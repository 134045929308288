import { generateUUID } from '@/utils/common'
import {
  CLIP_TYPES,
  CROP_KEYS,
  DEFAULT_CAPTION,
  FX_DESC,
  FX_TYPES,
  PARAMS_TYPES,
  TRANSFORM2D_KEYS,
} from '@/utils/Global'

class Raw {
  constructor(raw) {
    this.raw = raw
  }
}
export class Track extends Raw {
  constructor(type, index) {
    super(null)
    this.type = type
    this.index = index
    this.show = true
    this.volume = 1
    this.clips = []
    this.tips = ''
    this.icon = ''
    this.uuid = generateUUID()
  }
}

class Clip extends Raw {
  constructor({ type, inPoint, duration }) {
    super(null)
    this.type = type
    this.inPoint = inPoint && Math.floor(inPoint)
    this.duration = duration && Math.floor(duration)
  }
}
export class VideoFx extends Raw {
  constructor(desc, type = FX_TYPES.BUILTIN, from, videoFxType) {
    super(null)
    this.desc = desc
    this.type = type // 'builtin' or 'package' or 'property'
    this.params = []
    this.from = from || 'user-added'
    this.videoFxType = videoFxType // media-in-animation, media-out-animation, media-filter, media-mask
  }
}
export class FxParam {
  constructor(type, key, value) {
    this.type = type
    this.key = key
    this.value = value
  }
}
export class VideoClip extends Clip {
  constructor(option) {
    super({ ...option, type: CLIP_TYPES.VIDEO })
    this.id = option.id
    this.pristine = option.pristine || false
    this.m3u8Path = option.m3u8Path
    this.url = option.url
    this.coverUrl = option.coverUrl || option.url
    this.m3u8Url = option.m3u8Url
    this.videoType = option.videoType // videoType是字符串，且只能是video、image
    this.duration = option.duration
    this.orgDuration = option.orgDuration || option.duration
    this.splitList = [
      // 只有切割后splitList内部才有多个Object，不过立刻就会被处理为一个值Object，一般情况下为只有一个Object
      {
        trimIn: option.trimIn || 0,
        trimOut: option.trimOut || option.duration, // 切割点
        captureIn: option.captureIn || 0,
        captureOut: option.captureOut || option.duration, // 选中点
        raw: null,
        videoFxs: option.videoFxs || getDefaultFx(option),
      },
    ]
    this.width = option.width // 视频宽度
    this.height = option.height // 视频高度
    this.aspectRatio = option.aspectRatio // 视频宽高比
    this.motion = option.motion === undefined ? option.videoType === CLIP_TYPES.IMAGE : !!option.motion
    this.thumbnails = option.thumbnails || []
    this.coverUrl = option.coverUrl
    this.clips = option.clips || []
    this.title = option.title || ''
    this.uuid = option.uuid || generateUUID()
    this.pairedAudioUuid = option.pairedAudioUuid || ''
    this.digitalHumanClipId = option.digitalHumanClipId || ''
    this.volume = option.volume ?? 1
    this.userAddedClips = option.userAddedClips || []
    this.backgroundColor = option.backgroundColor
    this.isFromModule = option.isFromModule || false
    this.isDefaultAsset = option.isDefaultAsset || false
    this.templateDisplayHidden = option.templateDisplayHidden || false
    this.templateDisplayName = option.templateDisplayName || ''
    this.templateTag = option.templateTag || ''
    this.maskMode = option.maskMode || 'fill'
    this.maskLeft = option.maskLeft || -1
    this.maskTop = option.maskTop || 1
    this.maskRight = option.maskRight || 1
    this.maskBottom = option.maskBottom || -1
    // 视频中音频的波形图
    // this.leftChannelUrl = option.leftChannelUrl || "";
    // this.rightChannelUrl = option.rightChannelUrl || "";
    this.spillRemoval = option.spillRemoval || false
    if (this.spillRemoval) {
      this.spillRemovalSoftenessAmendment = option.spillRemovalSoftenessAmendment || 0.1
      this.spillRemovalIntensity = option.spillRemovalIntensity || 0
      this.spillRemovalShrinkIntensity = option.spillRemovalShrinkIntensity || 0
      this.spillRemovalColor = option.spillRemovalColor
    }
    this.digitalHuman = option.digitalHuman || undefined
    if (this.digitalHuman) {
      this.digitalHumanAvatarId = option.digitalHumanAvatarId
      this.digitalHumanAvatarVideoId = option.digitalHumanAvatarVideoId
      this.digitalHumanAudioUrl = option.digitalHumanAudioUrl
    }
    this.mediaInAnimationFxUrl = option.mediaInAnimationFxUrl
    if (this.mediaInAnimationFxUrl) {
      this.mediaInAnimationFxDuration = option.mediaInAnimationFxDuration || 1000000
    }
    this.mediaOutAnimationFxUrl = option.mediaOutAnimationFxUrl
    if (this.mediaOutAnimationFxUrl) {
      this.mediaOutAnimationFxDuration = option.mediaOutAnimationFxDuration || 1000000
    }
    this.mediaContextFxUrl = option.mediaContextFxUrl
  }

  resetDefaultScale() {
    this.splitList[0].videoFxs = getFxByWidthHeight(this.width, this.height, 0, 0)
  }
}
// 根据视频原始宽高比 计算放大倍数。用于视频初始时充满liveWindow
function getDefaultFx(option) {
  if (option.notDefaultScale) {
    return []
  }
  const m3u8Path = option.m3u8Path
  if (!m3u8Path) {
    return []
  }
  const { width, height } = option
  return getFxByWidthHeight(width, height, 0, 0)
}

export function getFxByWidthHeight(width, height, translationX, translationY) {
  const scale = getDefaultScale(width, height)
  return [getTransformFx(scale || 1, scale || 1, translationX, translationY)]
}

export function getTransformFx(scaleX, scaleY, translationX, translationY) {
  const transformFx = new VideoFx(FX_DESC.TRANSFORM2D)
  transformFx.params = [
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.SCALE_X, scaleX || 1), // scale
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.SCALE_Y, scaleY || 1),
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.TRANS_X, translationX || 0), // translation (shift)
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.TRANS_Y, translationY || 0),
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.ROTATION, 0),
  ]
  return transformFx
}

export function getCropMosaicFx(left, top, right, bottom) {
  const cropMosaicFx = new VideoFx(FX_DESC.MOSAIC)
  cropMosaicFx.params = [
    new FxParam('float', CROP_KEYS.REGION, {
      x1: left,
      y1: bottom,
      x2: left,
      y2: top,
      x3: right,
      y3: top,
      x4: right,
      y4: bottom,
    }), // crop region
  ]
  return cropMosaicFx
}

function getDefaultScale(width, height) {
  if (width / height > 9.0 / 16) {
    return ((16.0 / 9) * width) / height
  } else {
    // taller than portrait video
    return ((9.0 / 16) * height) / width
  }
}
// function getType(num) {
//   const temp = {
//     1: "VIDEO",
//     2: "AUDIO",
//     3: "IMAGE"
//   };
//   return temp[num] || "VIDEO";
// }
export class AudioClip extends Clip {
  constructor(option) {
    super({ ...option, type: CLIP_TYPES.AUDIO })
    this.id = option.id
    this.mediaAssetId = option.mediaAssetId
    this.m3u8Path = option.m3u8Path
    this.alphaM3u8Path = option.alphaM3u8Path
    this.coverUrl = option.coverUrl
    this.url = option.url
    this.alphaPath = ''
    this.m3u8Url = option.m3u8Url
    this.alphaM3u8Url = ''
    this.trimIn = Math.floor(option.trimIn || 0)
    this.trimOut = Math.floor(option.trimOut || option.orgDuration || option.duration) // TODO, think about this again
    this.orgDuration = Math.floor(option.orgDuration || option.duration)
    this.title = option.title || ''
    this.uuid = option.uuid || generateUUID()
    this.ttsAudioClipId = option.ttsAudioClipId || '' // used if the clip is generated from W2V
    this.pairedMediaUuid = option.pairedMediaUuid || ''
    this.pairedCaptionUuid = option.pairedCaptionUuid || ''
    this.leftChannelUrl = option.leftChannelUrl || ''
    this.rightChannelUrl = option.rightChannelUrl || ''
    this.name = option.name || ''
    this.artist = option.artist || ''
    this.volume = option.volume ?? 1
    this.audioLayerId = option.audioLayerId || ''
    this.audioType = option.audioType || ''
    this.isReachingEndOfVideo = false
    this.voiceId = option.voiceId
    this.voiceRate = option.voiceRate
    this.voiceScript = option.voiceScript
  }
}
export class CaptionClip extends Clip {
  constructor(option) {
    super({
      ...option,
      type: CLIP_TYPES.CAPTION,
      duration: option.duration || 3 * 1000000,
    })
    this.styleDesc = option.desc || option.id || option.styleDesc || DEFAULT_CAPTION
    this.text = option.text || option.value || CLIP_TYPES.CAPTION
    this.fontSize = option.fontSize
    this.scale = option.scale || 1
    this.rotation = option.rotation || 0
    this.uuid = option.uuid || generateUUID()
    this.pairedAudioUuid = option.pairedAudioUuid || ''
    this.packageUrl = option.packageUrl // url of the parent caption package
    this.align = option.align // string, left/center/right
    // front end stores color in RGBA，FW DOM stores color as #FFFFFFF, SDK uses NvsColor
    this.color = option.color || '' // RGBA
    this.backgroundColor = option.backgroundColor || ''
    this.font = option.font || '' // stringValue
    this.fontUrl = option.fontUrl || ''
    this.translationX = option.translationX || 0 // location related to the center of video preview
    this.translationY = option.translationY || 0
    this.z = option.z || 0
    this.frameWidth = option.frameWidth
    this.frameHeight = option.frameHeight
    this.isModule = !!option.isModule // whether from template / module
    this.deleted = !!option.deleted // whether be deleted
    this.outlineWidth = option.outlineWidth || 0
    this.outlineColor = option.outlineColor || ''
    this.shadowColor = option.shadowColor || ''
    this.shadowOffsetX = option.shadowOffsetX || 0
    this.shadowOffsetY = option.shadowOffsetY || 0
    this.shadowFeather = option.shadowFeather || 0
    this.lineSpacing = option.lineSpacing // default value is not needed, when null, the properties from caption package will be taken
    this.letterSpacingType = option.letterSpacingType // same as the above
    this.letterSpacing = option.letterSpacing // same as the above
    this.fontStyle = option.fontStyle
    this.bold = option.bold
    this.weight = option.weight
    this.italic = option.italic
    this.underline = option.underline
    this.temporal = option.temporal || '' // a property for FW DOM: intro, end, default
    this.templateDisplayHidden = option.templateDisplayHidden || false
    this.templateDisplayName = option.templateDisplayName || ''
    this.templateTag = option.templateTag || ''
    this.animation = option.animation || '' // url of the animated caption package
    // CT-1796 - These keys names exactly match meishe FX binary file extensions
    this.captionInAnimation = option.captionInAnimation || undefined // String:URL
    this.captionInAnimationDuration = option.captionInAnimationDuration || undefined // Number:microseconds
    this.captionOutAnimation = option.captionOutAnimation || undefined // String:URL
    this.captionOutAnimationDuration = option.captionOutAnimationDuration || undefined // Number:microseconds
    this.captionAnimation = option.captionAnimation || undefined // String:URL
    this.captionAnimationPeriod = option.captionAnimationPeriod || undefined // Number:microseconds
    this.captionContext = option.captionContext || undefined // String:URL
  }
}
export class StickerClip extends Clip {
  constructor(option) {
    super({
      ...option,
      type: CLIP_TYPES.STICKER,
      duration: option.duration || 3 * 1000000,
    })
    this.desc = option.desc || option.id
    this.scaleX = option.scaleX || 0.5
    this.scaleY = option.scaleY || 0.5
    this.rotation = option.rotation || 0
    this.packageUrl = option.packageUrl
    this.translationX = option.translationX || 0
    this.translationY = option.translationY || 0
    this.z = option.z || 0
    this.uuid = generateUUID()
    this.isModule = !!option.isModule // 是否为模板的贴纸
    this.deleted = !!option.deleted // 是否已删除
  }
}

export class ImageClip extends Clip {
  constructor(option) {
    super({ ...option, type: CLIP_TYPES.IMAGE })
    this.id = option.id
    this.url = option.url
    this.m3u8Url = option.m3u8Url
    this.orgDuration = option.orgDuration || option.duration
    this.splitList = [
      {
        trimIn: option.trimIn || 0,
        trimOut: option.trimOut || option.duration, // 切割点
        captureIn: option.captureIn || 0,
        captureOut: option.captureOut || option.duration, // 选中点
        raw: null,
        videoFxs: option.videoFxs || getDefaultFx(option),
      },
    ]
    this.isDefaultAsset = false
    this.width = option.width // 图片宽度
    this.height = option.height // 图片高度
    this.aspectRatio = option.aspectRatio // 视频宽高比
    this.motion = option.motion === undefined ? option.videoType === CLIP_TYPES.IMAGE : !!option.motion
    this.thumbnails = option.thumbnails || []
    this.title = option.title || ''
    this.uuid = option.uuid || generateUUID()
    this.pairedAudioUuid = option.pairedAudioUuid || ''
    this.digitalHumanClipId = option.digitalHumanClipId || ''
    this.volume = option.volume ?? 1
    this.templateDisplayHidden = option.templateDisplayHidden || false
    this.templateDisplayName = option.templateDisplayName || ''
    this.templateTag = option.templateTag || ''
    this.maskMode = option.maskMode || 'fill'
    this.maskLeft = option.maskLeft || -1
    this.maskTop = option.maskTop || 1
    this.maskRight = option.maskRight || 1
    this.maskBottom = option.maskBottom || -1
    // 视频中音频的波形图
    // this.leftChannelUrl = option.leftChannelUrl || "";
    // this.rightChannelUrl = option.rightChannelUrl || "";
    this.spillRemoval = option.spillRemoval || false
    if (this.spillRemoval) {
      this.spillRemovalSoftenessAmendment = option.spillRemovalSoftenessAmendment || 0.1
      this.spillRemovalIntensity = option.spillRemovalIntensity || 0
      this.spillRemovalShrinkIntensity = option.spillRemovalShrinkIntensity || 0
      this.spillRemovalColor = option.spillRemovalColor
    }
    this.mediaInAnimationFxUrl = option.mediaInAnimationFxUrl
    if (this.mediaInAnimationFxUrl) {
      this.mediaInAnimationFxDuration = option.mediaInAnimationFxDuration || 1000000
    }
    this.mediaOutAnimationFxUrl = option.mediaOutAnimationFxUrl
    if (this.mediaOutAnimationFxUrl) {
      this.mediaOutAnimationFxDuration = option.mediaOutAnimationFxDuration || 1000000
    }
    this.mediaContextFxUrl = option.mediaContextFxUrl
  }

  resetDefaultScale() {
    this.splitList[0].videoFxs = getFxByWidthHeight(this.width, this.height, 0, 0)
  }
}
