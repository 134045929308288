export class DataList extends Array {
  // CT-2014 - Method extensions on base Array object will not be reactive in Vue3 so we avoid using them
  // and instead mutate the array with Vue methods, not with these extended methods

  loading = false
  #dataUrl = ''
  #currentIndex = 0

  init = (options) => {
    this.#dataUrl = options.dataUrl
    this.length = 0 // FIXME: VUE2 shouldn't be able to detect this, but everything works... ==> https://v2.vuejs.org/v2/guide/reactivity.html#For-Arrays
    this.#currentIndex = 0
  }

  setDataUrl = (dataUrl) => (this.#dataUrl = dataUrl)
  getDataUrl = () => this.#dataUrl
  setLoading = (loading) => (this.loading = loading)
  isLoading = () => !!this.loading
  setCurrentIndexById = (targetId) => {
    this.#currentIndex = this.map((item) => item.id).indexOf(targetId)
  }
  next = () => {
    if (this.currentIndex >= this.length - 1) {
      return null
    }
    return this[++this.#currentIndex]
  }
  prev = () => {
    if (this.#currentIndex <= 0) {
      return null
    }
    return this[--this.#currentIndex]
  }
  replace = (replaceItems) => {
    this.length = 0
    this.splice(0, this.length, ...replaceItems)
  }
  // FIXME: this breaks Vue3 reactivity, so is not used
  replaceSingleData = (newData) => {
    if (!newData || !newData.id) {
      return
    }
    const oldDataIndex = this.findIndex((ele) => ele.id === newData.id)
    this.splice(oldDataIndex, 1, newData)
  }
  isFirst = () => {
    return this.#currentIndex === 0
  }
  isLast = () => {
    return this.#currentIndex === this.length - 1
  }
  getIndexById = (targetId) => {
    return this.map((item) => item.id).indexOf(targetId)
  }
  // FIXME: this breaks Vue3 reactivity, so is not used
  removeById = (targetId) => {
    this.splice(this.getIndexById(targetId), 1)
  }
}
