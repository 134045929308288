/*
    Vue Directive to implement 'long click', where when a user clicks on a item as long as the user
    holds the mouse button down the event handler of the mousedown will fire over and over again in 
    ever shorter intervals, ie an incrementor that goes faster as you hold the mouse button down
    CT-1531

    References: 
    https://blog.logrocket.com/building-a-long-press-directive-in-vue-3408d60fb511/
    https://v2.vuejs.org/v2/guide/custom-directive.html
*/

const LongClick = {
  beforeMount(el, binding, vNode) {
    const INITIAL_INTERVAL = 300
    const INTERVAL_ACCELERATION_LARGE = 60
    const INTERVAL_ACCELERATION_SMALL = 20
    const MIN_INTERVAL = 20

    let intervalLength = INITIAL_INTERVAL
    let interval = undefined

    const fn = binding.value

    const isHaltable = !!binding.modifiers.haltable

    // Sanity check - Make sure expression provided is a function
    if (typeof fn !== 'function') {
      let warn = `[LongClick] provided expression '${fn}' is not a function, but has to be`

      const compName = vNode?.context?.name
      if (compName) {
        warn += `Found in component '${compName}' `
      }

      console.warn(warn)
      return
    }

    // Trigger the callback function once and start a new timer to recursively
    // do this again, each interval making the timer shorter
    function _doInterval() {
      // if the callback returns false, and if LongClick is haltable
      //  end the long press by not setting the next timeout
      if (!fn() && isHaltable) {
        return
      }
      const currentAcceleration = intervalLength > 120 ? INTERVAL_ACCELERATION_LARGE : INTERVAL_ACCELERATION_SMALL
      intervalLength -= currentAcceleration
      interval = setTimeout(_doInterval, Math.max(intervalLength, MIN_INTERVAL))
    }

    // Clear the timer and reset the interval
    function _cancelInterval() {
      if (interval) {
        clearTimeout(interval)
        interval = null
        intervalLength = INITIAL_INTERVAL
      }
    }

    el.addEventListener('mousedown', _doInterval)
    el.addEventListener('mouseup', _cancelInterval)
  },
}

export default LongClick
