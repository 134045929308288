import cookies from './Cookie'

export const tokenKey = 'fwtoken'
export const apiHost = document.domain
const sessionStore = {}

/* Token */
const token = {}
token.hasUserToken = () => {
  return !!getTokenFromCookie()
}

token.setToken = (token) => {
  const expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + 30)
  // FIXME - axios should not be exposed publicly!!!  Move token to app state and have axios read from there
  if (axios) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
  return cookies.set('fwtoken', token, expireDate)
}

token.setTokenAndNext = (next, nextObj) => {
  if (token.hasUserToken()) {
    token.setToken(getTokenFromCookie())
  }
  if (nextObj) {
    return next(nextObj)
  }
  return next()
}

export default token

export const getTokenFromCookie = () => {
  try {
    const sessionFromCookies = cookies.get(tokenKey)
    return sessionFromCookies
  } catch (error) {
    console.error(error)
    return sessionStore
  }
}
