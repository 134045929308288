export default {
  namespaced: true,
  state: () => ({
    logoUrl: '',
    watermark: undefined,
  }),
  mutations: {
    setLogoUrl(state, payload) {
      state.logoUrl = payload
    },
    // We store the watermark object in the same format as was persisted by BE
    // ie, see TranslateX, TranslateY
    setWatermark(state, payload) {
      state.watermark = payload
    },
    setWatermarkScale(state, payload) {
      state.watermark = state.watermark || {}
      state.watermark.scale = payload
    },
    setWatermarkEnabled(state, payload) {
      state.watermark = state.watermark || {}
      state.watermark.enabled = payload
    },
    setWatermarkTranslateX(state, payload) {
      state.watermark = state.watermark || {}
      state.watermark['translate-x'] = payload
    },
    setWatermarkTranslateY(state, payload) {
      state.watermark = state.watermark || {}
      state.watermark['translate-y'] = payload
    },
    setWatermarkSourceWidth(state, payload) {
      state.watermark = state.watermark || {}
      state.watermark.sourceWidth = payload
    },
    setWatermarkSourceHeight(state, payload) {
      state.watermark = state.watermark || {}
      state.watermark.sourceHeight = payload
    },
  },
  actions: {
    setLogoUrl({ commit }, payload) {
      commit('setLogoUrl', payload)
    },
    setWatermark({ commit }, payload) {
      commit('setWatermark', payload)
    },
    setWatermarkScale({ commit }, payload) {
      commit('setWatermarkScale', payload)
    },
    setWatermarkEnabled({ commit }, payload) {
      commit('setWatermarkEnabled', payload)
    },
    setWatermarkTranslateX({ commit }, payload) {
      commit('setWatermarkTranslateX', payload)
    },
    setWatermarkTranslateY({ commit }, payload) {
      commit('setWatermarkTranslateY', payload)
    },
    setWatermarkSourceWidth({ commit }, payload) {
      commit('setWatermarkSourceWidth', payload)
    },
    setWatermarkSourceHeight({ commit }, payload) {
      commit('setWatermarkSourceHeight', payload)
    },
  },
  getters: {
    logoUrl: (state) => state.logoUrl,
    watermark: (state) => state.watermark,
    watermarkScale: (state) => state.watermark?.scale,
    watermarkEnabled: (state) => state.watermark?.enabled || false,
    watermarkTranslateX: (state) => state.watermark?.['translate-x'],
    watermarkTranslateY: (state) => state.watermark?.['translate-y'],
    watermarkSourceWidth: (state) => state.watermark?.sourceWidth,
    watermarkSourceHeight: (state) => state.watermark?.sourceHeight,
  },
}
