export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
        "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trim"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Backward"])},
        "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send To Back"])},
        "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring Forward"])},
        "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring To Front"])},
        "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add On Top"])}
      }
    }
  })
}
