export default {
  namespaced: true,
  state: () => ({
    clip: null,
    isRectChanged: false,
  }),
  mutations: {
    setClip(state, clip) {
      state.clip = clip
    },
    setIsRectChanged(state, isRectChanged) {
      state.isRectChanged = isRectChanged
    },
  },
  actions: {
    setClip({ commit }, clip) {
      commit('setClip', clip)
    },
    setIsRectChanged({ commit }, isRectChanged) {
      if (typeof isRectChanged !== 'boolean') {
        console.error('Vuex:mediaCropping:setIsRectChanged - received parameter is not a boolean')
        return
      }

      commit('setIsRectChanged', isRectChanged)
    },
  },
}
