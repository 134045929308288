import { createVNode, reactive, render } from 'vue'

import MenuList from './MenuList.vue'

// CT-2014 Mounting a Vue component outside of a Vue app
// https://stackoverflow.com/questions/66034882/mount-vue-component-vue-3
// FIXME: this code should not exist, MenuList.vue should be observe vuex and open/close/position itself
export const mount = (component, { props, children, element, app } = {}) => {
  let el = element

  let vNode = createVNode(component, props, children)
  if (app && app._context) {
    vNode.appContext = app._context
  }
  if (el) {
    render(vNode, el)
  } else if (typeof document !== 'undefined') {
    render(vNode, (el = document.createElement('div')))
  }

  const destroy = () => {
    if (el) {
      render(null, el)
    }
    el = null
    vNode = null
  }

  return { vNode, destroy, el }
}

let closed = true

function callMenu({ hover, pos }, app) {
  return new Promise((resolve, reject) => {
    if (!closed) {
      return reject()
    }
    closed = false

    const handleClickCallback = ({ name }) => {
      resolve(name)
      hideAndReset()
    }

    const props = reactive({
      hover,
      pos,
      handleClickCallback,
    })

    const {
      vNode,
      el: $el,
      destroy,
    } = mount(MenuList, {
      props,
      app,
    })

    document.body.appendChild($el)

    $el.addEventListener('click', (e) => e.stopPropagation())

    const menuHeight = $el.childNodes[0].offsetHeight
    if (window.innerHeight - vNode.props.pos.y < vNode.props.pos.y) {
      vNode.props.pos.y = vNode.props.pos.y - menuHeight - 20
      if (vNode.props.pos.y < 0) {
        $el.style.height = menuHeight + vNode.props.pos.y + 'px'
        vNode.props.pos.y = 0
      }
    } else {
      if (pos.y + menuHeight > window.innerHeight) {
        vNode.props.pos.y = window.innerHeight - menuHeight - 10
        $el.style.height = window.innerHeight - vNode.props.pos.y - 20 + 'px'
      }
    }

    function hideAndReset() {
      $el.parentNode?.removeChild($el)
      closed = true
      destroy()
      resolve()
    }
    document.body.addEventListener('mousedown', hideAndReset, { once: true })
    window.addEventListener('resize', hideAndReset, { once: true })
  })
}

const components = require.context('./', false, /\.vue/)

export default {
  install: (app) => {
    components.keys().forEach((fileName) => {
      const componentModule = components(fileName)
      const componentName =
        (componentModule.default && componentModule.default.name) || fileName.replace(/\.\/(.*)\.vue/, '$1')

      app.component(componentName, componentModule.default || componentModule)
    })

    app.config.globalProperties.$callMenu = (args) => callMenu(args, app)
  },
}
