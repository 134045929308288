export default {
  namespaced: true,
  state: () => ({
    isPlaying: false,
    isScaling: false,

    // This is the name of the panel currently loaded into the left drawer (Project level tools/materials for building)
    currentMaterialPanel: '',

    // The currently selected creative assets on the canvas that the user is editing
    currentlySelectedClips: [],

    // Meishe Objects
    timeline: undefined,

    // The last used settings tab (right drawer)
    currentSettingsTab: 'settingsTab',
  }),
  mutations: {
    setIsScaling(state, payload) {
      state.isScaling = payload
    },
    setIsPlaying(state, payload) {
      state.isPlaying = payload
    },
    setCurrentMaterialPanel(state, payload) {
      state.currentMaterialPanel = payload
    },
    setCurrentlySelectedClips(state, payload) {
      state.currentlySelectedClips = payload
    },
    setTimeline(state, payload) {
      state.timeline = payload
    },
    setCurrentSettingsTab(state, payload) {
      state.currentSettingsTab = payload
    },
  },
  actions: {
    setIsScaling({ commit }, payload) {
      commit('setIsScaling', payload)
    },
    setIsPlaying({ commit }, payload) {
      commit('setIsPlaying', payload)
    },
    setCurrentMaterialPanel({ commit }, payload) {
      commit('setCurrentMaterialPanel', payload)
    },
    setCurrentlySelectedClips({ commit }, payload) {
      commit('setCurrentlySelectedClips', payload)
    },
    setTimeline({ commit }, payload) {
      commit('setTimeline', payload)
    },
    setCurrentSettingsTab({ commit }, payload) {
      const valid = ['settingsTab', 'templateTagsTab']

      const toCommit = valid.includes(payload) ? payload : valid[0]
      commit('setCurrentSettingsTab', toCommit)
    },
  },
}
