export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "videoCompileInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compile In Progress..."])},
        "videoCompileFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compile Failed"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])}
      },
      "ja": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューを表示"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制作完了"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
        "sceneEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シーンを編集"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャプション"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグ"])},
        "posters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サムネイル設定"])},
        "postTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開設定"])},
        "ctaButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTAボタン"])},
        "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非公開"])},
        "hashtagHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(#マークは不要。ハッシュタグひとつ入力する度にEnter押下してください。）"])},
        "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
        "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compose Finish!"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compose Be In Progress..."])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compose Failed"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
        "posterMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいサムネイルを追加してください"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])},
        "insertLinkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクを挿入"])},
        "cta_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Now"])},
        "cta_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "cta_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
        "cta_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Now"])},
        "cta_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
        "cta_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
        "cta_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
        "cta_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "cta_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSVP"])},
        "cta_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
        "cta_get_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Offer"])},
        "cta_get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Quote"])},
        "cta_view_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Menu"])},
        "cta_get_showtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Showtimes"])},
        "cta_read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])}
      },
      "zh": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首頁"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈"])}
      }
    }
  })
}
