/*

  CT-1516 - Implement Event Bus (PubSub) as vanilla JS
  - this will be used as both Vue Plugin and a plain exported JS Object 
  - safe to migrate from Vue 2 to Vue 3

  As a vanilla JS import: (for legacy classes, etc)

    import { EventBus as Bus } from './utils/EventBus.js'

    Bus.$on(eventName{String}, callback{fn})
    Bus.$once(eventName{String}, callback{fn})
    Bus.$off(eventName{String}, callback{fn})
    Bus.$emit(eventName{String}, arg1, arg2, ...)

  As a Vue Plugin: (preferred usage)

    this.$eventBusOn(eventName{String}, callback{fn})
    this.$eventBusOnce(eventName{String}, callback{fn})
    this.$eventBusOff(eventName{String}, callback{fn})
    this.$eventBusEmit(eventName{String}, arg1, arg2, ...)

  ** NOTE ** the plugin version will auto-magically deregister all registered events for
  the given component in the beforeUnmount lifecycle hook, so no need to add that call
  in your component.
  

  ** LOOKING FORWARD **
  See https://v3-migration.vuejs.org/breaking-changes/events-api.html

  Vue 3 docs discourages use of Event Bus (as an anti-pattern to idiomatic Vue and reacting state changes)
  and we should strive to deprecate these as we evolve the application
*/

// Maps of eventNames to list of callbacks
const events = {}
const events_once = {}

/**
 * Subscribe to an event (by name) with a callback
 *
 * @param {string} evt - The name of the event.  Prefer to use an enum from /src/utils/EventBusKeys.js
 * @param {function} cb - The callback function to run when event is emitted
 * @returns undefined
 */
function $on(evt, cb) {
  if (!evt) {
    console.warn(`EventBus: attempt to register invalid event '${evt}'`)
    return
  }

  if (!(cb instanceof Function)) {
    console.warn(`EventBus: attempt to register event '${evt}' with non-function '${cb}' `)
    return
  }

  events[evt] = events[evt] || []
  events[evt].push(cb)
}

/**
 * Unsubscribe a callback from an event (by name).  This covers both .on and .once subscribe methods
 *
 * @param {string} evt - The name of the event.  Prefer to use an enum from /src/utils/EventBusKeys.js
 * @param {function} cb - The original callback function used to subscribe
 * @returns undefined
 */
function $off(evt, cb) {
  if (Array.isArray(events[evt])) {
    events[evt] = events[evt].filter((fn) => fn !== cb)
  }

  if (Array.isArray(events_once[evt])) {
    events_once[evt] = events_once[evt].filter((fn) => fn !== cb)
  }
}

/**
 * Subscribe to an event (by name) with a callback that should only run once
 *
 * @param {string} evt - The name of the event.  Prefer to use an enum from /src/utils/EventBusKeys.js
 * @param {function} cb - The callback function to run when event is emitted
 * @returns undefined
 */
function $once(evt, cb) {
  if (!evt) {
    console.warn(`EventBus: attempt to register invalid event '${evt}'`)
    return
  }

  if (!(cb instanceof Function)) {
    console.warn(`EventBus: attempt to register once-event '${evt}' with non-function '${cb}' `)
    return
  }

  events_once[evt] = events_once[evt] || []
  events_once[evt].push(cb)
}

/**
 * Trigger all callbacks on events, if there are any
 *
 * @param {string} evt - The name of the event.  Prefer to use an enum from /src/utils/EventBusKeys.js
 * @param {...*} args - The arguments to pass to the callback
 * @returns undefined
 */
function $emit(evt, ...args) {
  if (Array.isArray(events[evt])) {
    events[evt].forEach((fn) => fn(...args))
  }

  // After all callbacks in events_once have been called, clear them
  // so they cannot be triggered again
  if (Array.isArray(events_once[evt])) {
    events_once[evt].forEach((fn) => fn(...args))
    delete events_once[evt]
  }
}

// Expose event bus functions to non-Vue code (plain JS export)
export const EventBus = {
  $on,
  $off,
  $once,
  $emit,
}

// Expose event bus functions as Vue Plugin (Mixin)
// This is essentially a wrapper for the EventBus object that adds some smart functionality
// to auto-deregister callbacks on Vue component/instance teardown
export default function install(app) {
  app.config.globalProperties.$bus = EventBus // For backwards compatibility

  app.mixin({
    data() {
      return {
        // Memoize this component's EventBus registrations
        // Don't need separate collections for .on and .once events
        eventBus_registeredEvents: [],
      }
    },
    beforeUnmount() {
      // Automatically deregister and clean up state
      this.eventBus_registeredEvents.forEach(([evt, cb]) => $off(evt, cb))
      this.eventBus_registeredEvents = []
    },
    methods: {
      // Standardized interface for EventBus (wrappers)
      $eventBusOn(evt, cb) {
        $on(evt, cb)
        this.eventBus_registeredEvents.push([evt, cb])
      },
      $eventBusOff(evt, cb) {
        $off(evt, cb)
      },
      $eventBusOnce(evt, cb) {
        $once(evt, cb)
        this.eventBus_registeredEvents.push([evt, cb])
      },
      $eventBusEmit(evt, ...args) {
        $emit(evt, ...args)
      },
    },
  })
}
