export const getOS = () => {
  var userAgent = typeof window !== 'undefined' && window.navigator.userAgent,
    platform = typeof window !== 'undefined' && window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

export const getBrowser = () => {
  if (typeof window === 'undefined') {
    return null
  }
  if (navigator.userAgent.search('MSIE') >= 0) {
    return 'IE'
  } else if (navigator.userAgent.search('Edge') >= 0) {
    return 'Edge'
  } else if (navigator.userAgent.search('Chrome') >= 0 || navigator.userAgent.search('CriOS') >= 0) {
    return 'Chrome'
  } else if (navigator.userAgent.search('Firefox') >= 0) {
    return 'Firefox'
  } else if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
    return 'Safari'
  } else if (navigator.userAgent.search('Opera') >= 0) {
    return 'Opera'
  } else {
    return null
  }
}

export const getLocale = () => (typeof window !== 'undefined' && navigator.language) || ''
