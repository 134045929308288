export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a business and channel"])},
        "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to create a business account and channel before using Firework Studio"])},
        "businessChannelModalTitleForProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate project to a Channel"])},
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
        "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
        "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Firework Business Portal"])}
      }
    }
  })
}
