export const DEFAULT_VIDEO_WIDTH = 1080
export const DEFAULT_VIDEO_HEIGHT = 1920
export const ASPECT_RATIO = DEFAULT_VIDEO_WIDTH / DEFAULT_VIDEO_HEIGHT // This is equivalent to 9:16

export const DROPRATIO = 0.8 // 拖拽media到liveWindow的缩放尺寸

export const PREVIEW_VIDEO_WIDTH = 540
export const PREVIEW_VIDEO_HEIGHT = 960

export const W2V_MINIMUM_IMAGE_SIZE = 399

export const DEFAULT_IMAGE_DURATION = 3000000
