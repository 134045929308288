import { getActiveProfile, getNabooFlags, getProfileEmails } from '@/services/featureFlagService'
import cookies from '@/utils/Cookie'

class NabooFeatureFlag {
  constructor() {
    this._data = { flags: null, failed: false, profile: null, businessId: '' }
    this._asyncIds = { int: 0, time: 0 }
    this.fetchNabooFlags()
  }

  get flags() {
    return this._data.flags
  }

  get profile() {
    return this._data.profile
  }

  isActive(key) {
    if (!!this._data.flags && !this._data.failed) {
      return this.flags[key] ?? false
    } else {
      return false
    }
  }

  async fetchNabooFlags(businessId = cookies.get('businessId')) {
    if (businessId !== this._data.businessId) {
      try {
        // Avoid extra api call if profile exist
        if (!this._data.profile) {
          const profile = await getActiveProfile()
          this._data.profile = profile
          const emailsResponse = await getProfileEmails()
          this._data.profile.email = emailsResponse?.emails[0]?.address || ''
          const emailRegex = /^[a-zA-Z0-9._%+-]+@(fireworkhq|loopapps)\.com$/
          this._data.profile.internal_user = emailRegex.test(this._data.profile.email)
        }

        // Fetch Naboo flags
        const flags = await getNabooFlags(this._data.profile.encoded_id, businessId)
        this._data.businessId = businessId
        this._data.flags = flags
      } catch (error) {
        this._data.failed = true
        console.error('Error fetching Naboo Feature Flags', error)
      }
    }
  }

  /**
   * Check if this Class has completed its api calls
   * @returns promise
   */
  async isReady() {
    return new Promise((resolve) => {
      // Check if Naboo flags have been resolved
      this._asyncIds.int = setInterval(() => {
        if (this._data.flags || this._data.failed) {
          clearInterval(this._asyncIds.int)
          clearTimeout(this._asyncIds.time)
          return resolve()
        }
      }, 20)
      // Avoid infinite checks and cancel interval after 3 seconds
      this._asyncIds.time = setTimeout(() => {
        clearTimeout(this._asyncIds.time)
        clearInterval(this._asyncIds.int)
        return resolve()
      }, 3000)
    })
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$nff = new NabooFeatureFlag()
  },
}
