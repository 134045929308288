<template>
  <div class="business-channel-modal-wrapper">
    <div class="business-channel-modal-backdrop" @click="closeModal"></div>
    <div v-if="!noBusinessAndChannel" class="business-channel-modal">
      <div class="business-channel-title-wrapper">
        <span>{{ getModalTitle() }}</span>
      </div>
      <div class="business-channel-form">
        <div class="selctor-title">{{ $t('business') }}</div>
        <a-select
          v-model:value="businessIdLocal"
          show-search
          class="form-selector"
          placeholder="--"
          :not-found-content="null"
          :default-active-first-option="false"
          :filter-option="false"
          @search="handleBusinessSearch"
          @change="handleBusinessChange"
        >
          <a-select-option v-for="business in businessList" :key="business.id" :value="business.id">{{
            business.name
          }}</a-select-option>
        </a-select>
        <div class="selctor-title">{{ $t('channel') }}</div>
        <a-select v-model:value="channelIdLocal" class="form-selector" placeholder="--">
          <a-select-option v-for="channel in channelList" :key="channel.id" :value="channel.id">{{
            channel.name
          }}</a-select-option>
        </a-select>
      </div>
      <div class="business-channel-button-wrapper">
        <a-button class="confirm-button" :disabled="isDisabled" @click="selectBusinessAndChannel">
          {{ getModalCTA() }}
        </a-button>
      </div>
    </div>
    <div v-if="noBusinessAndChannel" class="business-channel-modal center fit">
      <div class="business-channel-title-wrapper">
        <span>{{ $t('businessChannelModalTitleNoBusiness') }}</span>
      </div>
      <div class="business-channel-button-wrapper">
        <a :href="businessPortalUrl">
          <a-button class="enter-button" tabindex="-1">
            {{ $t('enterBusinessPortal') }}
          </a-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import host from '@/api/apiHost'
import { DataList } from '@/utils/DataList'

const MAX_BUSINESS_COUNT = 250

export default {
  props: {
    businessId: String,
    channelId: String,
    forceOpen: Boolean,
    project: Object,
  },
  data() {
    return {
      businessList: new DataList(),
      channelList: new DataList(),
      businessIdLocal: this.businessId,
      channelIdLocal: this.channelId,
      isLoading: true,
      businessPortalUrl: host['bizPortal'],
    }
  },
  computed: {
    noBusinessAndChannel() {
      return this.businessList.length === 0 && this.channelList.length === 0
    },
    isDisabled() {
      return this.isLoading || !this.businessIdLocal || !this.channelIdLocal
    },
  },
  watch: {
    businessId(newBusinessId) {
      this.businessIdLocal = newBusinessId
    },
    /*
    channelId(newChannelId) {
      this.channelIdLocal = newChannelId
    },
    */
  },
  created() {
    this.businessList.init({
      dataUrl: this.$api.bus,
    })
    this.loadBusinesses()
    this.loadChannel()
  },
  methods: {
    getModalTitle() {
      if (!this.project) {
        return this.$t('businessChannelModalTitle')
      }
      return this.$t('businessChannelModalTitleForProject')
    },
    getModalCTA() {
      if (!this.project) {
        return this.$t('enter')
      }
      return this.$t('assign')
    },
    async loadBusinesses() {
      this.isLoading = true
      let error = undefined
      while (this.businessList.getDataUrl() && this.businessList.length <= MAX_BUSINESS_COUNT && !error) {
        try {
          const businessRes = await axios.get(this.$api.toPath(this.businessList.getDataUrl()))
          const { businesses, paging } = businessRes
          this.businessList.setDataUrl(paging?.next)
          this.businessList.push(...businesses)
        } catch (e) {
          error = e
          console.error(e)
        }
      }
      this.isLoading = false
    },
    async loadChannel() {
      this.isLoading = true

      if (!this.businessIdLocal) {
        return
      }
      this.channelList.init({
        dataUrl: `${this.$api.bus}/${this.businessIdLocal}/channels`,
      })
      while (this.channelList.getDataUrl()) {
        try {
          const channelRes = await axios.get(this.$api.toPath(this.channelList.getDataUrl()))
          const { channels, paging } = channelRes
          this.channelList.setDataUrl(paging?.next)
          this.channelList.push(...channels)
        } catch (e) {
          console.error(e)
          return this.$router.push({ name: 'Login' })
        }
      }
      this.isLoading = false
    },
    closeModal() {
      if (this.forceOpen) {
        return
      }
      this.$emit('close-business-channel-modal')
    },
    selectBusinessAndChannel() {
      this.$emit('change-business-channel', this.businessIdLocal, this.channelIdLocal)
      if (this.project) {
        this.$emit('assign-project-channel', this.project, this.channelIdLocal)
      }
      this.$emit('close-business-channel-modal')
    },
    handleBusinessSearch: debounce(function (query) {
      this.businessList.init({
        dataUrl: `${this.$api.bus}?q=${query}`,
      })
      this.loadBusinesses()
    }, 300),
    handleBusinessChange(newBusinessId) {
      this.businessIdLocal = newBusinessId
      this.loadChannel(newBusinessId)
      this.channelIdLocal = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.business-channel-modal-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
}
.business-channel-modal {
  width: 439px;
  height: 401px;
  background: #404040; // Gray 3
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  z-index: 11;
  padding: 33px 60px;
  box-sizing: content-box;
  &.center {
    justify-content: center;
  }
  &.fit {
    height: auto;
  }
}
.business-channel-title-wrapper {
  display: flex;
  color: white;
  font-size: 16px;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.business-channel-form {
  color: #e2e2e2;
}
.business-channel-button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  // New button style
  button {
    background: $button-bg-color;
    color: $button-text-color;
    border: $button-border;
    border-radius: 6px;
    &[disabled='disabled'],
    &.is-disabled {
      opacity: 0.3;
    }
    &:hover {
      color: $button-text-color;
      background: $button-bg-color;
      border: $button-border;
    }
    &:not(.is-disabled) {
      &:focus {
        border: $button-border-focus;
      }
      &:hover {
        background: rgba($button-bg-color, 0.9);
      }
    }
  }
  .confirm-button {
    width: 100%;
  }
}
.selctor-title {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 500;
}
.form-selector {
  margin-bottom: 17px;
  width: 100%;

  :deep {
    .ant-select-selection-item {
      color: black;
    }
    .ant-select-selection {
      border: 1px solid #121212;
    }
    .ant-select-selection:hover {
      border-color: white;
    }

    div {
      background: #2b2b2b;
      color: #e2e2e2;
    }

    svg {
      color: #c0c4cc;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "businessChannelModalTitle": "Select a business and channel",
    "businessChannelModalTitleNoBusiness": "You need to create a business account and channel before using Firework Studio",
    "businessChannelModalTitleForProject": "Associate project to a Channel",
    "business": "Business",
    "channel": "Channel",
    "enter": "Enter",
    "assign": "Assign",
    "enterBusinessPortal": "Go to Firework Business Portal"
  }
}
</i18n>

<i18n locale="ja">
{
  "businessChannelModalTitle": "ビジネスとチャンネルを選択",
  "businessChannelModalTitleNoBusiness": "You need to create a business account and channel before using Firework Studio",
  "business": "ビジネス",
  "channel": "チャンネル",
  "enter": "完了",
  "enterBusinessPortal": "Go to Firework Business Portal"
}
</i18n>

<i18n locale="zh-TW">
{
  "businessChannelModalTitle": "選擇您的商家和頻道",
  "businessChannelModalTitleNoBusiness": "在使用FW Studio之前，您需要先建立商家和頻道",
  "business": "商家",
  "channel": "頻道",
  "enter": "進入",
  "enterBusinessPortal": "前往Firework商家控制台"
}
</i18n>

<i18n locale="zh-HK">
{
  "businessChannelModalTitle": "選擇您的商家和頻道",
  "businessChannelModalTitleNoBusiness": "在使用FW Studio之前，您需要先建立商家和頻道",
  "business": "商家",
  "channel": "頻道",
  "enter": "進入",
  "enterBusinessPortal": "前往Firework商家控制台"
}
</i18n>
