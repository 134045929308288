import Vue from 'vue'

import api from '@/api/apiPath'
import i18n from '@/i18n'
import { EventBus } from '@/plugins/eventBus'
import { store } from '@/store'
import { DROPRATIO } from '@/utils/Constants'
import {
  ANIMATE_PROPERTY_KEYS,
  BACKGROUND_REMOVAL_KEYS,
  CLIP_TYPES,
  DEFAULT_IN_ANIMATION_DURATION,
  DEFAULT_OUT_ANIMATION_DURATION,
  FX_DESC,
  FX_TYPES,
  PARAMS_TYPES,
  TRANSFORM2D_KEYS,
  VIDEO_FX_TYPES,
} from '@/utils/Global'
import { FxParam, VideoClip, VideoFx } from '@/utils/ProjectData'

import { getAssetFromIndexDB, installAsset } from './AssetsUtils'
import Cookies from './Cookie'
import { getLocale } from './Environment'
import EventBusKeys from './EventBusKeys'
import WorkFlow from './WorkFlow'

// 小数转百分数
export function toPercentage(number, fixed = 2) {
  number = number * 100
  if (fixed) {
    number = number.toFixed(2)
  }
  return number + '%'
}
export function sleep(timeout) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, timeout)
  })
}
export function getNameFromUrl(url) {
  return url.split('/').pop().split('?').shift()
}
// micro second to ss.s s, for example, 105000 -> "10.5 s"
export function us2ss_s(us) {
  // FIXME - These strings should be read from localization configuration, not hardcoded
  let s = 's'
  if (i18n.global.locale.startsWith('ja') || i18n.global.locale.startsWith('zh')) {
    s = '秒'
  }

  return `${microsecondsToSeconds(us)} ${s}`
}
export function microsecondsToSeconds(us, precision = 1) {
  const exp = Math.pow(10, precision)
  // use Math.Floor to round down, use exp to keep "precision" digit after decimal point
  // add 1 to "us" (in micro second) to ensure 999,999 micro second is displayed as 0.1 s
  const sec = Math.floor(((us + 1) * exp) / 1000000) / exp
  return sec.toFixed(precision) // convert to string with a guarantee of "precision" digit after decimal point
}
export function secondsToMicroseconds(s) {
  return s * 1000000
}
export function microsecondsToMilliseconds(us) {
  return us / 1000
}
// uuid
export function generateUUID() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'
  var uuid = s.join('')
  return uuid
}
/**
 * 计算旋转后的位置
 * @param {object} origin 旋转中心 {x, y}
 * @param {number} angle 旋转角 弧度
 * @param {object} vector 要旋转的点 {x, y}
 */
export function vectorRotate(vector, angle, origin = { x: 0, y: 0 }) {
  const cosA = Math.cos(angle)
  const sinA = Math.sin(angle)
  var x1 = (vector.x - origin.x) * cosA - (vector.y - origin.y) * sinA
  var y1 = (vector.x - origin.x) * sinA + (vector.y - origin.y) * cosA
  return {
    x: origin.x + x1,
    y: origin.y + y1,
  }
}

export function isSameNvsColor(c0, c1) {
  return c0 && c1 && c0.r === c1.r && c0.g === c1.g && c0.b === c1.b && c0.a === c1.a
}

export function RGBAToNvsColor(rgbaValue) {
  if (!rgbaValue) {
    return new NvsColor(1, 1, 1, 0)
  }
  var rgba = rgbaValue.match(/(\d(\.\d+)?)+/g)
  return new NvsColor(
    parseInt(rgba[0]) / 255.0,
    parseInt(rgba[1]) / 255.0,
    parseInt(rgba[2]) / 255.0,
    (rgba[3] || 1) / 1.0,
  )
}
export function NvsColorToRGBA(color) {
  const r = Math.round(color.r * 255)
  const g = Math.round(color.g * 255)
  const b = Math.round(color.b * 255)
  const a = color.a
  return `rgb(${r}, ${g}, ${b}, ${a})`
}
export function RGBAToHex(rgbaValue) {
  if (!rgbaValue) {
    return ''
  }
  const [r, g, b, a] = rgbaValue.match(/(\d(\.\d+)?)+/g)
  const toHex = (v) => {
    const hex = parseInt(v).toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return `#${toHex(a * 255)}${toHex(r)}${toHex(g)}${toHex(b)}`
}
export function isDefinedAndNotNull(variable) {
  return variable !== undefined && variable !== null
}
export function isValidRGBAValue(variable) {
  return variable !== undefined && variable !== null && Number.isInteger(variable) && variable >= 0 && variable < 256
}
export function HexToRGBA(hexValue) {
  if (hexValue === '' || hexValue === null || hexValue === undefined) {
    return ''
  }
  return (
    'rgba(' +
    parseInt('0x' + hexValue.slice(3, 5)) +
    ',' +
    parseInt('0x' + hexValue.slice(5, 7)) +
    ',' +
    parseInt('0x' + hexValue.slice(7, 9)) +
    ',' +
    parseInt('0x' + hexValue.slice(1, 3)) / 255.0 +
    ')'
  )
}

// base64转字符串
export function base64ToString(data) {
  return atob(data)
  // return decode(data);
}
export function stringToBase64(string) {
  return btoa(string)
  // return encode(string);
}

// 应用字体
export async function loadFonts(url, familyName) {
  if (!url || !familyName) {
    return
  }
  let data = await getAssetFromIndexDB(url)
  if (!data) {
    data = `url(${url})`
  }
  const font = new FontFace(familyName, data)
  if (document.fonts.has(font)) {
    return
  }
  font.load().then(
    () => {
      // Resolved - add font to document.fonts
      document.fonts.add(font)
    },
    (err) => {
      console.error(err)
    },
  )
}

// CT-2030 - Define hardcoded Default Font obj here for now, keeping it together with logic that fetches source font obj data
// SEE https://fwn.atlassian.net/wiki/spaces/CE/pages/364937290/Caption+Packages
export const DEFAULT_FONT = {
  font_files: [
    {
      id: 'defaultFontRegular',
      stretch: 'medium',
      style: 'normal',
      title: 'NotoSans Regular',
      url: `https://cdn1-staging.fireworktv.com/studio/assets/font/NotoSansCJK-Regular.ttc`,
      weight: 'normal',
    },
  ],
  font_url: null,
  id: 'defaultFontFamily',
  name: 'Noto Sans',
  thumbnail_url: null,
}

/**
 * Fetch font list from server and load and install them asynchronously (non-blocking)
 * CT-2030
 *
 * @returns undefined
 */
export async function getFontList() {
  const businessId = Cookies.get('businessId')

  Promise.all([
    window.axios.get(api.fonts, {
      params: { page_size: 50, locale: getLocale() },
    }),
    window.axios.get(api.userFonts(businessId)),
  ])
    .then(([publicFontsRes, userFontsRes]) => {
      const fonts = [
        ...userFontsRes.font_families,
        ...publicFontsRes.font_families,
        // CT-2030 - install hardcoded default font, this must be in application state font list before any usage!!
        DEFAULT_FONT,
      ]

      for (const item of fonts) {
        const font = {
          installed: false,
          label: item.name,
          packageUrl: item.font_url,
          coverUrl: item.thumbnail_url,
          id: item.id,
          stringValue: item.name,
        }
        if (item.font_files.length) {
          // Make sure all font styles are covered
          const style = item.font_files.find((font) => {
            return (
              (font.style === 'normal' && font.weight === 'regular') ||
              (font.style === 'normal' && font.weight === 'normal') ||
              (font.style === 'normal' && font.weight === 'semibold') ||
              (font.style === 'normal' && font.weight === 'bold') ||
              (font.style === 'normal' && font.weight === 'light') ||
              font.style === 'italic'
            )
          })

          // CT-2496 - if no font url is found, try use the first font file
          if (!font.packageUrl) {
            font.packageUrl = item.font_files[0]?.url
          }

          if (!style) {
            continue
          }
        }

        // CT-2496 - only install font if packageUrl is available
        if (font.packageUrl) {
          installAsset(font.packageUrl).then((name) => {
            loadFonts(font.packageUrl, name).then(() => {
              font.installed = true
              font.installing = false
              font.stringValue = name || font.stringValue // CT-2496 - use font name from font file if available

              store.dispatch('clip/addFont', font)
            })
          })
        }
      }
    })
    .catch((e) => {
      console.error(e)
      return Vue.prototype.$message.error(i18n.t('App.loadFontsFail'))
    })
}

/**
 * Ensure font settings for a clip are set correctly
 * CT-2030
 * @param {Clip} clip - The clip to remove
 * @returns undefined
 */
export function validateFontSourceSettings(clip) {
  const fonts = store.getters['clip/fonts']
  if (!fonts.length) {
    return
  }

  if (clip.font) {
    // Handle url as the default
    const font = fonts.find((font) => font.stringValue === clip.font)
    if (font && font.packageUrl !== clip.fontUrl) {
      clip.fontUrl = font.packageUrl
    }
  } else {
    // Select the first font from application state font list
    // Since list is always sorted in order: brandingPrimaryFont, brandingSecondaryFont, defaultFont, ...the rest base fonts
    const replaceFont = fonts[0]

    clip.font = replaceFont.stringValue
    clip.fontUrl = replaceFont.packageUrl
    clip.raw.setFontFamily(clip.font)
  }

  EventBus.$emit(EventBusKeys.seek)
  store.commit('clip/updateClipToVuex', clip)
}
export function getRemoveBackFx(clip) {
  const {
    spillRemoval,
    spillRemovalSoftenessAmendment,
    spillRemovalIntensity,
    spillRemovalShrinkIntensity,
    spillRemovalColor,
  } = clip
  if (!spillRemoval) {
    return null
  }
  const removeBackFx = new VideoFx(FX_DESC.REMOVEBACK)
  removeBackFx.type = FX_TYPES.BUILTIN
  removeBackFx.params = [
    new FxParam(PARAMS_TYPES.BOOL, BACKGROUND_REMOVAL_KEYS.SPILL_REMOVAL, true),
    new FxParam(PARAMS_TYPES.FLOAT, BACKGROUND_REMOVAL_KEYS.SOFTENESS_AMENDMENT, spillRemovalSoftenessAmendment),
    new FxParam(PARAMS_TYPES.FLOAT, BACKGROUND_REMOVAL_KEYS.SPILL_REMOVAL_INTENSITY, spillRemovalIntensity),
    new FxParam(PARAMS_TYPES.FLOAT, BACKGROUND_REMOVAL_KEYS.SHRINK_INTENSITY, spillRemovalShrinkIntensity),
    new FxParam(PARAMS_TYPES.COLOR, BACKGROUND_REMOVAL_KEYS.KEY_COLOR, spillRemovalColor),
  ]
  return removeBackFx
}
export function getPresetFx(transX, transY, scaleX, scaleY) {
  const transformFx = new VideoFx(FX_DESC.TRANSFORM2D)
  transformFx.params = [
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.SCALE_X, scaleX || DROPRATIO), // 缩放
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.SCALE_Y, scaleY || DROPRATIO),
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.TRANS_X, transX || 0), // 位移
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.TRANS_Y, transY || 0),
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.ROTATION, 0),
  ]
  return [transformFx]
}
// CT-2492, given VideoClip and the VIDEO_FX_TYPE, add the VideoFx to clip
export function addMediaAnimationFxToClip(video, fxType) {
  if (fxType === VIDEO_FX_TYPES.IN_ANIMATION) {
    const mediaInAnimationFxUUID = extractUuidFromURL(video.mediaInAnimationFxUrl)
    video.videoFxs.push(createInAnimationPropertyFx(mediaInAnimationFxUUID, 0, video.mediaInAnimationFxDuration))
  } else if (fxType === VIDEO_FX_TYPES.OUT_ANIMATION) {
    const mediaOutAnimationFxUUID = extractUuidFromURL(video.mediaOutAnimationFxUrl)
    video.videoFxs.push(
      createOutAnimationPropertyFx(
        mediaOutAnimationFxUUID,
        video.duration - video.mediaOutAnimationFxDuration,
        video.duration,
      ),
    )
  } else if (fxType === VIDEO_FX_TYPES.IN_OUT_ANIMATION) {
    const mediaInOutAnimationFxUUID = extractUuidFromURL(video.mediaInOutAnimationFxUrl)
    video.videoFxs.push(createInOutAnimationPropertyFx(mediaInOutAnimationFxUUID, 0, video.duration))
  } else if (fxType === VIDEO_FX_TYPES.CONTEXT) {
    const mediaContextFxUUID = extractUuidFromURL(video.mediaContextFxUrl)
    video.videoFxs.push(getMediaFx(mediaContextFxUUID, VIDEO_FX_TYPES.CONTEXT))
  }
}
export function getMediaFx(fxUUID, fxType, clipDuration, animationDuration) {
  if (fxType === VIDEO_FX_TYPES.IN_ANIMATION) {
    const inAnimationDuration = animationDuration ? animationDuration : DEFAULT_IN_ANIMATION_DURATION
    return createInAnimationPropertyFx(fxUUID, 0, inAnimationDuration)
  } else if (fxType === VIDEO_FX_TYPES.OUT_ANIMATION) {
    const outAnimationDuration = animationDuration ? animationDuration : DEFAULT_OUT_ANIMATION_DURATION
    return createOutAnimationPropertyFx(fxUUID, clipDuration - outAnimationDuration, clipDuration)
  } else if (fxType === VIDEO_FX_TYPES.IN_OUT_ANIMATION) {
    return createInOutAnimationPropertyFx(fxUUID, 0, clipDuration)
  } else {
    // fxType === VIDEO_FX_TYPES.CONTEXT or other generic fx
    return new VideoFx(fxUUID, FX_TYPES.PACKAGE, 'user-added', fxType)
  }
}
export function getClipOffsetForAnimation(clip) {
  const sceneVideo = findUserAddedClipParent(clip)
  if (!sceneVideo) {
    return 0
  }
  // if the scene duration is shorter than the animation duration
  // -2 for ensuring the timeline is sought to a visible position, -1 (will miss the overlap media elements)
  return Math.min(getSceneMaxAnimationDuration(sceneVideo, VIDEO_FX_TYPES.IN_ANIMATION), sceneVideo.duration - 2)
}
export function getSceneOffsetForAnimation(sceneVideo) {
  if (!sceneVideo) {
    return 0
  }
  // if the scene duration is shorter than the animation duration
  // -2 for ensuring the timeline is sought to a visible position, -1 (will miss the overlap media elements)
  const inAnimationFxOutPoint = Math.min(
    getSceneMaxAnimationDuration(sceneVideo, VIDEO_FX_TYPES.IN_ANIMATION),
    sceneVideo.duration - 2,
  )
  const outAnimationFxDuration = getSceneMaxAnimationDuration(sceneVideo, VIDEO_FX_TYPES.OUT_ANIMATION)
  if (inAnimationFxOutPoint > sceneVideo.duration - outAnimationFxDuration) {
    // when having overlapping in/out animations, calculate the mid point
    return (inAnimationFxOutPoint + sceneVideo.duration - outAnimationFxDuration) / 2
  }
  return inAnimationFxOutPoint
}
function getSceneMaxAnimationDuration(video, fxType) {
  let maxDuration = 0
  video.userAddedClips.forEach((clip) => {
    const fxDuration = getAnimationFxDuration(clip.splitList[0].videoFxs, fxType)
    if (fxDuration && maxDuration < fxDuration) {
      maxDuration = fxDuration
    }
  })
  return maxDuration
}
// CT-2492 find fx of specific fxType exists in videoFxs
// undefined when not found
function findAnimationFx(videoFxs, fxType) {
  return videoFxs?.find((fx) => fx.videoFxType === fxType)
}
// get in or out animation fx duration
// undefined when animation fx does not exist
function getAnimationFxDuration(videoFxs, fxType) {
  if (fxType === VIDEO_FX_TYPES.IN_ANIMATION) {
    const currentInAnimationFx = findAnimationFx(videoFxs, VIDEO_FX_TYPES.IN_ANIMATION)
    if (!currentInAnimationFx) {
      return undefined
    }
    // in-animation always start at 0, return IN_ANIMATE_OUT_POINT directly
    return currentInAnimationFx?.params?.find((param) => param.key === ANIMATE_PROPERTY_KEYS.IN_ANIMATE_OUT_POINT).value
  } else if (fxType === VIDEO_FX_TYPES.OUT_ANIMATION) {
    const currentOutAnimationFx = findAnimationFx(videoFxs, VIDEO_FX_TYPES.OUT_ANIMATION)
    if (!currentOutAnimationFx) {
      return undefined
    }
    // to obtain the duration for out animation, return OUT_POINT - IN_POINT
    return (
      currentOutAnimationFx.params?.find((param) => param.key === ANIMATE_PROPERTY_KEYS.OUT_ANIMATE_OUT_POINT).value -
      currentOutAnimationFx.params?.find((param) => param.key === ANIMATE_PROPERTY_KEYS.OUT_ANIMATE_IN_POINT).value
    )
  }
  return null
}
// CT-2492
// when the clip duration is changed, should update animation fx duration, such that
// 1. in animation duration does not exceed clip duration
// 2. out animation fx has the correct inPoint / outPoint
export function updateAnimationFxOnClipDurationChanged(videoFxs, clipDuration) {
  const hasInAnimationFx = findAnimationFx(videoFxs, VIDEO_FX_TYPES.IN_ANIMATION) !== undefined
  if (hasInAnimationFx) {
    const inAnimationFxDuration = getAnimationFxDuration(videoFxs, VIDEO_FX_TYPES.IN_ANIMATION)
    if (inAnimationFxDuration > clipDuration - 1) {
      updateAnimationFxDuration(videoFxs, VIDEO_FX_TYPES.IN_ANIMATION, clipDuration, clipDuration - 1)
    }
  }
  // need to re-calculate the in and out point for out animation whenever the clip duration is adjusted
  const hasOutAnimationFx = findAnimationFx(videoFxs, VIDEO_FX_TYPES.OUT_ANIMATION) !== undefined
  if (hasOutAnimationFx) {
    const outAnimationDuration = Math.min(
      clipDuration - 1,
      getAnimationFxDuration(videoFxs, VIDEO_FX_TYPES.OUT_ANIMATION),
    )
    updateAnimationFxDuration(videoFxs, VIDEO_FX_TYPES.OUT_ANIMATION, clipDuration, outAnimationDuration)
  }
}
function updateAnimationFxDuration(videoFxs, fxType, clipDuration, animationDuration) {
  const animationFx = videoFxs?.find((fx) => fx.videoFxType === fxType)
  if (!animationFx) {
    return
  }
  if (fxType === VIDEO_FX_TYPES.IN_ANIMATION) {
    const inAnimationParamOutPointIndex = animationFx.params.findIndex(
      (param) => param.key === ANIMATE_PROPERTY_KEYS.IN_ANIMATE_OUT_POINT,
    )
    if (inAnimationParamOutPointIndex !== -1) {
      animationFx.params[inAnimationParamOutPointIndex].value = animationDuration
    }
  } else if (fxType === VIDEO_FX_TYPES.OUT_ANIMATION) {
    const outAnimationParamInPointIndex = animationFx.params.findIndex(
      (param) => param.key === ANIMATE_PROPERTY_KEYS.OUT_ANIMATE_IN_POINT,
    )
    if (outAnimationParamInPointIndex !== -1) {
      animationFx.params[outAnimationParamInPointIndex].value = clipDuration - animationDuration
    }
    const outAnimationParamOutPointIndex = animationFx.params.findIndex(
      (param) => param.key === ANIMATE_PROPERTY_KEYS.OUT_ANIMATE_OUT_POINT,
    )
    if (outAnimationParamOutPointIndex !== -1) {
      animationFx.params[outAnimationParamOutPointIndex].value = clipDuration
    }
  }
}
function addInAnimationParams(propertyVideoFx, inAnimateId, inAnimateInPoint, inAnimateOutPoint) {
  propertyVideoFx.params = propertyVideoFx.params || []
  propertyVideoFx.params.push(
    new FxParam(PARAMS_TYPES.STRING, ANIMATE_PROPERTY_KEYS.IN_ANIMATE_PACKAGE_ID, inAnimateId),
  )
  propertyVideoFx.params.push(
    new FxParam(PARAMS_TYPES.FLOAT, ANIMATE_PROPERTY_KEYS.IN_ANIMATE_IN_POINT, inAnimateInPoint),
  )
  propertyVideoFx.params.push(
    new FxParam(PARAMS_TYPES.FLOAT, ANIMATE_PROPERTY_KEYS.IN_ANIMATE_OUT_POINT, inAnimateOutPoint),
  )
}
function addOutAnimationParams(propertyVideoFx, outAnimateId, outAnimateInPoint, outAnimateOutPoint) {
  propertyVideoFx.params.push(
    new FxParam(PARAMS_TYPES.STRING, ANIMATE_PROPERTY_KEYS.OUT_ANIMATE_PACKAGE_ID, outAnimateId),
  )
  propertyVideoFx.params.push(
    new FxParam(PARAMS_TYPES.FLOAT, ANIMATE_PROPERTY_KEYS.OUT_ANIMATE_IN_POINT, outAnimateInPoint),
  )
  propertyVideoFx.params.push(
    new FxParam(PARAMS_TYPES.FLOAT, ANIMATE_PROPERTY_KEYS.OUT_ANIMATE_OUT_POINT, outAnimateOutPoint),
  )
}
export function createInAnimationPropertyFx(inAnimateId, inAnimateInPoint, inAnimateOutPoint) {
  if (!inAnimateId) {
    return null
  }
  const propertyVideoFx = new VideoFx('', FX_TYPES.PROPERTY, '', VIDEO_FX_TYPES.IN_ANIMATION)
  propertyVideoFx.params = []
  addInAnimationParams(propertyVideoFx, inAnimateId, inAnimateInPoint, inAnimateOutPoint)
  return propertyVideoFx
}
export function createOutAnimationPropertyFx(outAnimateId, outAnimateInPoint, outAnimateOutPoint) {
  if (!outAnimateId) {
    return null
  }
  const propertyVideoFx = new VideoFx('', FX_TYPES.PROPERTY, '', VIDEO_FX_TYPES.OUT_ANIMATION)
  propertyVideoFx.params = []
  addOutAnimationParams(propertyVideoFx, outAnimateId, outAnimateInPoint, outAnimateOutPoint)
  return propertyVideoFx
}
export function createInOutAnimationPropertyFx(inOutAnimateId, duration) {
  if (!inOutAnimateId) {
    return null
  }
  const propertyVideoFx = new VideoFx('', FX_TYPES.PROPERTY, '', VIDEO_FX_TYPES.IN_OUT_ANIMATION)
  propertyVideoFx.params = []
  addInAnimationParams(propertyVideoFx, inOutAnimateId, 0, duration)
  return propertyVideoFx
}
export async function getBackgroundColorVideoClip(inPoint, duration, backgroundColor) {
  const m3u8Path = await installAsset('/assets/alpha_1px.png', {
    isCustom: true,
  })
  const videoClip = new VideoClip({
    m3u8Path,
    inPoint,
    duration,
    videoType: CLIP_TYPES.IMAGE,
    backgroundColor,
    motion: false,
    pristine: true,
  })
  return videoClip
}
export async function setWatermark(branding_preset) {
  if (!branding_preset.watermark || !branding_preset.watermark.enabled) {
    return store.commit('clip/setWatermarks', [])
  }

  const { videoWidth, videoHeight, videos } = store.state.clip
  const lastVideo = videos.slice(-1)[0]
  if (!lastVideo) {
    return
  }
  const duration = lastVideo.inPoint + (lastVideo.splitList[0].trimOut - lastVideo.splitList[0].trimIn)
  const fx = new VideoFx(FX_DESC.TRANSFORM2D, FX_TYPES.BUILTIN, 'user-added')
  fx.params = [
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.TRANS_X, branding_preset.watermark['translate-x'] * videoWidth), // 偏移
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.TRANS_Y, branding_preset.watermark['translate-y'] * videoHeight),

    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.SCALE_X, branding_preset.watermark.scale), // 缩放
    new FxParam(PARAMS_TYPES.FLOAT, TRANSFORM2D_KEYS.SCALE_Y, branding_preset.watermark.scale),
  ]
  const m3u8Path = await installAsset(branding_preset.logo_url, {
    isCustom: true,
    assetId: branding_preset.id,
  })
  const imageClip = new VideoClip({
    m3u8Path,
    inPoint: 0,
    duration: duration,
    videoType: CLIP_TYPES.IMAGE,
    coverUrl: branding_preset.logo_url,
    url: branding_preset.logo_url,
    m3u8Url: '',
    width: branding_preset.watermark.sourceWidth,
    height: branding_preset.watermark.sourceHeight,
    aspectRatio: '',
    id: branding_preset.id,
    thumbnails: '',
    motion: false,
    videoFxs: [fx],
  })
  imageClip.type = 'watermark'
  store.commit('clip/setWatermarks', [imageClip])
}

export const dropLine = document.createElement('div')
dropLine.classList.add('drop-line')

export function findUserAddedClipParent(clip) {
  if (isClipBackgroundMedia(clip)) {
    return clip
  }
  let ret = null
  store.state.clip.videos.forEach((video) => {
    const res = video.userAddedClips.find((c) => c.uuid === clip.uuid)
    if (res) {
      ret = video
    }
  })
  return ret
}

export function isClipBackgroundMedia(clip) {
  return store.state.clip.videos.some((video) => video.uuid === clip.uuid)
}

export function getCanvasSize(timelineClass) {
  const canvasSize = WorkFlow.bToa(new NvsPointF(0, 0), timelineClass.liveWindow)
  canvasSize.width = canvasSize.x * 2
  canvasSize.height = canvasSize.y * 2
  return canvasSize
}

export function calcCropMosaicRagion({ videoSize, canvasSize, rectSize }) {
  const { x, y, width, height } = videoSize
  const x1 = ((x + rectSize.left * width - canvasSize.width / 2) / canvasSize.width) * 2
  const x3 = ((x + (rectSize.left + rectSize.width) * width - canvasSize.width / 2) / canvasSize.width) * 2
  const y1 = ((canvasSize.height / 2 - (y + (rectSize.top + rectSize.height) * height)) / canvasSize.height) * 2
  const y2 = ((canvasSize.height / 2 - (y + rectSize.top * height)) / canvasSize.height) * 2

  return {
    x1,
    y1,
    x2: x1,
    y2,
    x3,
    y3: y2,
    x4: x3,
    y4: y1,
  }
}

/**
 * Given videos (scenes), calculate and return the total duration
 * CT-1544
 * @param {VideoClip} videos - usually pass in timeline.videos
 * @returns total video duration in micro second
 * perry - clean up everywhere to use vuex
 */
export function getTotalDuration(videos) {
  if (!Array.isArray(videos)) {
    return 0
  }
  let time = 0
  videos.forEach((video) => {
    video.splitList.forEach((item) => {
      time += item.captureOut - item.captureIn
    })
  })
  return time
}

export function extractUuidFromURL(url) {
  if (!url) {
    return ''
  }
  return url.split('/').pop().split('.').shift()
}

export function downscaleImage(dataUrl, newWidth, imageType, imageArguments) {
  'use strict'
  let image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl

  // Provide default values
  imageType = imageType || 'image/jpeg'
  imageArguments = imageArguments || 0.7

  // Create a temporary image so that we can compute the height of the downscaled image.
  image = new Image()
  return new Promise((resolve) => {
    image.src = dataUrl
    image.addEventListener('load', () => {
      oldWidth = image.width
      oldHeight = image.height
      newHeight = Math.floor((oldHeight / oldWidth) * newWidth)
      canvas = document.createElement('canvas')
      canvas.width = newWidth
      canvas.height = newHeight
      // // Create a temporary canvas to draw the downscaled image on.

      // // Draw the downscaled image on the canvas and return the new data URL.
      ctx = canvas.getContext('2d')
      // If transparent, make it white
      ctx.fillStyle = '#fff' /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Draw picture
      ctx.drawImage(image, 0, 0, newWidth, newHeight)
      newDataUrl = canvas.toDataURL(imageType, imageArguments)
      resolve(newDataUrl)
    })
  })
}

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export function isMediaAssetM3u8Ready(mediaAsset) {
  if (mediaAsset.source === null) {
    return mediaAsset[`hls_${mediaAsset.media_type}_url`]?.endsWith('.m3u8')
  }
  return (
    mediaAsset.source[`hls_${mediaAsset.source.media_type}_url`]?.endsWith('.m3u8') ||
    mediaAsset[`hls_${mediaAsset.media_type}_url`]?.endsWith('.m3u8')
  )
}

export function getMediaAssetM3u8Url(mediaAsset) {
  if (mediaAsset.source !== null && mediaAsset.source[`hls_${mediaAsset.source.media_type}_url`]?.endsWith('.m3u8')) {
    return mediaAsset.source[`hls_${mediaAsset.source.media_type}_url`]
  }
  return mediaAsset[`hls_${mediaAsset.media_type}_url`]
}

export function getMediaAssetMediaUrl(media) {
  if (media.source === null) {
    return media[`${media.media_type}_url`]
  }
  return media[`${media.source.media_type}_url`] || media[`${media.media_type}_url`]
}

export function getPosterPayload({ key, file, newPosterImg }) {
  const { width, height, aspect_ratio } = getImgAttribute(newPosterImg)
  const payload = {
    key: key,
    aspect_ratio: aspect_ratio,
    height: height,
    width: width,
    format: getFileExtension(file.name),
  }
  return payload
}
export function getImgAttribute(img) {
  const ratios = ['9:16', '3:4', '4:5', '1:1', '5:4', '4:3', '16:9']
  const ratios2 = [0.5625, 0.75, 0.8, 1.0, 1.25, 1.3333, 1.7778]
  const r = img.width / img.height
  const idx = ratios2.reduce((a, b, index) => {
    return Math.abs(b - r) < Math.abs(ratios2[a] - r) ? index : a
  }, 0)
  return {
    width: img.width || 1,
    height: img.height || 1,
    aspect_ratio: ratios[idx] || '1:1',
  }
}

export function getFileExtension(filename) {
  const formats = {
    png: 'png',
    jpg: 'jpg',
    jpeg: 'jpg',
    gif: 'gif',
    heic: 'heic',
    svg: 'svg',
    webp: 'webp',
  }
  const fileExtension = filename.split('.').pop().toLowerCase()
  return formats[fileExtension]
}

// convert val (micro-sec) to (HH:)MM:SS.s (one digit after decimal point)
// an exception is val === "Max", in which case, return "Max" as it is
// TODO - merge these to functions and implement precision argument
export function us2hhmmss_s(val) {
  if (val === 'Max') {
    return 'Max'
  }
  const second = Math.floor(val / 1000 / 1000)
  const secondAfterDecimalPointUs = val - second * 1000000
  const firstDecimalPlace = Math.floor(secondAfterDecimalPointUs / 100 / 1000)
  const minute = parseInt(second / 60)
  const hour = parseInt(minute / 60)
  const fix = (v) => (v > 9 ? v : '0' + v)
  const prefixWithHour = hour === 0 ? `` : `${fix(hour)}:`
  return `${prefixWithHour}${fix(minute % 60)}:${fix(second % 60)}.${firstDecimalPlace}`
}
export function us2hhmmss(val) {
  if (val === 'Max') {
    return 'Max'
  }
  const second = Math.floor(val / 1000 / 1000)
  const minute = parseInt(second / 60)
  const hour = parseInt(minute / 60)
  const fix = (v) => (v > 9 ? v : '0' + v)
  const prefixWithHour = hour === 0 ? `` : `${fix(hour)}:`
  return `${prefixWithHour}${fix(minute % 60)}:${fix(second % 60)}`
}
export function initGoogleImage() {
  if (window?.gapi) {
    window?.gapi.load('client')
    setTimeout(() => {
      window?.gapi.client.setApiKey('AIzaSyDYHErm2LOB7HobMsgBlfz_GYiJUj30EzM')
      window?.gapi.client.load('https://content.googleapis.com/discovery/v1/apis/customsearch/v1/rest')
    }, 1000)
  }
}

// CT-2373: the migration from captionstyle to captioncontext requires us
//  to externalize the text properties, to enhance the operation efficiency,
// this function is created for operation-purpose, a JSON object will be
//  created, stringified, and placed into the clipboard
export function getCaptionInfo(item, videoWidth, videoHeight) {
  // CT-2436 removed { text, font, fontUrl }
  const { frameWidth, frameHeight, weight } = item
  const alignDefaultValue = 'center'
  const backgroundColorDefaultValue = '#00000000'
  const unitConvertConstant = 720 / Math.max(videoHeight, videoWidth)
  const captionInfo = {
    frameWidth,
    frameHeight,
    weight,
  }
  if (item.align !== alignDefaultValue) {
    captionInfo.textXAlignment = item.align
  }
  if (item.color) {
    captionInfo.fontColor = RGBAToHex(item.color)
  }
  if (item.backgroundColor && RGBAToHex(item.backgroundColor) !== backgroundColorDefaultValue) {
    captionInfo.backgroundColor = RGBAToHex(item.backgroundColor)
  }
  if (item.outlineWidth) {
    captionInfo.outlineWidth = (item.outlineWidth * unitConvertConstant).toFixed(2)
  }
  if (item.outlineColor) {
    captionInfo.outlineColor = RGBAToHex(item.outlineColor)
  }
  if (item.shadowFeather) {
    captionInfo.shadowFeather = item.shadowFeather
  }
  if (item.shadowOffsetX) {
    captionInfo.shadowOffsetX = (item.shadowOffsetX * unitConvertConstant).toFixed(2)
  }
  if (item.shadowOffsetY) {
    captionInfo.shadowOffsetY = (item.shadowOffsetY * unitConvertConstant).toFixed(2)
  }
  if (item.shadowColor) {
    captionInfo.shadowColor = RGBAToHex(item.shadowColor)
  }
  if (item.italic) {
    captionInfo.isItalic = String(item.italic)
  }
  if (item.bold) {
    captionInfo.isBold = String(item.bold)
  }
  if (item.underline) {
    captionInfo.isUnderline = String(item.underline)
  }
  return captionInfo
}

export function getMaskInfo(x, y, width, height, timelineClass) {
  // use on screen rectangle for calculating the mask
  const {
    screenLeftTopX,
    screenLeftTopY,
    screenWidth: canvasWidth,
    screenHeight: canvasHeight,
  } = WorkFlow.getScreen({ timelineClass })

  // x, y: 0 ~ 1
  const canvas_item_left_p = (x - screenLeftTopX) / canvasWidth
  const canvas_item_top_p = (y - screenLeftTopY) / canvasHeight
  const canvas_item_right_p = (x - screenLeftTopX + width) / canvasWidth
  const canvas_item_bottom_p = (y - screenLeftTopY + height) / canvasHeight

  // remap to x = -1 ~ 1 (left to right), y = 1 ~ -1 (top to bottom)
  // the coordinate system Meishe uses
  const canvas_item_left = -1 + canvas_item_left_p * 2
  const canvas_item_top = 1 - canvas_item_top_p * 2
  const canvas_item_right = -1 + canvas_item_right_p * 2
  const canvas_item_bottom = 1 - canvas_item_bottom_p * 2

  const nd = 5 // number of digit after decimal point
  const maskLeft = constrainMaskValueInbound(canvas_item_left).toFixed(nd)
  const maskRight = constrainMaskValueInbound(canvas_item_right).toFixed(nd)
  const maskTop = constrainMaskValueInbound(canvas_item_top).toFixed(nd)
  const maskBottom = constrainMaskValueInbound(canvas_item_bottom).toFixed(nd)

  const maskInfoString = `mask-mode="crop-region" mask-left="${maskLeft}" mask-top="${maskTop}" mask-right="${maskRight}" mask-bottom="${maskBottom}"`
  navigator.clipboard.writeText(maskInfoString)

  return [parseFloat(maskLeft), parseFloat(maskTop), parseFloat(maskRight), parseFloat(maskBottom)]
}

// the coordinate is mapped to an axis from (-1:1)
function constrainMaskValueInbound(val) {
  if (val > 1) {
    return 1.0
  } else if (val < -1) {
    return -1.0
  }
  return val
}
