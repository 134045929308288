import { createRouter, createWebHashHistory } from 'vue-router'

import qs from 'qs'

import cookies from '../utils/Cookie'
import token from '../utils/Token'

const AvaGenerator = () => import('../components/fwInternalTools/AvaGenerator.vue')
const AssistantProfileManager = () => import('../components/fwInternalTools/AssistantProfileManager.vue')
const Create = () => import(/* webpackChunkName: "Create" */ '../views/Create.vue')
const Edit = () => import('../views/Edit.vue')
const Login = () => import('../views/Login.vue')
const Upload = () => import('../views/Upload.vue')

const routes = [
  {
    path: '/Edit',
    name: 'Edit',
    component: Edit,
  },
  {
    path: '/Create',
    name: 'Create',
    component: Create,
  },
  {
    path: '/Upload',
    name: 'Upload',
    component: Upload,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      isLoginPage: true,
    },
  },
  {
    path: '/Token',
    name: 'Token',
    meta: {
      isLoginPage: true,
    },
  },
  {
    path: '/',
    redirect: '/Create',
  },
  {
    path: '/AvaGenerator',
    name: 'AvaGenerator',
    component: AvaGenerator,
  },
  {
    path: '/AssistantProfileManager',
    name: 'AssistantProfileManager',
    component: AssistantProfileManager,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // Check if token exist
  if (!token.hasUserToken()) {
    const hashToken = qs.parse(window.location.hash)['#/token']
    if (hashToken) {
      token.setToken(hashToken)
      window.location.hash = ''
    }
    const { access_token } = to.query
    if (access_token) {
      // Remove businessId and channel Id
      token.setToken(access_token)
      cookies.remove('businessId')
      cookies.remove('channelId')
    }
    if (hashToken || access_token) {
      return next({ path: '/Create' })
    }
  }
  // Check if login
  if (to.matched.some((record) => record.meta.isLoginPage)) {
    // Check businessId channelId
    const { businessId, channelId, bizToken, videoProjectId, language } = to.query

    if (businessId && channelId) {
      cookies.set('businessId', businessId)
      cookies.set('channelId', channelId)
    }
    if (bizToken) {
      token.setToken(bizToken)
    }
    if (language) {
      cookies.set('language', language)
    }

    // Redirect
    if (token.hasUserToken()) {
      return token.setTokenAndNext(next, {
        path: '/Create',
        query: videoProjectId ? { preview: videoProjectId } : {},
      })
    }
    return next()
  } else {
    if (!token.hasUserToken()) {
      return next({ path: '/Login' })
    }
    return token.setTokenAndNext(next)
  }
})

export default router
