/*
  Isolate all Ant imports to this file to keep main.js clean - CT-1567

  While its possible to load the entire Ant library (in 2 lines) we opt to load
  components as we use them to keep our app bundle small.
*/
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Layout,
  Menu,
  message,
  Modal,
  notification,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  Upload,
} from 'ant-design-vue'

import 'ant-design-vue/dist/antd.css'

export default {
  install: (app) => {
    //app.use(Antd)
    // ^ Use full Ant design - only consider this when we have a majority usage of components and Element is removed
    app.use(Alert)
    app.use(Button)
    app.use(Col)
    app.use(Collapse)
    app.use(Checkbox)
    app.use(Divider)
    app.use(Dropdown)
    app.use(Form)
    app.use(Input)
    app.use(Icon)
    app.use(Layout)
    app.use(Menu)
    app.use(Modal)
    app.use(Popover)
    app.use(Progress)
    app.use(Radio)
    app.use(Row)
    app.use(Select)
    app.use(Slider)
    app.use(Spin)
    app.use(Steps)
    app.use(Switch)
    app.use(Tabs)
    app.use(Table)
    app.use(Upload)

    app.config.globalProperties.$message = message
    app.config.globalProperties.$notification = notification
    app.config.globalProperties.$confirm = Modal.confirm
    app.config.globalProperties.$info = Modal.info
    app.config.globalProperties.$success = Modal.success
    app.config.globalProperties.$error = Modal.error
    app.config.globalProperties.$warn = Modal.warning
  },
}
