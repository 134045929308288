/*
  Naboo Feature Flag Service

*/

import apiHost from '@/api/apiHost'
import { _axios } from '@/plugins/axios'

/**
 * Get Naboo flags
 *
 * @param {String} encoded_id - Current user encoded id
 * @param {String} business_id - Current business id
 * @returns {Promise}
 */
export function getNabooFlags(encoded_id, business_id) {
  const basePath = `${apiHost.fw}/api/feature_flags?user=${encoded_id}&business=${business_id}`

  return _axios.get(basePath)
}

/**
 * Get Profile info
 *
 * @returns {Promise}
 */
export function getActiveProfile() {
  const basePath = `${apiHost.fw}/api/me`

  return _axios.get(basePath)
}

/**
 * Get Profile info
 *
 * @returns {Promise}
 */
export function getProfileEmails() {
  const basePath = `${apiHost.fw}/api/emails`

  return _axios.get(basePath)
}
