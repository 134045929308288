export default {
  namespaced: true,
  state: () => ({
    currentCreateView: 'templates',
  }),
  mutations: {
    setCurrentCreateView(state, viewName) {
      state.currentCreateView = viewName
    },
  },
  actions: {
    setCurrentCreateView({ commit }, viewName) {
      commit('setCurrentCreateView', viewName)
    },
  },
}
