'use strict'

import axios from 'axios'
//https://www.npmjs.com/package/axios-retry
import axiosRetry from 'axios-retry'

import { getTokenFromCookie } from '@/utils/Token'

export const _axios = axios.create()

// CT-2120 - Retry axios requests
// This will retry the request 8 times with an exponential delay between each retry, to maximum time of 127s
axiosRetry(_axios, {
  retries: 8,
  retryDelay: axiosRetry.exponentialDelay, // https://developers.google.com/analytics/devguides/reporting/core/v3/errors#backoff
})

// Add a request interceptor
_axios.interceptors.request.use(
  function (config) {
    // We must always set the Authorization header from the latest token
    config.headers.Authorization = 'Bearer ' + getTokenFromCookie()
    config.timeout = 60000
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response.data
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  },
)

export default {
  install: (app) => {
    app.config.globalProperties.$axios = _axios
    app.config.globalProperties.axios = _axios
    window.axios = _axios // FIXME: Axios should not be exposed publicly!!!
  },
}
