export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "videoTooShort": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("durationOfVideo")), "-second video is too short. The minimum duration is ", _interpolate(_named("minVideoDuration")), " seconds."])},
        "videoTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("durationOfVideo")), "-second video is too long. The maximum duration is ", _interpolate(_named("maxVideoDuration")), " seconds."])},
        "publishVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Video"])},
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caption"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "hashtagHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate hashtags with commas (i.e. Travel, Trip, Nomad)"])},
        "poster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poster"])},
        "posterMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Poster"])},
        "ctaButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTA Button"])},
        "insertLinkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert link..."])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only you"])},
        "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only you and people on your account can see."])},
        "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
        "public_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyone on the internet can see."])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "agreeFireworkText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By posting, I agree to Firework ", _interpolate(_named("communityGuideline")), " & ", _interpolate(_named("terms"))])},
        "communityGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["community guidelines"])},
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])}
      }
    }
  })
}
