export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "zh-TW",
    "resource": {
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈中..."])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未支援的瀏覽器"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在電腦上使用 Google Chrome 或 Firework 來使用 Firework Studio"])}
    }
  })
}
