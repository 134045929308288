/*
  FX Service

  This service is the standard (CRUD) interface to BE for all transactions for FX
*/

import apiHost from '@/api/apiHost'
import { _axios } from '@/plugins/axios'

/**
 * Load FXs (Universal Animations), optionally by type
 * CT-2238
 *
 * @param {Object} options - Options object
 * @returns {Promise}
 */
export function getFxs(options = {}) {
  const basePath = `${apiHost.fw}/studio/studio_effects`
  const { page_size = 100, effect_type = '' } = options

  return _axios.get(basePath, {
    params: { page_size, effect_type },
  })
}

const fxService = { getFxs }
export default fxService
