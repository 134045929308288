import Vue from 'vue'

import Konva from 'konva'

import { EventBus as Bus } from '@/plugins/eventBus'
import { findUserAddedClipParent, getCanvasSize, isClipBackgroundMedia, loadFonts, toPercentage } from '@/utils/common'
import { ASPECT_RATIO } from '@/utils/Constants'
import { CLIP_TYPES, CROP_KEYS, FX_DESC, MENU_OPTIONS, TRANSFORM2D_KEYS } from '@/utils/Global'

import delImg from '../assets/images/delete.png'
import menuBtn from '../assets/images/menu-btn.png'
import Keys from '../utils/EventBusKeys'

// CT-1376 - prevent 'dimensionless' assets by enforcing a minimum value for height/width
const DIMENSION_MINIMUM = 1

const TRANSFORMER_BORDER_COLOR = '#fe177a'

// CT-1296 - how close an edge of dragging object needs to be before it snaps to point
const SNAP_TOLERANCE = 3
const SNAP_COLOR = '#a40ff4'
export default class WorkFlow {
  constructor(options) {
    const { containerId, timelineClass, store, callMenu } = options
    this.containerId = containerId
    this.$store = store
    this.container = document.getElementById(containerId)
    this.callMenu = callMenu

    this.originalNodeAttr = null

    this.stage = null
    this.layer = null
    this.rectTransform = null
    this.deleteNode = null
    this.menuNode = null
    this.timelineClass = timelineClass || null
    this.isShiftPressed = false
    this.transformCurrentState = { scaleX: 1, scaleY: 1 }
    this.projectBoundaries = undefined
    this.isRectTransformOutOfBounds = false

    // CT-1296 - State for guidelines
    // See https://fwn.atlassian.net/wiki/spaces/CE/pages/186974646/Guidelines+Snap+Grid+Rulers
    this.horizontalGuide = null // This is a horizontal line to indicate to user when a top/middle/bottom edge snaps
    this.verticalGuide = null // This is a vertical line to indicate to user when a left/center/right edge snaps
    this.horizontalSnapHighlights = []
    this.verticalSnapHighlights = []
    this.horizontalSnapPoints = undefined
    this.horizontalSnapPointMap = new Map()
    this.verticalSnapPoints = undefined
    this.verticalSnapPointMap = new Map()
    this.previousDragX = undefined
    this.previousDragY = undefined
    this.clipsOfCurrentScene = undefined

    // CT-1404 Workflow should handle one or more selected clips for any transforming operations
    this.clips = [] // This is ordered-list of {Clip} objects currently registered (used) by Konva.Transformer
    this.uuidClipMap = new Map() // Fast-lookup for clips that Transformer is reflecting
    this.nodes = [] // This is ordered-list of {Konva.rect} objects that currently reflect this.clips
    this.dragNodeId = undefined // Id of {Konva.Node} currently being dragged

    this.initStage()
  }
  initStage() {
    if (this.stage && this.stage instanceof Konva.Stage) {
      this.stage.destroy()
    }
    if (!this.containerId || !this.container) {
      console.warn('Container for Workflow is missing')
      return
    }

    const { clientHeight, clientWidth } = this.container
    this.stage = new Konva.Stage({
      container: this.containerId,
      width: clientWidth,
      height: clientHeight,
    })
    this.layer = new Konva.Layer()
    this.stage.add(this.layer)

    // CT-1296 - Init guidelines, style per CT-1342
    this.horizontalGuide = new Konva.Line({
      points: [-10000, 0, 10000, 0],
      stroke: SNAP_COLOR,
      strokeWidth: 0,
      dash: [10, 4],
    })
    this.layer.add(this.horizontalGuide)

    this.verticalGuide = new Konva.Line({
      points: [0, -10000, 0, 10000],
      stroke: SNAP_COLOR,
      strokeWidth: 0,
      dash: [10, 4],
    })
    this.layer.add(this.verticalGuide)

    this.initRect()
  }
  async initRect() {
    //flag properties including rotateEnabled / centeredScaling / keepRatio
    //use {Clip}.type === CLIP_TYPES.STICKER / CLIP_TYPES.VIDEO / CLIP_TYPES.CAPTION to configure
    this.rectTransform = new Konva.Transformer({
      nodes: [],
      rotateEnabled: false,
      borderStrokeWidth: 1.5,
      borderStroke: TRANSFORMER_BORDER_COLOR,
      anchorFill: 'white',
      anchorStroke: 'white',
      anchorSize: 8,
      rotationSnaps: [0, 45, 90, 135, 180, 225, 270],
      centeredScaling: false,
      keepRatio: false,
      rotateAnchorOffset: 20,

      // CT-1346 - Konva has unexpected behavior when transforming (changing scale) of objects
      // If a transform handle is dragged past it's opposite handle (ie we're scaling the object smaller)
      // instead of halting the transform Konva just halts the position of the handle the user is originally
      // interacting with and then proceeds to continue the mutation on the opposite handle.
      // We disallow anchors from ever crossing past their opposites.
      anchorDragBoundFunc: (oldPos, newPos) => {
        const { x, y, width, height } = this.originalNodeAttr
        const activeAnchor = this.rectTransform.getActiveAnchor()

        if (activeAnchor.startsWith('top')) {
          newPos.y = Math.min(newPos.y, y + height - DIMENSION_MINIMUM)
        }
        if (activeAnchor.startsWith('bottom')) {
          newPos.y = Math.max(newPos.y, y + DIMENSION_MINIMUM)
        }
        if (activeAnchor.endsWith('left')) {
          newPos.x = Math.min(newPos.x, x + width - DIMENSION_MINIMUM)
        }
        if (activeAnchor.endsWith('right')) {
          newPos.x = Math.max(newPos.x, x + DIMENSION_MINIMUM)
        }

        return newPos
      },

      boundBoxFunc: (oldBox, newBox) => {
        if (this.clips.length !== 1) {
          return newBox
        }

        const box = this.nodes[0].getClientRect() // CT-1404 - for now only consider single-selection transforms
        const x = box.x + box.width / 2
        const y = box.y + box.height / 2
        const rectCenter = { x, y }
        this.clips.forEach((c) => {
          // CT-1404 - apply transform to all clips (for now only one clip in selection)
          if (c.type === CLIP_TYPES.CAPTION) {
            this.captionTransformer(oldBox, newBox, c)
          } else if (c.type === CLIP_TYPES.STICKER) {
            this.stickerTransformer(oldBox, newBox, rectCenter, c)
          } else if (c.type === CLIP_TYPES.VIDEO) {
            this.videoTransformer(oldBox, newBox, c)
          }
        })
        this.timelineClass.seekTimeline()
        // Set delete button position when zooming and rotating
        this.setMenuNodePosition(newBox)
        return newBox
      },
    })
    this.rectTransform.on('mousedown', () => {
      this.$store.commit('setEditBoxStatus', true)
    })

    this.layer.add(this.rectTransform)

    // Initialize Delete button - only once
    const delBtnImg = await _getImage(delImg)
    this.deleteNode = new Konva.Image({
      x: -10000,
      y: -10000,
      image: delBtnImg,
      width: 46,
      height: 46,
      visible: false,
    })
    this.deleteNode.on('mousedown', ({ evt }) => {
      evt.stopPropagation()

      // CT-1406 Delete all selected clips
      Bus.$emit(Keys.deleteClips, this.clips)
      this.deregisterAllClips()
    })
    this.deleteNode.on('mouseenter', (e) => {
      e.target.getStage().container().style.cursor = 'pointer'
    })
    this.deleteNode.on('mouseleave', (e) => {
      e.target.getStage().container().style.cursor = 'default'
    })

    this.layer.add(this.deleteNode)

    // Initialize Menu button - only once
    // CT-1404 - The Menu Node will be hidden except when there is exactly one selected item
    const menuBtnImg = await _getImage(menuBtn)
    this.menuNode = new Konva.Image({
      x: -10000,
      y: -10000,
      image: menuBtnImg,
      width: 46,
      height: 46,
      visible: false,
    })
    this.layer.add(this.menuNode)
    this.setupMenuNode() // There is much more initialization for Menu button than Delete button, so it remains in a separate method

    // ***** TRANSFORM EVENTS *****

    // CT-1346 - Capture initial transform-node state
    this.rectTransform.on('transformstart', () => {
      // CT-1404 - For now only consider single-item selections for transform logic
      const { x, y, width, height } = WorkFlow.getCoordinateFromPoint(this.clips[0], this.timelineClass)
      this.originalNodeAttr = { x, y, width, height }
    })

    // CT-1344 - Capture current transform state to be used by the various asset type Transformers
    // Instead of having the other Transformers imperatively calculate mutations for each tick,
    // just use the delta between the original start and the current states
    this.rectTransform.on('transform', (e) => {
      const activeAnchor = this.rectTransform.getActiveAnchor()
      const { scaleX, scaleY } = e.target.attrs
      this.transformCurrentState = { scaleX, scaleY }

      // CT-1404 - For now only consider single-item selections for shift constraining logic
      if (this.isShiftPressed) {
        const { x, y } = WorkFlow.getCoordinateFromPoint(this.clips[0], this.timelineClass)
        if (activeAnchor === 'top-center' || activeAnchor === 'bottom-center') {
          // User is manipulating north/south handles - Apply vert scaling to horz
          this.nodes[0].scaleX(scaleY)
          this.nodes[0].x(x)
          this.transformCurrentState = { scaleX: scaleY, scaleY }
        } else if (activeAnchor === 'middle-right' || activeAnchor === 'middle-left') {
          // User is manipulating east/west handles - Apply horz scaling to vert
          this.nodes[0].scaleY(scaleX)
          this.nodes[0].y(y)
          this.transformCurrentState = { scaleX, scaleY: scaleX }
        }
      }
    })

    // CT-1409 - Invoke snap-shot state for undo/redo history, since these transforms
    // do not trigger Vuex state mutations which normally invoke snap-shotting
    this.rectTransform.on('transformend', () => {
      Bus.$emit(Keys.change_clipAsset)
    })

    // ***** DRAG EVENTS *****

    // CT-1296 - rectTransform is the source-of-truth for all dragging (repositioning) operations
    this.rectTransform.on('dragstart', () => {
      this.isRectTransformOutOfBounds = false

      // Snapshot starting position
      this.previousDragX = this.rectTransform.x()
      this.previousDragY = this.rectTransform.y()

      // Create a snap horizontal map (and same for vertical map) which maps a horizontal snap point to:
      // 1) List on vertical points that sit on that horizontal snap point axis-line, to draw correct length of guideline
      // 2) List of (konva) nodes of above points, to highlight matching creative objects
      // We only want to do these derivisions ONCE upon drag start to minimize repetition of work for efficiency/speed

      // CT-1726 - relate snap points to their canvas objects, for highlighting when snapped
      this.horizontalSnapPointMap.clear()
      this.verticalSnapPointMap.clear()

      // Private helper function to derive snap points from a given node and populate above maps
      const _addSnapPoints = function (node) {
        const { x, y, width, height } = node

        const horzPoints = [x, x + width / 2, x + width].map(Math.floor)
        const vertPoints = [y, y + height / 2, y + height].map(Math.floor)

        // Add node left/center/right to horizontal snap points
        horzPoints.forEach((h) => {
          const { points = [], nodes = [] } = this.horizontalSnapPointMap.get(h) || {}
          points.push(...vertPoints)
          nodes.push(node)

          this.horizontalSnapPointMap.set(h, { points, nodes })
        })

        // Add node top/middle/bottom to vertical snap points
        vertPoints.forEach((v) => {
          const { points = [], nodes = [] } = this.verticalSnapPointMap.get(v) || {}
          points.push(...horzPoints)
          nodes.push(node)

          this.verticalSnapPointMap.set(v, { points, nodes })
        })
      }.bind(this)

      // Start with snap points of project edges and center lines, and memoize for later use (out of bounds checking)
      const { screenLeftTopX, screenLeftTopY, screenWidth, screenHeight } = WorkFlow.getScreen({
        timelineClass: this.timelineClass,
      })
      this.projectBoundaries = { x: screenLeftTopX, y: screenLeftTopY, width: screenWidth, height: screenHeight }
      _addSnapPoints({
        x: screenLeftTopX,
        y: screenLeftTopY,
        width: screenWidth,
        height: screenHeight,
      })

      // Next add snap points of all non-selected clips of the current scene
      this.clipsOfCurrentScene.forEach((clip) => {
        if (!this.uuidClipMap.has(clip.uuid)) {
          _addSnapPoints(WorkFlow.getCoordinateFromPoint(clip, this.timelineClass))
        }
      })

      // Finally, generate reference array for snap points
      this.horizontalSnapPoints = Array.from(this.horizontalSnapPointMap.keys())
      this.verticalSnapPoints = Array.from(this.verticalSnapPointMap.keys())
    })

    this.rectTransform.on('dragmove', (e) => {
      // See documentation for terminology
      // https://fwn.atlassian.net/wiki/spaces/CE/pages/186974646/Guidelines+Snap+Grid+Rulers

      // As this is done on every mouse move we must be cognizant of performance and optimize where we can!!
      // This whole function is O(x+2y+z)*2 => O(n) time complexity, where
      // x is the loop to match snap point
      // y is the loop to update selected Konva nodes (remember we have group select) and then their respective clips
      // z is the loop to draw highlights
      // and the whole thing is done for vertical and horizontal guides

      const rectTransformX = e.target.x()
      const rectTransformY = e.target.y()
      const rectTransformWidth = e.target.width()
      const rectTransformHeight = e.target.height()
      const horzOffsets = {
        center: rectTransformWidth / 2,
        left: 0,
        right: rectTransformWidth,
      }
      const vertOffsets = {
        middle: rectTransformHeight / 2,
        top: 0,
        bottom: rectTransformHeight,
      }

      let matchedHSP = undefined
      let matchedVSP = undefined
      let snappedX = rectTransformX
      let snappedY = rectTransformY

      // In order of priority, try to match rectTransform horz/vert centers to snap points
      // then try to match rectTransform horz/vert edges to snap points
      // On successful match:
      // - snap transform rect
      // - set guideline location and display
      // - finally update creative asset(s) to also snap

      // Try to match horizontal snap points...
      for (const horzOffset of Object.values(horzOffsets)) {
        const edge = rectTransformX + horzOffset
        matchedHSP = this.horizontalSnapPoints.find(
          (hsp) => edge >= hsp - SNAP_TOLERANCE && edge <= hsp + SNAP_TOLERANCE,
        )

        if (matchedHSP !== undefined) {
          // This is the horizontal snap point the transform rect (one of its 3 horizontal offsets) snapped to
          snappedX = matchedHSP - horzOffset
          break // We snapped - don't continue looking other snaps
        }
      }

      // Try to match vertical snap points...
      for (const vertOffset of Object.values(vertOffsets)) {
        const edge = rectTransformY + vertOffset
        matchedVSP = this.verticalSnapPoints.find((vsp) => edge >= vsp - SNAP_TOLERANCE && edge <= vsp + SNAP_TOLERANCE)
        if (matchedVSP !== undefined) {
          // This is the vertical snap point the transform rect (one of its 3 vertical offsets) snapped to
          snappedY = matchedVSP - vertOffset
          break // We snapped - don't continue looking other snaps
        }
      }

      // Adjust all members of the transform rect by the horizontal and vertical deltas of the snap
      const snappedDX = snappedX - rectTransformX
      const snappedDY = snappedY - rectTransformY
      this.nodes.forEach((n) => {
        n.x(n.x() + snappedDX)
        n.y(n.y() + snappedDY)
      })

      // Short circuit here if the rectTransform hasn't moved, as we don't need to (re)draw or update anything
      if (this.previousDragX === snappedX && this.previousDragY === snappedY) {
        return
      }

      // Apply delta of previous dragged position to current position to reposition all selected clips
      this.updateClipPosition(new NvsPointF(this.previousDragX, this.previousDragY), new NvsPointF(snappedX, snappedY))
      this.previousDragX = snappedX
      this.previousDragY = snappedY

      // Finally show guidelines/snap highlights

      // Clear all guides/highlights before (re)drawing them, if we are going to redraw them
      _clearGuides()

      if (matchedHSP !== undefined) {
        // Show the vertical line that denotes a horizontal snap
        const { points, nodes } = this.horizontalSnapPointMap.get(matchedHSP)
        const yPoints = [...points, rectTransformY, rectTransformY + rectTransformHeight]

        // Vertical guideline should only be a long as top-most edge to bottom-most edge of snapped items
        this.verticalGuide.points([matchedHSP, Math.min(...yPoints), matchedHSP, Math.max(...yPoints)])
        this.verticalGuide.strokeWidth(1)

        // Show highlight on nodes we've snapped to
        nodes.forEach((n) => {
          this.verticalSnapHighlights.push(_createHighlight(n))
        })
      }

      if (matchedVSP !== undefined) {
        // Show the horizontal line that denotes a vertical snap
        const { points, nodes } = this.verticalSnapPointMap.get(matchedVSP)
        const xPoints = [...points, rectTransformX, rectTransformX + rectTransformWidth]

        // Horizontal guideline should only be a long as left-most edge to right-most edge of snapped items
        this.horizontalGuide.points([Math.min(...xPoints), matchedVSP, Math.max(...xPoints), matchedVSP])
        this.horizontalGuide.strokeWidth(1)

        // Show highlight on nodes we've snapped to
        nodes.forEach((n) => {
          this.horizontalSnapHighlights.push(_createHighlight(n))
        })
      }

      // CT-2023 - check if rectTransform is out of bounds, which signals to delete associated clips downstream
      this.isRectTransformOutOfBounds =
        rectTransformX + rectTransformWidth < this.projectBoundaries.x ||
        rectTransformX > this.projectBoundaries.x + this.projectBoundaries.width ||
        rectTransformY + rectTransformHeight < this.projectBoundaries.y ||
        rectTransformY > this.projectBoundaries.y + this.projectBoundaries.height
    })

    this.rectTransform.on('dragend', () => {
      // CT-1296 - hide guides if we're no longer dragging
      _clearGuides()

      // CT-2023 - If selected items OOB then deleted items
      if (this.isRectTransformOutOfBounds) {
        Bus.$emit(Keys.deleteClips, this.clips)
        this.deregisterAllClips()
      }

      // CT-1409 - Invoke snap-shot state for undo/redo history, since these transforms
      // do not trigger Vuex state mutations which normally invoke snap-shotting
      Bus.$emit(Keys.change_clipAsset)
    })

    // Private helper to generate Konva highlight nodes
    const _createHighlight = function ({ x, y, width, height }) {
      const highlight = new Konva.Rect({
        x,
        y,
        width,
        height,
        strokeWidth: 1,
        stroke: SNAP_COLOR,
        dash: [2, 3],
      })
      this.layer.add(highlight)
      return highlight
    }.bind(this)

    // Private helper to clear guides
    const _clearGuides = function () {
      this.horizontalGuide.strokeWidth(0)
      this.horizontalSnapHighlights.forEach((h) => h.destroy())
      this.verticalGuide.strokeWidth(0)
      this.verticalSnapHighlights.forEach((h) => h.destroy())
    }.bind(this)

    // Finally everything is ready, render
    this.layer.draw()
  }
  /**
   * Given and old and new position (of a Konva Transformer), calculate delta and apply change in position to a clip
   * CT-1377
   * @param {NvsPointF} startNvsPoint - The start position
   * @param {NvsPointF} endNvsPoint - The end position
   * @returns undefined
   */
  updateClipPosition(startNvsPoint, endNvsPoint) {
    const canonicalStartPoint = WorkFlow.aTob(startNvsPoint, this.timelineClass.liveWindow)
    const canonicalEndPoint = WorkFlow.aTob(endNvsPoint, this.timelineClass.liveWindow)
    const canonicalOffsetX = canonicalEndPoint.x - canonicalStartPoint.x
    const canonicalOffsetY = canonicalEndPoint.y - canonicalStartPoint.y
    const offsetPointF = new NvsPointF(canonicalOffsetX, canonicalOffsetY)
    // CT-1404 - Transform position of all items in selected group
    this.clips.forEach((c) => {
      if (c.type === CLIP_TYPES.CAPTION) {
        this.captionDrag(offsetPointF, c)
      } else if (c.type === CLIP_TYPES.STICKER) {
        this.stickerDrag(offsetPointF, c)
      } else if (c.type === CLIP_TYPES.VIDEO) {
        this.videoDrag(offsetPointF, c)
      }
    })
    this.timelineClass.seekTimeline()
    this.setMenuNodePosition()
  }

  /**
   * Given a clip, toggle its "selected" state
   * CT-1404
   * @param {Clip} clip - The clip to (de)select
   * @returns undefined
   */
  toggleClipRegistration(clip) {
    if (this.uuidClipMap.has(clip.uuid)) {
      this.deregisterClipFromTransformer(clip)
    } else {
      this.registerClipToTransformer(clip)
    }
  }
  /**
   * Update local state with a collection of clips (of the current scene)
   * CT-1296
   * @param [{Clip}] assets - a collection of clips
   * @returns undefined
   */
  setClipsOfCurrentScene(...assets) {
    this.clipsOfCurrentScene = assets
  }
  /**
   * Given a clip, create and add a node to the current Konva.Transformer
   * CT-1404
   * @param {Clip} clip - The clip to add to Transformer
   * @returns undefined
   */
  async registerClipToTransformer(clip) {
    this.clips.push(clip)
    this.uuidClipMap.set(clip.uuid, clip)
    this.$store.dispatch('edit/setCurrentlySelectedClips', this.clips)

    const { x, y, width, height } = WorkFlow.getCoordinateFromPoint(clip, this.timelineClass)
    const node = new Konva.Rect({
      id: clip.uuid,
      x,
      y,
      width,
      height,
      stroke: TRANSFORMER_BORDER_COLOR,
      strokeWidth: this.clips.length > 1 ? 1 : 0,
      draggable: true,
    })
    if (clip.rotation) {
      node.offsetX(node.width() / 2)
      node.offsetY(node.height() / 2)
      node.x(node.x() + node.width() / 2)
      node.y(node.y() + node.height() / 2)
      node.rotation(-clip.rotation)
    }
    this.nodes.push(node)
    this.layer.add(node)
    this.rectTransform.moveToTop() // Always keep transformer layer on top of everything, so that handles fully accessible

    this.adjustTransformerForMultipleClips()

    // CT-1404 - When user drags an asset around, all assets in a selected group will
    // fire their dragBoundFunc, which will incorrectly multiply the drag amount.  So
    // we dedupe by only allowing dragBoundFunc to proceed on the clicked asset
    node.on('mousedown', () => {
      this.dragNodeId = node.attrs.id
    })
    node.on('mouseup', () => {
      this.dragNodeId = undefined
    })

    // Special - Double-clicking on text assets should allow users to edit text
    if (clip.type === CLIP_TYPES.CAPTION) {
      node.on('dblclick', () => {
        this.captionInput(clip)
      })
    }

    // Add the node into selection
    this.rectTransform.nodes([...this.rectTransform.nodes(), node])

    this.setMenuNodePosition()
  }
  /**
   * Given a clip, tear it out of Transfomer
   * CT-1404
   * @param {Clip} clip - The clip to remove
   * @returns undefined
   */
  deregisterClipFromTransformer(clip) {
    const indexClipToDeselect = this.clips.findIndex((c) => c.uuid === clip.uuid)
    this.clips.splice(indexClipToDeselect, 1)
    this.$store.dispatch('edit/setCurrentlySelectedClips', this.clips)

    const indexNodeToDeselect = this.nodes.findIndex((n) => n.attrs.id === clip.uuid)
    this.nodes[indexNodeToDeselect].destroy()
    this.nodes.splice(indexNodeToDeselect, 1)
    this.rectTransform.nodes(this.nodes)

    this.uuidClipMap.delete(clip.uuid)

    this.setMenuNodePosition()
    this.adjustTransformerForMultipleClips()
  }
  /**
   * Deselect all clips (remove all nodes from Konva.Transformer)
   * CT-1404
   * @param none
   * @returns undefined
   */
  deregisterAllClips() {
    this.clips = []
    this.$store.dispatch('edit/setCurrentlySelectedClips', this.clips)
    this.uuidClipMap = new Map()
    this.nodes.forEach((n) => n.destroy())
    this.nodes = []
    this.rectTransform.nodes([])
    this.setMenuNodePosition()
  }
  /**
   * The appearance and allowed functions of selected clips changes based on the
   * number and type of clips selected, ie 1 vs many
   * CT-1404 - For now we only allow positional transforms on a group - scaling/rotation/etc will come later
   *
   * Todo - when WorkFlow becomes a Vue Component, change this to a watcher
   *
   * @param none
   * @returns undefined
   */
  adjustTransformerForMultipleClips() {
    const isOnlyOneItemSelected = this.clips.length === 1

    // CT-1404 - Special adjustment for "highlight" appearance of Transformer and its children
    this.rectTransform.borderStroke(isOnlyOneItemSelected ? TRANSFORMER_BORDER_COLOR : '#fff')
    this.rectTransform.borderDash(isOnlyOneItemSelected ? [0] : [4, 4])

    // CT-1404 - When there is >1 selected items then each item should have its
    // own visual indication that it is part of the selected group of items.  We only need
    // to worry about first node as all subsequent nodes are taken care of at time of their
    // initialization - see registerClipToTransformer
    this.nodes[0] && this.nodes[0].strokeWidth(this.clips.length > 1 ? 1 : 0)

    // CT-1404 - For now only allow scaling when there only one selected item... memoize start
    // state of a single clip
    // CT-1344 - Capture immutable snapshot of starting state of clip we're mutating
    this.originalClip = isOnlyOneItemSelected ? Object.freeze(JSON.parse(JSON.stringify(this.clips[0]))) : undefined

    this.rectTransform.rotateEnabled(isOnlyOneItemSelected && this.clips[0].type === CLIP_TYPES.STICKER)

    this.rectTransform.enabledAnchors(
      isOnlyOneItemSelected
        ? [
            'top-left',
            'top-center',
            'top-right',
            'middle-right',
            'middle-left',
            'bottom-left',
            'bottom-center',
            'bottom-right',
          ]
        : [],
    )

    if (!isOnlyOneItemSelected) {
      return // Below is side-effects - short-circuit here if >1 item selected
    }

    // CT-1378 - adjust behavior/appearance of transformer (only for single selected item) based on asset type
    if (this.clips[0].type === CLIP_TYPES.CAPTION) {
      this.rectTransform.anchorCornerRadius(4)
      this.rectTransform.keepRatio(false)
    } else {
      this.rectTransform.anchorCornerRadius(0)
      this.rectTransform.keepRatio(true)
    }
  }

  // type = 3 for frame caption bounding vertices computation
  static getCoordinateFromPoint(clip, timelineClass) {
    if (clip.type === CLIP_TYPES.VIDEO) {
      return WorkFlow.getTransform2DVideoFxPoint({
        videoFxs: clip.splitList[0].videoFxs,
        clipM3u8Path: clip.m3u8Path,
        timelineClass,
      })
    }
    const rotation = Math.round((clip?.raw?.getRotationZ?.() || 0) * 100) / 100
    let vertices
    if (clip.type === CLIP_TYPES.CAPTION) {
      const captionBoundingType = clip.raw.isFrameCaption()
        ? NvsCaptionTextBoundingTypeEnum.TextOriginFrame
        : NvsCaptionTextBoundingTypeEnum.Text
      vertices = clip.raw.getCaptionBoundingVertices(captionBoundingType)
    } else if (clip.type === CLIP_TYPES.STICKER) {
      vertices = clip.raw.getBoundingRectangleVertices()
    }
    if (!vertices) {
      console.warn('获取素材边框失败')
      return {}
    }
    let i1, i2, i3, i4
    if (clip.type === CLIP_TYPES.STICKER) {
      const v = WorkFlow.getVerticesPoint(vertices, timelineClass.liveWindow)
      i1 = v.i1
      i2 = v.i2
      i3 = v.i3
      i4 = v.i4
    } else {
      const { translationX, translationY } = clip
      const f = (i) => ({ x: i.x + translationX, y: i.y + translationY }) // 框的位置基本不变，需要加上transition
      i1 = f(vertices.get(0))
      i2 = f(vertices.get(1))
      i3 = f(vertices.get(2))
      i4 = f(vertices.get(3))
      // - counter clockwise i1, i2, i3, i4
      i1 = WorkFlow.bToa(new NvsPointF(i1.x, i1.y), timelineClass.liveWindow)
      i2 = WorkFlow.bToa(new NvsPointF(i2.x, i2.y), timelineClass.liveWindow)
      i3 = WorkFlow.bToa(new NvsPointF(i3.x, i3.y), timelineClass.liveWindow)
      i4 = WorkFlow.bToa(new NvsPointF(i4.x, i4.y), timelineClass.liveWindow)
    }

    let x
    let y
    let width
    let height

    if (!rotation) {
      width = i3.x - i1.x
      height = i2.y - i1.y

      if (width > 0) {
        x = i1.x
      } else {
        x = i3.x
      }

      if (height > 0) {
        y = i1.y
      } else {
        y = i2.y
      }

      width = Math.abs(width)
      height = Math.abs(height)
    } else {
      width = Math.sqrt(Math.pow(i4.y - i1.y, 2) + Math.pow(i4.x - i1.x, 2))
      height = Math.sqrt(Math.pow(i4.x - i3.x, 2) + Math.pow(i3.y - i4.y, 2))
      const oX = i3.x + (i1.x - i3.x) / 2
      const oY = i4.y + (i2.y - i4.y) / 2
      x = oX - width / 2
      y = oY - height / 2
    }
    return {
      x,
      y,
      width,
      height,
      rotation,
    }
  }
  static getCurrentTimelinePosition(timelineClass) {
    const { timeline, streamingContext } = timelineClass
    if (timeline !== null && timeline !== undefined && streamingContext !== undefined) {
      return streamingContext.getTimelineCurrentPosition(timeline)
    }
    return 0
  }

  static getVerticesPoint(vertices, liveWindow) {
    // - counter clockwise
    // - ptr.get(0) top left
    // - ptr.get(1) bottom left
    // - ptr.get(2) bottom right
    // - ptr.get(3) top right
    const i1 = WorkFlow.bToa(vertices.get(0), liveWindow)
    const i2 = WorkFlow.bToa(vertices.get(1), liveWindow)
    const i3 = WorkFlow.bToa(vertices.get(2), liveWindow)
    const i4 = WorkFlow.bToa(vertices.get(3), liveWindow)

    return { i1, i2, i3, i4 }
  }
  //  liveWindow -> canvas
  static bToa(coordinate, liveWindow) {
    // 渲染层 to 视口层
    if (!liveWindow) {
      console.warn('Missing liveWindow.')
      return
    }
    return liveWindow.mapCanonicalToView(coordinate)
  }
  destroy() {
    if (this.stage && this.stage instanceof Konva.Stage) {
      this.stage.destroy()
    }
  }
  // canvas -> liveWindow
  static aTob(coordinate, liveWindow) {
    // 视口层 to 渲染层
    return liveWindow.mapViewToCanonical(coordinate)
  }
  captionDrag(offsetPointF, clip) {
    clip.raw.translateCaption(offsetPointF)
    const targetPointF = clip.raw.getCaptionTranslation()
    clip.translationX = targetPointF.x
    clip.translationY = targetPointF.y
  }
  stickerDrag(offsetPointF, clip) {
    clip.raw.translateAnimatedSticker(offsetPointF)
    const targetPointF = clip.raw.getTranslation()
    clip.translationX = targetPointF.x
    clip.translationY = targetPointF.y
  }

  setVideoTranslationOffset(offsetPointF, clip) {
    const fx = _getFx(clip, FX_DESC.TRANSFORM2D)
    const translationX = _lookupParam(fx, TRANSFORM2D_KEYS.TRANS_X)
    const translationY = _lookupParam(fx, TRANSFORM2D_KEYS.TRANS_Y)

    fx.raw.setFloatVal(TRANSFORM2D_KEYS.TRANS_X, (translationX.value += offsetPointF.x))
    fx.raw.setFloatVal(TRANSFORM2D_KEYS.TRANS_Y, (translationY.value += offsetPointF.y))
  }
  videoDrag(offsetPointF, clip) {
    this.setVideoTranslationOffset(offsetPointF, clip)
    const { videoWidth, videoHeight } = this.$store.state.clip
    const wRatio = videoWidth / 2
    const hRatio = videoHeight / 2

    {
      const cropMosaicFx = _getFx(clip, FX_DESC.MOSAIC)
      if (!cropMosaicFx) {
        return
      }
      const { value } = _lookupParam(cropMosaicFx, CROP_KEYS.REGION)
      const region = new NvsVectorFloat()
      // pos1
      region.push_back((value.x1 += offsetPointF.x / wRatio))
      region.push_back((value.y1 += offsetPointF.y / hRatio))
      // pos2
      region.push_back((value.x2 += offsetPointF.x / wRatio))
      region.push_back((value.y2 += offsetPointF.y / hRatio))
      // pos3
      region.push_back((value.x3 += offsetPointF.x / wRatio))
      region.push_back((value.y3 += offsetPointF.y / hRatio))
      // pos4
      region.push_back((value.x4 += offsetPointF.x / wRatio))
      region.push_back((value.y4 += offsetPointF.y / hRatio))

      cropMosaicFx.raw.setRegion(region)
    }
  }
  /**
   * Mutate (position, scale, rotation) a media asset (image/video)
   *
   * @param {NvsRectF} oldBox - the Konva transform rect before user mutations
   * @param {NvsRectF} newBox - the Konva transform rect after user mutations
   * @param {Clip} clip - the media asset to apply changes to
   * @returns {void}
   *
   * Note that we don't care about oldBox/newBox coordinates as we only need to calculate/use the delta between them.
   */
  videoTransformer(oldBox, newBox, clip) {
    this.setVideoTranslationOffset(this.getCenterDiffBetweenBoxes(clip, oldBox, newBox, true), clip)

    const originalFx = _getFx(this.originalClip, FX_DESC.TRANSFORM2D)
    const originalScaleX = _lookupParam(originalFx, TRANSFORM2D_KEYS.SCALE_X)
    const originalScaleY = _lookupParam(originalFx, TRANSFORM2D_KEYS.SCALE_Y)

    const fx = _getFx(clip, FX_DESC.TRANSFORM2D)
    const scaleX = _lookupParam(fx, TRANSFORM2D_KEYS.SCALE_X)
    const scaleY = _lookupParam(fx, TRANSFORM2D_KEYS.SCALE_Y)
    const translationX = _lookupParam(fx, TRANSFORM2D_KEYS.TRANS_X)
    const translationY = _lookupParam(fx, TRANSFORM2D_KEYS.TRANS_Y)
    const rotation = _lookupParam(fx, TRANSFORM2D_KEYS.ROTATION)

    scaleX.value = originalScaleX.value * this.transformCurrentState.scaleX
    scaleY.value = originalScaleY.value * this.transformCurrentState.scaleY
    fx.raw.setFloatVal(TRANSFORM2D_KEYS.SCALE_X, scaleX.value)
    fx.raw.setFloatVal(TRANSFORM2D_KEYS.SCALE_Y, scaleY.value)

    // Set rotation transform value
    const diffRotation = oldBox.rotation - newBox.rotation
    if (diffRotation) {
      rotation.value += (diffRotation / Math.PI) * 180
      fx.raw.setFloatVal(TRANSFORM2D_KEYS.ROTATION, rotation.value)
    }

    // Apply scaling with respect to Meishe Crop cropping point, if applicable
    const cropMosaicFx = _getFx(clip, FX_DESC.MOSAIC)
    if (!cropMosaicFx) {
      return
    }
    const { value } = _lookupParam(cropMosaicFx, CROP_KEYS.REGION)
    const region = new NvsVectorFloat()

    // CT-2023 Account for offset of the canvas wrt the screen and normalize 'newBox' to the canvas
    const { screenLeftTopX, screenLeftTopY, screenWidth, screenHeight } = WorkFlow.getScreen({
      timelineClass: this.timelineClass,
    })
    const newBoxLeft = newBox.x - screenLeftTopX
    const newBoxTop = newBox.y - screenLeftTopY
    const newBoxWidth = newBox.width
    const newBoxHeight = newBox.height

    /*
     * Note on crop mosaic region coordinates:
     *
     * p1  p4
     *
     * p2  p3
     *
     * ie, x1,y1 is the top left corner, x2,y2 is the bottom left corner, etc
     *
     * Each coordinate is normalized to the range [-1, 1] where -1 is the left/bottom edge of the canvas
     * and 1 is the right/top edge of the canvas, ie
     *
     *       +1
     *       |
     * -1 ---|--- +1
     *       |
     *       -1
     *
     */
    value.x1 = (newBoxLeft - screenWidth / 2) / (screenWidth / 2)
    value.y1 = (screenHeight / 2 - (newBoxTop + newBoxHeight)) / (screenHeight / 2)
    value.x2 = (newBoxLeft - screenWidth / 2) / (screenWidth / 2)
    value.y2 = (screenHeight / 2 - newBoxTop) / (screenHeight / 2)
    value.x3 = (newBoxLeft + newBoxWidth - screenWidth / 2) / (screenWidth / 2)
    value.y3 = (screenHeight / 2 - newBoxTop) / (screenHeight / 2)
    value.x4 = (newBoxLeft + newBoxWidth - screenWidth / 2) / (screenWidth / 2)
    value.y4 = (screenHeight / 2 - (newBoxTop + newBoxHeight)) / (screenHeight / 2)
    // p1: top left
    region.push_back(value.x1)
    region.push_back(value.y1)
    // p2: bottom left
    region.push_back(value.x2)
    region.push_back(value.y2)
    // p3: bottom right
    region.push_back(value.x3)
    region.push_back(value.y3)
    // p4: top right
    region.push_back(value.x4)
    region.push_back(value.y4)

    cropMosaicFx.raw.setRegion(region)

    // update translation
    clip.translationX = translationX
    clip.translationY = translationY
  }
  captionTransformer(oldBox, newBox, clip) {
    const { left, right, top, bottom } = this.getRect(clip, newBox)
    const rectWidth = right - left
    const rectHeight = top - bottom
    const rect = new NvsRectF(-rectWidth / 2, rectHeight / 2, rectWidth / 2, -rectHeight / 2)

    // saved for debugging frame caption
    //     console.log(`isFrameCaption ${clip.raw.isFrameCaption()}
    //     ┌────── Top:${rect.top.toString().substring(0, 4)} ──────┐
    // Left:${rect.left.toFixed(2)}            Right:${rect.right.toFixed(2)}
    //     └──── Bottom:${rect.bottom.toString().substring(0, 4)} ─────┘
    //     `);
    clip.raw.translateCaption(this.getCenterDiffBetweenBoxes(clip, oldBox, newBox, false))
    if (clip.raw.isFrameCaption()) {
      clip.raw.setTextFrameOriginRect(rect)
    }
    // Remember frameWidth, Height
    const { videoWidth, videoHeight } = this.$store.state.clip
    clip.frameWidth = toPercentage(rectWidth / videoWidth)
    clip.frameHeight = toPercentage(rectHeight / videoHeight)
    // Update rotation
    const diffRotation = oldBox.rotation - newBox.rotation
    if (diffRotation) {
      clip.raw.rotateCaption2((diffRotation / Math.PI) * 180)
      clip.rotation = Math.round((clip?.raw?.getRotationZ?.() || 0) * 100) / 100
    }
    // Update position
    const targetPointF = clip.raw.getCaptionTranslation()
    clip.translationX = targetPointF.x
    clip.translationY = targetPointF.y
  }
  stickerTransformer(oldBox, newBox, rectCenter, clip) {
    const centerPointF = new NvsPointF(rectCenter.x, rectCenter.y)

    // Set scale transform values
    clip.raw.setSeperatedScaleX(this.originalClip.scaleX * this.transformCurrentState.scaleX)
    clip.raw.setSeperatedScaleY(this.originalClip.scaleY * this.transformCurrentState.scaleY)
    clip.scaleX = clip.raw.getSeperatedScaleX()
    clip.scaleY = clip.raw.getSeperatedScaleY()

    // 旋转操作
    const diffRotation = oldBox.rotation - newBox.rotation
    if (diffRotation) {
      clip.raw.rotateAnimatedSticker(
        (diffRotation / Math.PI) * 180,
        WorkFlow.aTob(centerPointF, this.timelineClass.liveWindow),
      )
      clip.rotation = Math.round((clip?.raw?.getRotationZ?.() || 0) * 100) / 100
    } else {
      clip.raw.translateAnimatedSticker(this.getCenterDiffBetweenBoxes(clip, oldBox, newBox, false))
    }
    // 重新记录位置
    const targetPointF = clip.raw.getTranslation()
    clip.translationX = targetPointF.x
    clip.translationY = targetPointF.y
  }
  getCenterDiffBetweenBoxes(clip, oldBox, newBox, isVideo) {
    const { left, right, top, bottom } = this.getRect(clip, newBox, isVideo)
    const center = { x: (left + right) / 2, y: (top + bottom) / 2 }
    const { left: oldLeft, right: oldRight, top: oldTop, bottom: oldBottom } = this.getRect(clip, oldBox, isVideo)
    const oldCenter = {
      x: (oldLeft + oldRight) / 2,
      y: (oldTop + oldBottom) / 2,
    }
    return new NvsPointF(center.x - oldCenter.x, center.y - oldCenter.y)
  }
  estimateNumberOfLines(text, frameWidthPx, frameHeightPx, lineWeight, letterSpacing, letterSpacingType) {
    const SINGLE_CHARACTER_AVG_ASPECT_RATIO = 0.4515 // the aspect ratio of single letter

    const letterSpacingScale = letterSpacingType === 1 ? 0.01 * (letterSpacing + 100) : 0.01 * letterSpacing
    const lineSpacingScale = lineWeight / 1.25

    // the aspect ratio of caption frame
    const frameRatio = frameWidthPx / frameHeightPx

    // the aspect ratio if the entire string is displayed on one line
    const textOneLineRatio = (text.length * SINGLE_CHARACTER_AVG_ASPECT_RATIO * letterSpacingScale) / lineSpacingScale

    // a continuous value that estimates the number of lines needed to fit text in frame
    const numberOfLines_float = Math.sqrt(textOneLineRatio / frameRatio)

    if (textOneLineRatio / frameRatio < 2) {
      return 1
    } else {
      return Math.floor(numberOfLines_float)
    } // return an integer no greater than "numberOfLines_float"
  }
  processFontFamilyName(fontFamily) {
    // when font family name = "Alfa Slab One [AlfaSlabOne-Regular]"
    // remove the bracket part

    if (fontFamily.includes('[') && fontFamily.includes(']')) {
      return fontFamily.substring(0, fontFamily.indexOf('['))
    } else {
      return fontFamily
    }
  }
  calculateInputFontSize(captionClip, canvas, context, frameLeftPx, frameTopPx, frameWidthPx, frameHeightPx) {
    const captionClipRaw = captionClip.raw
    const text = captionClipRaw.getText()
    const fontFamily = this.processFontFamilyName(captionClipRaw.getFontFamily() || 'sans-serif')
    const fontWeight = captionClipRaw.getBold() ? '600' : '300'
    const maxFontSize = captionClipRaw.getFontSize()
    const lineHeight = this.calculateLineWeightByLineSpacing(
      captionClipRaw.getLineSpacing() / captionClipRaw.getFontSize(),
    )

    const fontInfo = `${fontWeight} ${maxFontSize}px ${fontFamily}`
    context.font = fontInfo
    const numberOfLines = this.estimateNumberOfLines(
      text,
      frameWidthPx,
      frameHeightPx,
      lineHeight,
      captionClipRaw.getLetterSpacing(),
      captionClipRaw.getLetterSpacingType(),
    )

    const metrics = context.measureText(text.substring(0, Math.ceil(text.length / numberOfLines)))
    const lineWeightScale = this.calculateScaleOverDefaultLineWeight(
      captionClipRaw.getLineSpacing() / captionClipRaw.getFontSize(),
    )
    const fontHeight =
      (metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent) * numberOfLines * lineWeightScale

    const SCALE_BY_WIDTH_RATIO = numberOfLines === 1 ? 0.96 : 0.92
    const SCALE_BY_HEIGHT_RATIO = 0.92
    const fontSize_byWidth = (frameWidthPx / metrics.width) * maxFontSize * SCALE_BY_WIDTH_RATIO
    const fontSize_byHeight = (frameHeightPx / fontHeight) * maxFontSize * SCALE_BY_HEIGHT_RATIO

    let fontSize = fontSize_byWidth < fontSize_byHeight ? fontSize_byWidth : fontSize_byHeight
    if (!fontSize) {
      fontSize = 96
    } else if (fontSize > maxFontSize) {
      fontSize = maxFontSize
    }

    return fontSize
  }
  calculateLineWeightByLineSpacing(lineSpacing) {
    const DEFAULT_LINE_HEIGHT = 1.25
    const POSITIVE_RESCALE_RATIO = 0.01 * 0.4
    const NEGATIVE_RESCALE_RATIO = 0.01 * 0.25

    const rescale_ratio = lineSpacing >= 0 ? POSITIVE_RESCALE_RATIO : NEGATIVE_RESCALE_RATIO

    return DEFAULT_LINE_HEIGHT + rescale_ratio * lineSpacing
  }
  calculateScaleOverDefaultLineWeight(lineSpacing) {
    const DEFAULT_LINE_HEIGHT = 1.25
    return this.calculateLineWeightByLineSpacing(lineSpacing) / DEFAULT_LINE_HEIGHT
  }
  captionInput(captionClip) {
    // CT-1404 - for now only allow text-edit if only one item selected and that item is a caption
    if (this.clips.length > 1) {
      return
    }

    this.$store.commit('setIsEdit', true)
    const captionClipRaw = captionClip.raw
    loadFonts(captionClip.fontUrl, captionClipRaw.getFontFamily())
    const text = captionClipRaw.getText()
    // captionClipRaw.setText('')
    const captionColor = captionClipRaw.getTextColor()
    captionClipRaw.setTextColor(new NvsColor(0, 0, 0, 0))
    this.timelineClass.seekTimeline()
    const input = document.createElement('textarea')
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    captionClip.italic && (input.style.fontStyle = 'italic')
    captionClip.bold && (input.style.fontWeight = 'bold')
    captionClip.underline && (input.style.textDecoration = 'underline')

    const { x, y, width, height } = WorkFlow.getCoordinateFromPoint(captionClip, this.timelineClass)
    input.style.textAlign = captionClip.align || 'center'
    input.style.boxSizing = 'content-box'
    input.style.position = 'absolute'
    input.style.width = width + 'px'
    input.style.height = height + 'px'
    input.style.lineHeight = this.calculateLineWeightByLineSpacing(
      captionClipRaw.getLineSpacing() / captionClipRaw.getFontSize(),
    )
    input.style.display = 'inline-block'
    input.style.verticalAlign = 'middle'
    input.style.fontFamily = this.processFontFamilyName(captionClipRaw.getFontFamily() || 'sans-serif')
    input.style.fontWeight = captionClipRaw.getBold() ? '600' : '300'
    input.style.fontSize = this.calculateInputFontSize(captionClip, canvas, context, x, y, width, height) + 'px'

    input.style.left = x + 'px'
    input.style.top = y + 'px'
    input.style.zIndex = 200
    input.style.display = 'block'
    input.style.border = 0
    input.style.backgroundColor = 'transparent'
    const { r, g, b, a } = captionColor
    input.style.color = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a})`

    // const { r: bR, g: bG, b: bB, a: bA } = captionClipRaw.getBackgroundColor();
    // input.style.backgroundColor = `rgba(${bR * 255}, ${bG * 255}, ${bB * 255}, ${bA})`;

    input.style.outline = 0
    input.value = text + ''
    if (text === ' ') {
      input.value = ''
    }

    const rotation = this.rectTransform.rotation()
    if (rotation) {
      input.style.transform = `rotate(${rotation}deg)`
      input.style.transformOrigin = 'top left'
    }

    const onChange = () => {
      if (!(input.value + '').length) {
        input.value = ' '
      }
      captionClipRaw.setText(input.value + '')
      captionClip.text = captionClipRaw.getText()
      input.onfocus = null
      input.oninput = null
      input.onblur = null
      input.onchange = null
      input.remove()
      setTimeout(() => {
        captionClipRaw.setTextColor(captionColor)
        this.$store.commit('setIsEdit', false)
        this.timelineClass.seekTimeline()
        this.$store.commit('clip/updateClipToVuex', captionClip)
      })
    }
    input.onblur = onChange
    input.onchange = onChange
    input.ondblclick = (e) => e.stopPropagation()
    input.onclick = (e) => e.stopPropagation()
    this.container.appendChild(input)
    input.focus()
  }
  /**
   * Initialize Menu button (only extra video options currently)
   * This is a separate function to the initRect function small
   *
   * CT-1404 For now, Menu button only shows when there is exactly one selected item and
   * that item is a Video clip.  So, all actions for now will target the first item in this.clips
   *
   * @param none
   * @returns undefined
   */
  setupMenuNode() {
    this.menuNode.on('mousedown', async ({ evt }) => {
      evt.stopPropagation()

      const operateName = await this.callMenu({
        pos: { x: evt.clientX + 20, y: evt.clientY },
      })

      if (
        [
          MENU_OPTIONS.SEND_BACKWARD,
          MENU_OPTIONS.SEND_TO_BACK,
          MENU_OPTIONS.BRING_FORWARD,
          MENU_OPTIONS.BRING_TO_FRONT,
        ].includes(operateName)
      ) {
        this.changeMediaLayerOrder(operateName)
        return
      }
      // const isBackgroundMedia = isClipBackgroundMedia(this.clips[0])
      // const parentClip = findUserAddedClipParent(this.clips[0])
      // const index = parentClip?.userAddedClips.findIndex((clip) => clip.uuid === this.clips[0].uuid)
      // TODO: isBackgroundMedia / index to be used in follow-up stories of detach from background / send to background
      if (operateName === MENU_OPTIONS.TRIM) {
        // CT-2070 - update application state with media clip to edit (to open the crop dialog)
        this.clips[0].isUserAddedClip = true //FIXME - rather than polluting object put this state in vuex
        this.$store.dispatch('mediaCropping/setClip', this.clips[0])
        this.$store.dispatch('mediaCropping/setIsRectChanged', false)
      } else if (operateName === MENU_OPTIONS.COPY) {
        Bus.$emit(Keys.copy)
      } else if (operateName === MENU_OPTIONS.PASTE) {
        Bus.$emit(Keys.paste)
      } else if (operateName === MENU_OPTIONS.DELETE) {
        Bus.$emit(Keys.deleteClips, [this.clips[0]])
        this.deregisterClipFromTransformer(this.clips[0])
      } else if (operateName === MENU_OPTIONS.REPLACE) {
        const parentClip = findUserAddedClipParent(this.clips[0])
        const index = parentClip.userAddedClips.findIndex((clip) => clip.uuid === this.clips[0].uuid)
        this.$store.commit('clip/setIsSelectForReplaceUserAddedClip', true)
        Bus.$emit(Keys.openMediaDialog, true)
        Bus.$once(Keys.swapUserAddedClip, (clips) => {
          if (clips.length) {
            clips[0].duration /= 1000000
            Bus.$emit(Keys.openMediaDialog, false)
            findUserAddedClipParent(this.clips[0])
            Bus.$emit(Keys.editClip, null, {
              type: CLIP_TYPES.VIDEO,
              target: clips[0],
              beReplacedClip: parentClip.userAddedClips[index],
            })
          }
          this.$store.commit('clip/setIsSelectForReplaceUserAddedClip', false)
        })
      }
      Bus.$emit(Keys.rebuildTimeline)
    })
    this.menuNode.on('mouseenter', (e) => {
      e.target.getStage().container().style.cursor = 'pointer'
    })
    this.menuNode.on('mouseleave', (e) => {
      e.target.getStage().container().style.cursor = 'default'
    })
  }
  /**
   * CT-1611 - Method to change layer order of currently selected clip (for type=media only)
   *
   * @param {string} operation - the operation to perform
   * @returns undefined
   */
  changeMediaLayerOrder(operation) {
    // Only allow layer change operation when one exactly one clip is selected
    if (this.clips.length !== 1) {
      return
    }

    const parentClip = findUserAddedClipParent(this.clips[0])
    if (!parentClip) {
      return
    }

    const index = parentClip.userAddedClips.findIndex((clip) => clip.uuid === this.clips[0].uuid)
    switch (operation) {
      case MENU_OPTIONS.SEND_BACKWARD:
        if (!index) {
          return
        }
        parentClip.userAddedClips.splice(index - 1, 0, parentClip.userAddedClips.splice(index, 1)[0])
        break
      case MENU_OPTIONS.SEND_TO_BACK:
        if (!index) {
          return
        }
        parentClip.userAddedClips.splice(0, 0, parentClip.userAddedClips.splice(index, 1)[0])
        break
      case MENU_OPTIONS.BRING_FORWARD:
        if (index === parentClip.userAddedClips.length - 1) {
          return
        }
        parentClip.userAddedClips.splice(index + 1, 0, parentClip.userAddedClips.splice(index, 1)[0])
        break
      case MENU_OPTIONS.BRING_TO_FRONT:
        if (index === parentClip.userAddedClips.length - 1) {
          return
        }
        parentClip.userAddedClips.splice(
          parentClip.userAddedClips.length - 1,
          0,
          parentClip.userAddedClips.splice(index, 1)[0],
        )
        break
    }
    Bus.$emit(Keys.rebuildTimeline)
  }

  // 计算区域
  getRect(clip, box, isVideoClip) {
    const { width: viewWidth, height: viewHeight } = this.container.getBoundingClientRect()
    const { x, y, width, height } = box
    const liveWindow = this.timelineClass.liveWindow
    // calculate the left,top of rect under liveWindow coordination
    let { x: left, y: top } = WorkFlow.aTob(new NvsPointF(x, y), liveWindow)
    if (isVideoClip) {
      // for video clip, translation info can be found in videofx
      const fx = _getFx(clip, FX_DESC.TRANSFORM2D)
      const translationX = _lookupParam(fx, TRANSFORM2D_KEYS.TRANS_X)
      const translationY = _lookupParam(fx, TRANSFORM2D_KEYS.TRANS_Y)

      left -= translationX.value
      top -= translationY.value
    } else {
      // for caption and sticker clip
      left -= clip.translationX
      top -= clip.translationY
    }
    // calculate the width,height of rect under liveWindow coordination
    let { x: liveWidth, y: liveHeight } = WorkFlow.aTob(
      new NvsPointF(width + viewWidth / 2, height + viewHeight / 2),
      liveWindow,
    )
    liveWidth = Math.abs(liveWidth)
    liveHeight = Math.abs(liveHeight)
    const right = left + liveWidth
    const bottom = top - liveHeight
    return {
      left,
      right,
      top,
      bottom,
    }
  }

  /** 以下三个method 用于辅助计算videoClip的位置 */
  static ratioScreen(screen, ratio) {
    let width = screen.width
    let height = screen.height

    const oRatio = width / height
    if (oRatio > ratio) {
      // 宽过长
      width = height * ratio
    } else {
      // 高过长
      height = width / ratio
    }

    return {
      width,
      height,
    }
  }
  static getScreen({ timelineClass }) {
    let { offsetWidth: screenWidth, offsetHeight: screenHeight } = document.getElementById('live-window')
    const screenCenterX = screenWidth / 2
    const screenCenterY = screenHeight / 2

    const resolution = timelineClass.timeline.getVideoRes()

    const ratio = resolution.imageWidth / resolution.imageHeight

    const change = WorkFlow.ratioScreen(
      {
        width: screenWidth,
        height: screenHeight,
      },
      ratio,
    )

    screenWidth = change.width
    screenHeight = change.height

    return {
      screenHeight,
      screenWidth,
      screenCenterX,
      screenCenterY,

      screenLeftTopX: screenCenterX - screenWidth / 2,
      screenLeftTopY: screenCenterY - screenHeight / 2,
      screenLeftBottomX: screenCenterX - screenWidth / 2,
      screenLeftBottomY: screenCenterY + screenHeight / 2,

      screenRightTopX: screenCenterX + screenWidth / 2,
      screenRightTopY: screenCenterY - screenHeight / 2,
      screenRightBottomX: screenCenterX + screenWidth / 2,
      screenRightBottomY: screenCenterY + screenHeight / 2,
    }
  }
  static getTransform2DVideoFxPoint({
    videoFxs,
    clipM3u8Path,
    timelineClass,
    resetScale = false,
    noRegion = false, // 完整素材尺寸，不考虑裁剪mosaic
  }) {
    const videoFx = videoFxs.find((fx) => fx.desc === FX_DESC.TRANSFORM2D)
    const cropMosicFx = videoFxs.find((fx) => fx.desc === FX_DESC.MOSAIC)
    const { width: videoWidth, height: videoHeight } = timelineClass

    if (!videoFx || !videoFx.raw) {
      return console.warn('$monitorGetTransform2DVideoFxPoint video_fx null')
    }
    const videoFxRaw = videoFx.raw

    const originRotation = videoFxRaw.getFloatVal('Rotation')
    let transX = videoFxRaw.getFloatVal('Trans X')
    let transY = videoFxRaw.getFloatVal('Trans Y')
    const anchorX = videoFxRaw.getFloatVal(TRANSFORM2D_KEYS.ANCHOR_X)
    const anchorY = videoFxRaw.getFloatVal(TRANSFORM2D_KEYS.ANCHOR_Y)
    let originScaleFactorX = videoFxRaw.getFloatVal('Scale X')
    let originScaleFactorY = videoFxRaw.getFloatVal('Scale Y')

    if (resetScale) {
      transX = 0
      transY = 0
      originScaleFactorX = 1
      originScaleFactorY = 1
    }
    const viewCenterPoint = WorkFlow.bToa(
      new NvsPointF(transX + anchorX * (1 - originScaleFactorX), transY + anchorY * (1 - originScaleFactorY)),
      timelineClass.liveWindow,
    )

    let screenHeight
    let screenWidth
    const screen = WorkFlow.getScreen({ timelineClass })

    if (clipM3u8Path) {
      const { videoStreamInfo } = timelineClass.streamingContext.getAVFileInfo(clipM3u8Path, 0)

      const ratio = videoWidth / videoHeight
      let realWidth = videoStreamInfo.width
      let realHeight = videoStreamInfo.height
      if (videoStreamInfo.rotation === 1 || videoStreamInfo.rotation === 3) {
        realWidth = videoStreamInfo.height
        realHeight = videoStreamInfo.width
      }
      const oRatio = realWidth / realHeight
      if (oRatio >= ratio) {
        screenWidth = screen.screenWidth
        screenHeight = (screenWidth / realWidth) * realHeight
      } else {
        screenHeight = screen.screenHeight
        screenWidth = (screenHeight / realHeight) * realWidth
      }
    } else {
      screenHeight = screen.screenHeight
      screenWidth = screen.screenWidth
    }
    screenWidth *= originScaleFactorX
    screenHeight *= originScaleFactorY

    let x = viewCenterPoint.x - screenWidth / 2
    let y = viewCenterPoint.y - screenHeight / 2
    let width = screenWidth
    let height = screenHeight

    if (!noRegion && cropMosicFx) {
      // Separately handle the calculation of cropping the mosaic region
      const res = calcCropMosaicFxPoint({
        cropMosicFx,
        timelineClass,
      })
      x = res.x
      y = res.y
      width = res.width
      height = res.height
    }

    const point = {
      x,
      y,
      width,
      height,
      oX: x + width / 2,
      oY: y + height / 2,
      transX,
      transY,
      originScaleFactorX,
      originScaleFactorY,
      originRotation,
    }
    return point
  }
  clientWidth() {
    return this.stage.content.clientWidth
  }
  clientHeight() {
    return this.stage.content.clientHeight
  }
  /**
   * determine whether we should flip the position of buttons from bottom to top
   * between +- FLIP_MARGIN_IN_DEGREE of 180 degree
   * CT-422
   * @param the rotation of control box, in (or converted to) degree
   * @returns boolean
   */
  shouldFlipControlButtons(rotationInDegree) {
    const FLIP_MARGIN_IN_DEGREE = 15
    return 180 - FLIP_MARGIN_IN_DEGREE < rotationInDegree && rotationInDegree < 180 + FLIP_MARGIN_IN_DEGREE
  }
  calculateNodePosition(index, totalNodes, curX, curY, curW, curH, rotationInDegree) {
    const NODE_WIDTH = 46,
      HALF_NODE_WIDTH = 23
    const toFlip = this.shouldFlipControlButtons(rotationInDegree)
    const x = curX + curW / 2
    const y = toFlip ? curY - NODE_WIDTH : curY + curH
    const distanceToRight = this.clientWidth() - (curX + curW / 2)
    let offsetX = totalNodes === 2 ? NODE_WIDTH - NODE_WIDTH * index : HALF_NODE_WIDTH
    let offsetY = -5

    if (totalNodes === 1) {
      // only one node
      if (x - offsetX < 0) {
        offsetX = x
      } else if (x - offsetX + NODE_WIDTH > this.clientWidth()) {
        offsetX = offsetX + HALF_NODE_WIDTH - distanceToRight
      } else {
        offsetX = HALF_NODE_WIDTH
      }
    } else if (totalNodes === 2) {
      // has two nodes
      if (x - offsetX < NODE_WIDTH * index) {
        offsetX = x - NODE_WIDTH * index
      } else if (x - offsetX + (2 - index) * NODE_WIDTH > this.clientWidth()) {
        offsetX = (2 - index) * NODE_WIDTH - distanceToRight
      } else {
        offsetX = (1 - index) * NODE_WIDTH
      }
    }
    if (toFlip) {
      if (y < 0) {
        offsetY = y
      } // sticky to top edge of preview
    } else {
      const distanceToBottom = this.clientHeight() - (curY + curH)
      if (distanceToBottom < 50) {
        offsetY = 45 - distanceToBottom
      } // sticky to bottom edge of preview
    }
    return { x, y, offsetX, offsetY }
  }
  validateDegree(rawDegree) {
    let degree = rawDegree % 360
    if (degree < 0) {
      degree += 360
    }
    return degree
  }
  /**
   * Update the visibility and positions of the Delete & Menu nodes
   * CT-1404
   * @param {object} newBox - Konva.Transformer box object (of the latest position of repositioned/resized Konva Node)
   * @returns undefined
   */
  setMenuNodePosition(newBox) {
    // CT-1406 Show Delete button when 1 or more items are selected, positioned relative to TransformerRect
    // For rotated items, only worry about rotated button placement when there is only one selected item...
    let totalButtons = 1
    if (this.clips.length === 0) {
      this.deleteNode && this.deleteNode.hide()
    } else {
      this.deleteNode?.show()
    }
    // Menu button only shows if only one item is selected and that item is of type Media
    if (this.clips.length === 1 && this.clips[0].type === CLIP_TYPES.VIDEO) {
      this.menuNode.show()
      totalButtons = 2
    } else {
      this.menuNode && this.menuNode.hide()
    }

    // CT-1406 - For now only worry about rotated button placement when there only one selected clip
    const coordinateOfOnlyClip =
      this.clips.length === 1 ? WorkFlow.getCoordinateFromPoint(this.clips[0], this.timelineClass) : null
    const curX = coordinateOfOnlyClip ? coordinateOfOnlyClip.x : this.rectTransform.x()
    const curY = coordinateOfOnlyClip ? coordinateOfOnlyClip.y : this.rectTransform.y()
    const curW = coordinateOfOnlyClip ? coordinateOfOnlyClip.width : this.rectTransform.width()
    const curH = coordinateOfOnlyClip ? coordinateOfOnlyClip.height : this.rectTransform.height()
    const controlBoxRotationInDegree = this.validateDegree(
      newBox ? (newBox.rotation / Math.PI) * 180 : this.rectTransform.rotation(),
    )

    // Update Delete node position
    _assignNodePosition.call(this, this.deleteNode, 0, controlBoxRotationInDegree)
    this.deleteNode && this.deleteNode.moveToTop()

    // Updated Menu node position
    _assignNodePosition.call(this, this.menuNode, 1, controlBoxRotationInDegree)
    this.menuNode && this.menuNode.moveToTop()

    function _assignNodePosition(node, index, rotationInDegree) {
      if (!node) {
        return
      }
      const { x, y, offsetX, offsetY } = this.calculateNodePosition(
        index,
        totalButtons,
        curX,
        curY,
        curW,
        curH,
        rotationInDegree,
      )
      node.x(x)
      node.y(y)
      node.offsetX(offsetX)
      node.offsetY(offsetY)
    }
  }
}

/**
 * Calculate the size of the region on the canvas layer based on the full material size + region, with side effects
 */
export function calcCropMosaicFxPoint({ cropMosicFx, timelineClass }) {
  // Separately handle the region calculation of clipping mosaic
  const { value } = _lookupParam(cropMosicFx, CROP_KEYS.REGION)
  const { width: videoWidth, height: videoHeight } = timelineClass
  const point = WorkFlow.bToa(
    new NvsPointF((value.x2 * videoWidth) / 2, (value.y2 * videoHeight) / 2),
    timelineClass.liveWindow,
  )

  // CT-2023 while 'stage' (Konva definition) is 100% height and width, the canvas of the project will forever be locked to 9:16 aspect ratio
  // The canvas size will be as large as possible while maintaining the 9:16 aspect ratio within the stage
  let { width: canvasWidth, height: canvasHeight } = getCanvasSize(timelineClass)
  if (canvasWidth > canvasHeight) {
    canvasWidth = canvasHeight * ASPECT_RATIO
  } else {
    canvasHeight = canvasWidth / ASPECT_RATIO
  }

  return {
    x: point.x,
    y: point.y,
    width: ((value.x3 - value.x2) / 2) * canvasWidth,
    height: ((value.y2 - value.y1) / 2) * canvasHeight,
  }
}

/**
 * Given a Video clip, lookup and return its FX property
 * @private
 * @param {Clip} clip - A Video Clip ()
 * @param {string} desc - The name of the specific FX object we want
 * @returns {FX}
 */
function _getFx(clip, desc) {
  return clip.splitList[0].videoFxs.find((fx) => fx.desc === desc && fx.from === 'user-added')
}

/**
 * Given an FX object (of a Video clip) return the pointer the object whose key property the paramName we want
 * @private
 * @param {FX} fx - An FX object
 * @param {string} paramName - Name of property we want to lookup
 * @returns {any}
 */
function _lookupParam(fx, paramName) {
  return fx.params.find((param) => param.key === paramName)
}

/**
 * Given an image url, create and return an img dom element
 * @private
 * @param {string} imageUrl - Image Url
 * @returns <img>
 */
function _getImage(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = imageUrl
    img.onload = () => {
      resolve(img)
    }
    img.onerror = reject
  })
}
