<template>
  <img
    v-if="!!src"
    :src="imgSrc"
    :class="`${fitClass}`"
    :loading="loadingStrategy"
    crossorigin="anonymous"
    @error="handleError"
  />
</template>

<script>
export default {
  name: 'SImage',
  props: {
    src: { type: [String, null], required: true },
    fallbackSrc: { type: [String], required: false, default: '' },
    lazy: { type: Boolean, default: false },
    fit: {
      type: String,
      required: false,
      default: 'none',
      validator: (value) => ['contain', 'cover', 'fill', 'none', 'scale-down'].includes(value),
    },
  },
  data: function () {
    return {
      imgSrc: this.src,
    }
  },
  computed: {
    fitClass() {
      return 'simage-fit-' + this.fit
    },
    loadingStrategy() {
      return this.lazy ? 'lazy' : 'eager'
    },
  },
  watch: {
    src() {
      this.imgSrc = this.src
    },
  },
  methods: {
    handleError() {
      this.imgSrc = this.fallbackSrc || null
    },
  },
}
</script>

<style scoped>
.simage-fit-contain {
  object-fit: contain;
}
.simage-fit-cover {
  object-fit: cover;
}
.simage-fit-fill {
  object-fit: fill;
}
.simage-fit-none {
  object-fit: none;
}
.simage-fit-scale-down {
  object-fit: scale-down;
}
</style>