export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "channelVsUserTextTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
        "channelVsUserTextFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from now, projects are now displayed based on the channel they were created under. This means that you will be able to see and edit projects that other team members created under this channel.\n\nSome projects may not have been assigned to any specific channel yet and will therefore appear regardless of which channel you are on. However, you can assign your previous projects to the channel you want them to live under."])}
      }
    }
  })
}
