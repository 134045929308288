import fxService from '@/services/fxService'

export default {
  namespaced: true,
  state: () => ({
    fxListsByType: {},
  }),
  mutations: {
    setFxList(state, payload) {
      const { effect_type, list } = payload
      state.fxListsByType[effect_type] = list
    },
  },
  actions: {
    loadFxsByType({ commit, state }, effect_type) {
      // Infrequently changed data - only fetch FXs once per session
      if (state.fxListsByType[effect_type]?.length) {
        return
      }

      fxService
        .getFxs({ effect_type })
        .then((response) => {
          commit('setFxList', { effect_type, list: response.studio_effects })
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
