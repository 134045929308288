/* 
  A Vue plugin to replicate Element $prompt UI functionality in Ant
  Essentially a small modal with a single input field
  - See CT-1594
  
  This plugin follows roughly the same API as Ant .$confirm except that the
  onOk function will now receive a value of the input as a parameter
  See: https://1x.antdv.com/components/modal/

  Usage: 

  (Vue Instance).$prompt({ 
    title: STRING,                  // The prompt title, default empty
    content: STRING,                // Text to display above the input field, default empty
    inputType: STRING,              // 'text' or 'textarea', default 'text'
    inputValue: STRING,             // Initial value of the input, default empty
    placeholder: STRING,            // Placeholder text in the input field, default empty
    icon: STRING,                   // Icon to display at left of input field, default none
    okText: STRING,                 // Ok button text, default 'Ok'
    cancelText: STRING,             // Cancel button text, default 'Cancel'
    onOk: FUNCTION({input value}),  // Ok button callback function, called with value of input field, default void
    onCancel: FUNCTION              // Cancel button callback function, default void
  })
*/
import { h } from 'vue'

import { Input as aInput, Modal } from 'ant-design-vue'

import { generateUUID } from '@/utils/common.js'

export default {
  install: (app) => {
    const id = `i${generateUUID()}`

    app.config.globalProperties.$prompt = function ({
      title = '',
      content = '',
      inputType: type = 'text',
      inputValue: value = '',
      placeholder = '',
      icon = () => h('span'),
      okText = this.$t('default.App.ok'),
      cancelText = this.$t('default.App.cancel'),
      onOk = () => {},
      onCancel = () => {},
    }) {
      let inputValue = ''

      const modal = Modal.confirm({
        title,
        icon,
        okText,
        cancelText,
        content: () =>
          h('span', [
            content,
            h(aInput, {
              style: {
                marginTop: '15px',
              },
              id,
              type,
              placeholder,
              defaultValue: value,
              'v-model:value': value,
              onChange(e) {
                inputValue = e.target.value
              },
              onPressEnter() {
                // UX - Enter key to do same thing as Ok button
                modal.destroy()
                onOk(inputValue)
              },
            }),
          ]),
        onOk: () => {
          onOk(inputValue)
        },
        onCancel,
      })

      // UX - Autofocus on input field in prompt after 200ms.
      // We must use a timeout to do this as .$nextTick occurs too early, ie before the
      // modal has been fully rendered to screen.  There is no callback in Ant Modal/Input
      // on loaded/rendered that could help us here.  Also, 'autofocus' HTML5 attribute also
      // won't work here as it collides with other elements on page and sometimes is blocked by browser.
      setTimeout(() => {
        document.querySelector(`#${id}`).focus()
      }, 200)
    }
  },
}
