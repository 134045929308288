export default {
  "App": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Successfully!"])},
    "saveFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Failed"])},
    "loadFontsFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Font List Failed..."])}
  }
}