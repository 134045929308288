import { EventBus as Bus } from '@/plugins/eventBus'

import Keys from '../utils/EventBusKeys'

const cloneDeep = require('clone-deep')

const MAX_HISTORY_STATES = 20

/**
 * A Vuex Plugin to implement Undo/Redo in the UI by simply snap-shotting and replacing state
 * (specifically a Meishe clip object)
 * Exposes methods globally to app (legacy structure):
 * - $undoRedoHistory.snapshotClipState() - Creates a state snapshot to history
 * - $undoRedoHistory.undo() - go back one state
 * - $undoRedoHistory.redo() - go forward one state
 * CT-1409
 *
 * @param {Vuex Store}
 * @returns undefined
 */
const undoRedoVuexPlugin = (store) => {
  const history = []
  let currentIndex = -1

  /**
   * Take a snapshot of current {clip} state and push it to history at the position
   * of the pointer currentIndex (if the pointer has moved back in time then throw away
   * all previous history points past the current pointer position and record new history points)
   * CT-1409
   *
   * @param none
   * @returns undefined
   */
  function snapshotClipState() {
    const stateSnapshot = JSON.parse(JSON.stringify(store.state.clip))
    history.splice(++currentIndex, +Infinity, stateSnapshot)

    // CT-1505 Keep only the last n history states
    if (history.length > MAX_HISTORY_STATES) {
      history.shift()
      currentIndex--
    }
  }

  /**
   * Given a vector (+1 for forward in time, -1 for backward in time), look into state
   * snapshot history and set it as current.
   * CT-1409
   *
   * @param {Number} vector
   * @returns undefined
   */
  function _gotoState(vector) {
    const nextState = history[currentIndex + vector]

    // Sanity check - only goto a state in history if that state exists
    if (!nextState || currentIndex < 0) {
      return
    }

    // Take a copy of the history state to apply it because it could be changed
    // if the user starts mutating the Clip from this point onwards...
    store.replaceState(
      cloneDeep({
        ...store.state,
        clip: nextState,
      }),
    )
    currentIndex += vector
    Bus.$emit(Keys.rebuildTimeline)
  }

  // Expose Undo/Redo functionality to rest of app
  store.$undoRedoHistory = {
    undo: () => _gotoState(-1),
    redo: () => _gotoState(+1),
    snapshotClipState,
  }

  // Automatically create state snapshots for specific Vuex mutations
  // (mutations that specifically make changes to the {Meishe} clip data)
  const include = ['clip/init', 'clip/addClipToVuex', 'clip/addUserAddedClipsToVuex', 'clip/updateClipToVuex']
  // This is called AFTER every mutation
  // https://vuex.vuejs.org/api/#subscribe
  store.subscribe((mutation) => {
    if (include.includes(mutation.type)) {
      snapshotClipState()
    }
  })

  // There are some Konva data updates that do not trigger/call Vuex mutations, which mean deep state changes are not snapshotted...
  // For now, we'll listen to a 'change_clipAsset' events to imperatively tell us to create a snapshot
  // - Workflow.Transformer:dragend
  // - Workflow.Transformer:transformend
  // - Preview:deleteClips
  Bus.$on(Keys.change_clipAsset, snapshotClipState)

  return {
    undo: () => _gotoState(-1),
    redo: () => _gotoState(+1),
    snapshotClipState,
  }
}

export default undoRedoVuexPlugin
