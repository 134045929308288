export const CTA_TYPE = {
  BUY: 'buy',
  DOWNLOAD: 'download',
  VIEW: 'view',
  BOOK_NOW: 'book',
  LIVE: 'live',
  DONATE: 'donate',
  APPLY_NOW: 'apply',
  CONTACT_US: 'contact',
  SIGN_UP: 'sign_up',
  ORDER_NOW: 'order',
  GET_OFFER: 'get_offer',
  GET_QUOTE: 'get_quote',
  VIEW_MENU: 'view_menu',
  GET_SHOWTIMES: 'get_showtimes',
  READ_MORE: 'read_more',
}

export const getActionLink = ({ actionType, actionUrl }) => {
  let action_type = actionType
  let action_url = actionUrl
  if (action_url) {
    action_type = actionType
    action_url = action_url.startsWith('http') ? action_url : `http://${action_url}`
  } else {
    action_type = null
    action_url = null
  }
  return { action_type, action_url }
}
