import { Socket } from 'phoenix'

import apiHost from '@/api/apiHost'

const host = new URL(apiHost.fw).host
const websocketPath = '/studio_socket'
const websocketScheme = host.match(/localhost/) ? 'ws' : 'wss'
const websocketUrl = `${websocketScheme}://${host}${websocketPath}`

// Call this function early in the app lifecycle.
// It establishes a long-running Phoenix Channels connection to the server, which is an abstraction over WebSockets.
// This connection automatically re-establishes if the server goes down or the user loses their internet connection.
export const connectSocket = (token) => {
  const socket = new Socket(websocketUrl, { params: { token } })
  socket.connect()
  return socket
}
