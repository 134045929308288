/*
  Avatar Service

  This service is the standard (CRUD) interface to BE for all transactions for Avatars, aka digital humans, aka talking heads
*/

import apiHost from '@/api/apiHost'
import { _axios } from '@/plugins/axios'

/**
 * Load voiceover models
 * CT-2079
 *
 * @param {Object} options - Options object
 * @returns {Promise}
 */
export function getAvatars(options = {}) {
  const basePath = `${apiHost.fw}/studio/avatars`
  const { page = 1, page_size = 1000 } = options

  return _axios.get(basePath, {
    params: { page, page_size },
  })
}

const avatarService = { getAvatars }
export default avatarService
