export default {
  "en": {
    "App": {
      "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scenes"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing ..."])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser not supported"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use Google Chrome or Firefox on desktop to access the Firework Studio."])},
      "projectChannelUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video project channel has been updated"])}
    },
    "components": {
      "branding": {
        "BrandingPreview": {
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])}
        }
      },
      "ChannelVsUserDialog": {
        "channelVsUserTextTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
        "channelVsUserText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from now, projects are now displayed based on the channel they were created under. This means that you will be able to see and edit projects that other team members created under this channel.\n\nSome projects may not have been assigned to any specific channel yet and will therefore appear regardless of which channel you are on."])},
        "channelVsUserTextFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from now, projects are now displayed based on the channel they were created under. This means that you will be able to see and edit projects that other team members created under this channel.\n\nSome projects may not have been assigned to any specific channel yet and will therefore appear regardless of which channel you are on. However, you can assign your previous projects to the channel you want them to live under."])}
      },
      "create": {
        "BusinessChannelSelector": {
          "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a business and channel"])},
          "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to create a business account and channel before using Firework Studio"])},
          "businessChannelModalTitleForProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate project to a Channel"])},
          "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
          "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
          "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
          "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Firework Business Portal"])}
        },
        "CreateSideNav": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from scratch"])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW Code"])},
          "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])}
        },
        "MediaItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "Medias": {
          "deleteMediaBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the media asset. Continue?"])},
          "deleteMediaTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete media"])},
          "deleteCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete completed"])},
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your uploads will appear here"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search millions of photos and videos"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
          "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Failed"])},
          "loadMediaFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Medias Failed"])},
          "dragAndDropHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and Drop here to upload"])},
          "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload"])}
        },
        "PreviewModal": {
          "noBatchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not find batch id."])},
          "videoCompileFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compile Failed"])},
          "videoOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Outline"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "sceneOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scene ", _interpolate(_named("sceneOrder"))])},
          "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CSV"])},
          "text2Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text to Video"])},
          "batchEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch editing"])},
          "batchProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch processing"])},
          "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
          "batchEditingButtonHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag or drop CSV file here"])},
          "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose File"])},
          "totalVideoNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total: ", _interpolate(_named("videoNumber")), " / ", _interpolate(_named("videoMaxNumber")), " videos"])},
          "sampleCsvFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample CSV file"])},
          "totalFinishedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("finishedVideo")), " / ", _interpolate(_named("videoNumber")), " finished"])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
          "confirmCancelBatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel the batch editing job. Continue?"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "cancelProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel process"])},
          "cancelBatchSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel batch process success"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
          "failedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("failedVideoCount")), " failed."])}
        },
        "Project": {
          "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW code"])},
          "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a Firework Code to start your creation"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the FW Code"])},
          "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error: ", _interpolate(_named("error"))])},
          "appliedFwCode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applied FW code: ", _interpolate(_named("fwCode"))])},
          "secretModeUnlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret mode unlocked."])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start from scratch"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop anywhere to upload"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or choose files"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Project"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "fwCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW Code"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
        },
        "ProjectItem": {
          "promptFwCodePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the fw-code"])},
          "promptFwCodeBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the fw-code you would like to share with others"])},
          "deleteProjectBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the video project. Continue?"])},
          "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error: ", _interpolate(_named("error"))])},
          "yourFWCodeIsReady": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your fw-code: ", _interpolate(_named("fwCode")), " is ready to be shared."])},
          "domUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input canceled"])},
          "domUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOM updated successfully."])},
          "projectDeleteCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete canceled"])},
          "projectDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete completed"])},
          "projectTitleUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video project title has been updated."])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW code"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit DOM"])},
          "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Template"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
          "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
          "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
          "batchEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch Editing"])}
        },
        "SearchResultItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
        },
        "Template": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
          "createBlankProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Blank Project"])},
          "templateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Templates"])},
          "templateListByName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("templateListName")), " Templates"])}
        },
        "TemplateItem": {
          "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error: ", _interpolate(_named("error"))])},
          "yourFWCodeIsReady": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your fw-code: ", _interpolate(_named("fwCode")), " is ready to be shared."])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW code"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit DOM"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])}
        },
        "TemplateListItem": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
        },
        "Text2Video": {
          "text2Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text To Video"])},
          "ActionsBar": {
            "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
            "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
          },
          "Article2Paragraph": {
            "feelingLucky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feeling Lucky"])},
            "addScene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Scene"])}
          },
          "MediaSelector": {
            "keywordSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword Search"])},
            "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
            "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])}
          },
          "SceneBlock": {
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
            "scene": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scene ", _interpolate(_named("sceneNumber"))])},
            "estimateTime": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Est. time: ", _interpolate(_named("estimateSeconds")), "s"])}
          },
          "Url2Text": {
            "enterUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your article URL:"])},
            "orEnterText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or edit the text area below"])},
            "wordCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Word count (", _interpolate(_named("wordCount")), "/", _interpolate(_named("wordCountMax")), ")"])},
            "characterCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Character count (", _interpolate(_named("charCount")), "/", _interpolate(_named("charCountMax")), ")"])}
          },
          "VideoOrStudio": {
            "videoCompileFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compile Failed"])},
            "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
            "publishAsVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish as video"])},
            "studio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio"])},
            "editAsStudioProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit as Studio project"])},
            "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing ..."])},
            "viewInBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in Business Portal"])}
          }
        },
        "Uploader": {
          "fileTooLarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file you selected is greater than 256 mb, please try with a smaller file."])},
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload"])}
        }
      },
      "music": {
        "MusicList": {
          "playAudioError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Audio Error"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trim"])},
          "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use"])},
          "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "noMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No More Content"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
          "applyAudioFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Audio Failed"])}
        }
      },
      "Preview": {
        "swapMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Media"])},
        "loadModulesFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Failed"])},
        "loadProjectFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot load. Try again later."])},
        "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Media"])},
        "addFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Failed!"])},
        "loadBrandingAssetsFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load branding presets failed!"])}
      },
      "PublishModal": {
        "videoTooShort": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("durationOfVideo")), "-second video is too short. The minimum duration is ", _interpolate(_named("minVideoDuration")), " seconds."])},
        "videoTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("durationOfVideo")), "-second video is too long. The maximum duration is ", _interpolate(_named("maxVideoDuration")), " seconds."])},
        "publishVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Video"])},
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caption"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "hashtagHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate hashtags with commas (i.e. Travel, Trip, Nomad)"])},
        "poster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poster"])},
        "posterMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Poster"])},
        "ctaButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTA Button"])},
        "insertLinkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert link..."])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only you"])},
        "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only you and people on your account can see."])},
        "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
        "public_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyone on the internet can see."])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "agreeFireworkText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By posting, I agree to Firework ", _interpolate(_named("communityGuideline")), " & ", _interpolate(_named("terms"))])},
        "communityGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["community guidelines"])},
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])}
      },
      "scenes": {
        "Canvas": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Media"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Project"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "CropDialog": {
          "minVideoLengthWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your split Video cannot be shorter than ", _interpolate(_named("MIN_DURATION_SEC")), "s"])},
          "noThumbnails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No thumbnails!"])},
          "motionOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion On "])},
          "motionOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Off"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split"])}
        },
        "DraftList": {
          "addScene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Scene"])},
          "trimVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trim Video"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split"])},
          "totalVideoDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total video duration"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
          "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Successfully!"])}
        },
        "FieldInputSeconds": {
          "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])}
        },
        "FontPanel": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
          "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
          "animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
          "letterSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letter Spacing"])},
          "lineSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Spacing"])},
          "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Color"])},
          "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background Color"])}
        },
        "Materials": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
          "sticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphics"])},
          "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Media"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Project"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "MediaComponent": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "MediaList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])},
          "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload images and videos"])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])}
        }
      },
      "styles": {
        "FavoriteList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        },
        "StyleList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        }
      },
      "TitleBar": {
        "videoCompileInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compile In Progress..."])},
        "videoCompileFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compile Failed"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])}
      },
      "utils": {
        "MenuList": {
          "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trim"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
          "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
          "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Backward"])},
          "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send To Back"])},
          "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring Forward"])},
          "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring To Front"])},
          "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add On Top"])}
        }
      }
    },
    "views": {
      "BrandingLite": {
        "saveBrandingPresetsFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Branding Presets Failed!"])},
        "loadBrandingPresetsFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load branding presets failed!"])},
        "uploadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Successfully!"])},
        "brandColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Color"])},
        "brandFonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Fonts"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "primaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
        "secondaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary"])},
        "fontTypeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected font is not supported."])}
      },
      "Create": {
        "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error: ", _interpolate(_named("error"))])},
        "appliedFwCode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applied FW code: ", _interpolate(_named("fwCode"))])},
        "secretModeUnlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret mode unlocked."])},
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Media"])},
        "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW code"])},
        "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a Firework Code to start your creation"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the FW Code"])}
      },
      "Login": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "loginFromFwTv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login from fw.tv"])},
        "loginFromBizPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login from Business Portal"])}
      },
      "Music": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music Library"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "Scenes": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Media"])}
      },
      "Styles": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending Styles"])},
        "allTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Templates"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])}
      }
    }
  },
  "ja": {
    "App": {
      "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シーン"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタイル"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュージック"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランディング"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser not supported"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use Google Chrome or Firefox on desktop to access the Firework Studio."])}
    },
    "components": {
      "branding": {
        "BrandingPreview": {
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイズ"])}
        }
      },
      "create": {
        "BusinessChannelSelector": {
          "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスとチャンネルを選択"])},
          "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to create a business account and channel before using Firework Studio"])},
          "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネス"])},
          "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネル"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
          "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Firework Business Portal"])}
        },
        "CreateSideNav": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインを作成"])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコード"])},
          "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロジェクト"])},
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])}
        },
        "MediaItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])}
        },
        "Medias": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ライブラリ"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your uploads will appear here"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter keyword search"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
          "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Failed"])},
          "loadMediaFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Medias Failed"])},
          "dragAndDropHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドラッグ&ドロップしてアップロード"])},
          "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もしくは"])},
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリックしてアップロード"])}
        },
        "PreviewModal": {
          "videoOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインの詳細"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "sceneOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["シーン ", _interpolate(_named("sceneOrder"))])},
          "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CSV"])},
          "batchEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括編集"])},
          "batchProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["処理中"])},
          "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
          "batchEditingButtonHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVファイルをドラッグアンドドロップ"])},
          "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを選択"])},
          "totalVideoNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["計 ", _interpolate(_named("videoNumber")), " / ", _interpolate(_named("videoMaxNumber")), "  ビデオ"])},
          "sampleCsvFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サンプルCSVファイル"])},
          "totalFinishedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["完成 ", _interpolate(_named("finishedVideo")), " / ", _interpolate(_named("videoNumber"))])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
          "confirmCancelBatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel the batch editing job. Continue?"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "cancelProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel process"])},
          "cancelBatchSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel batch process success"])},
          "failedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("failedVideoCount")), " failed."])}
        },
        "Project": {
          "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコード"])},
          "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firework共同編集コードを入力して制作を開始する"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコードを入力"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規動画作成"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドラッグ&ドロップでファイルをアップロードする"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを選択する"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のプロジェクト"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "fwCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW共同編集コード"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "ProjectItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW共同編集コード"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオスタイル"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit DOM"])},
          "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Template"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "SearchResultItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])}
        },
        "Template": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
          "createBlankProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規プロジェクトを作成"])},
          "templateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてテンプレート"])},
          "templateListByName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("templateListName")), "テンプレート"])}
        },
        "TemplateItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW共同編集コード"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオスタイル"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit DOM"])}
        },
        "TemplateListItem": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])}
        },
        "Uploader": {
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload"])}
        }
      },
      "music": {
        "MusicList": {
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリミング"])},
          "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中"])},
          "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用する"])},
          "noMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No More Content"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
          "applyAudioFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Audio Failed"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])}
        }
      },
      "Preview": {
        "swapMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メディアを交換する"])},
        "loadModulesFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込みに失敗しました。再度お試しください。"])},
        "loadProjectFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込みに失敗しました。再度お試しください。"])},
        "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])}
      },
      "scenes": {
        "Canvas": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のプロジェクト"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])}
        },
        "CropDialog": {
          "motionOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動モーションオン"])},
          "motionOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動モーションオフ"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])}
        },
        "DraftList": {
          "addScene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
          "trimVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオをトリミングする"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])},
          "totalVideoDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計動画尺"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
          "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Successfully!"])}
        },
        "FieldInputSeconds": {
          "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])}
        },
        "FontPanel": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動画尺"])},
          "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォント"])},
          "animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アニメーション"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字サイズ"])},
          "letterSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字間隔"])},
          "lineSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行間隔"])},
          "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストカラー"])},
          "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バックグラウンドカラー"])}
        },
        "Materials": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキスト"])},
          "sticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グラフィック"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のプロジェクト"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
          "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メディア"])}
        },
        "MediaComponent": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "MediaList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])},
          "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像と動画をアップロードする"])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中"])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])}
        }
      },
      "styles": {
        "FavoriteList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        },
        "StyleList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        }
      },
      "TitleBar": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューを表示"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制作完了"])}
      },
      "utils": {
        "MenuList": {
          "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["置換"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切り抜き"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
          "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貼り付け"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
          "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背面へ"])},
          "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最背面へ"])},
          "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前面へ"])},
          "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最前面"])},
          "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最前面に追加"])}
        }
      }
    },
    "views": {
      "BrandingLite": {
        "brandColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドカラー"])},
        "brandFonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドフォント"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロゴ"])},
        "primaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メイン"])},
        "secondaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブ"])}
      },
      "Create": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
        "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコード"])},
        "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firework共同編集コードを入力して制作を開始する"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコードを入力"])}
      },
      "Login": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
        "loginFromFwTv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login from fw.tv"])},
        "loginFromBizPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスポータルからログイン"])}
      },
      "Music": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュージックライブラリ"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択解除"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュージックを検索する"])}
      },
      "Scenes": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Media"])}
      },
      "Styles": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気のスタイル"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択解除"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りスタイル"])},
        "allTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてテンプレート"])}
      }
    }
  },
  "zh-TW": {
    "App": {
      "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分鏡"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音樂"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈中..."])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未支援的瀏覽器"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在電腦上使用 Google Chrome 或 Firework 來使用 Firework Studio"])}
    },
    "components": {
      "branding": {
        "BrandingPreview": {
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比例"])}
        }
      },
      "create": {
        "BusinessChannelSelector": {
          "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇您的商家和頻道"])},
          "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在使用FW Studio之前，您需要先建立商家和頻道"])},
          "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家"])},
          "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頻道"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進入"])},
          "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往Firework商家控制台"])}
        },
        "CreateSideNav": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳媒體"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往頭開始編輯"])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專案"])},
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])}
        },
        "MediaItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["處理中..."])}
        },
        "Medias": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your uploads will appear here"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter keyword search"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖片"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片"])},
          "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Failed"])},
          "loadMediaFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Medias Failed"])},
          "dragAndDropHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖放檔案至此處上傳"])},
          "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或是"])},
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點擊上傳"])}
        },
        "PreviewModal": {
          "videoOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分鏡概覽"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
          "sceneOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["分鏡 ", _interpolate(_named("sceneOrder"))])},
          "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳CSV檔"])},
          "batchEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次編輯"])},
          "batchProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次處理"])},
          "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
          "batchEditingButtonHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將CSV檔案拖曳到這裡"])},
          "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取檔案"])},
          "totalVideoNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["共", _interpolate(_named("videoNumber")), "/", _interpolate(_named("videoMaxNumber")), "影片"])},
          "sampleCsvFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下載CSV樣本"])},
          "totalFinishedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已完成 ", _interpolate(_named("finishedVideo")), " / ", _interpolate(_named("videoNumber")), " 影片"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步"])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
          "confirmCancelBatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel the batch editing job. Continue?"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "cancelProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel process"])},
          "cancelBatchSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel batch process success"])},
          "failedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("failedVideoCount")), " failed."])}
        },
        "Project": {
          "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入Firework共同編輯代碼來開始製作影片"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入FW代碼"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往頭開始編輯"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖放檔案在這裡開始上傳"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或選取檔案"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "fwCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標題"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "ProjectItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯DOM"])},
          "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯範本"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])}
        },
        "Template": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
          "createBlankProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建空白專案"])},
          "templateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部範本"])},
          "templateListByName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("templateListName")), "範本"])}
        },
        "TemplateItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯DOM"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])}
        },
        "TemplateListItem": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])}
        },
        "Uploader": {
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload"])}
        }
      },
      "music": {
        "MusicList": {
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪"])},
          "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中"])},
          "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "noMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No More Content"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "applyAudioFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Audio Failed"])}
        }
      },
      "Preview": {
        "swapMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替換媒體"])},
        "loadModulesFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入失敗"])},
        "loadProjectFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法載入，請稍候再試"])},
        "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])}
      },
      "scenes": {
        "Canvas": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "CropDialog": {
          "motionOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫：開啟"])},
          "motionOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫：關閉"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])}
        },
        "DraftList": {
          "addScene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加分鏡"])},
          "trimVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪影片"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])},
          "totalVideoDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片全長"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存中"])},
          "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存成功！"])}
        },
        "FontPanel": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時長"])},
          "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字型"])},
          "animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比例"])},
          "letterSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字距"])},
          "lineSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行距"])},
          "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字顏色"])},
          "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背景顏色"])}
        },
        "Materials": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字"])},
          "sticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預設圖形"])},
          "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒體庫"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "MediaComponent": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "MediaList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])},
          "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳圖片與影片"])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])}
        }
      },
      "styles": {
        "FavoriteList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        },
        "StyleList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        }
      },
      "utils": {
        "MenuList": {
          "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替換"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貼上"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往下一層"])},
          "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最下層"])},
          "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往上一層"])},
          "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最上層"])},
          "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加在上面"])}
        }
      }
    },
    "views": {
      "BrandingLite": {
        "brandColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌顏色"])},
        "brandFonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌字型"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "primaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要字型"])},
        "secondaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輔助字型"])}
      },
      "Create": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
        "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
        "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入Firework共同編輯代碼來開始製作影片"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入FW代碼"])}
      },
      "Login": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
        "loginFromFwTv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login from fw.tv"])},
        "loginFromBizPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從Firework商務控制台登入"])}
      },
      "Music": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音樂庫"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])}
      },
      "Scenes": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Media"])}
      },
      "Styles": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending Styles"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
        "allTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部範本"])}
      }
    }
  },
  "zh-HK": {
    "App": {
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])},
      "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分鏡"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音樂"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈中..."])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未支援的瀏覽器"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在電腦上使用 Google Chrome 或 Firework 來使用 Firework Studio"])}
    },
    "components": {
      "branding": {
        "BrandingPreview": {
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比例"])}
        }
      },
      "create": {
        "BusinessChannelSelector": {
          "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇您的商家和頻道"])},
          "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在使用FW Studio之前，您需要先建立商家和頻道"])},
          "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家"])},
          "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頻道"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進入"])},
          "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往Firework商家控制台"])}
        },
        "CreateSideNav": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳媒體"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往頭開始編輯"])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專案"])},
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])}
        },
        "MediaItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["處理中..."])}
        },
        "Medias": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your uploads will appear here"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter keyword search"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖片"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片"])},
          "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Failed"])},
          "loadMediaFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Medias Failed"])},
          "dragAndDropHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖放檔案至此處上傳"])},
          "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或是"])},
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點擊上傳"])}
        },
        "PreviewModal": {
          "confirmCancelBatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel the batch editing job. Continue?"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "cancelProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel process"])},
          "cancelBatchSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel batch process success"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步"])},
          "failedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("failedVideoCount")), " failed."])},
          "videoOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分鏡概覽"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
          "sceneOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["分鏡 ", _interpolate(_named("sceneOrder"))])},
          "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳CSV檔"])},
          "batchEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次編輯"])},
          "batchProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次處理"])},
          "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
          "batchEditingButtonHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將CSV檔案拖曳到這裡"])},
          "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取檔案"])},
          "totalVideoNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["共", _interpolate(_named("videoNumber")), " / ", _interpolate(_named("videoMaxNumber")), "影片"])},
          "sampleCsvFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下載CSV樣本"])},
          "totalFinishedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已完成 ", _interpolate(_named("finishedVideo")), " / ", _interpolate(_named("videoNumber")), " 影片"])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])}
        },
        "Project": {
          "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入Firework共同編輯代碼來開始製作影片"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入FW代碼"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往頭開始編輯"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖放檔案在這裡開始上傳"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或選取檔案"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "fwCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標題"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "ProjectItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯DOM"])},
          "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯範本"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])}
        },
        "Template": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
          "createBlankProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建空白專案"])},
          "templateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部範本"])},
          "templateListByName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("templateListName")), "範本"])}
        },
        "TemplateItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯DOM"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])}
        },
        "TemplateListItem": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])}
        },
        "Uploader": {
          "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload"])}
        }
      },
      "music": {
        "MusicList": {
          "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪"])},
          "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "noMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No More Content"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "applyAudioFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Audio Failed"])}
        }
      },
      "Preview": {
        "swapMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替換媒體"])},
        "loadModulesFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入失敗"])},
        "loadProjectFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法載入，請稍候再試"])},
        "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])}
      },
      "scenes": {
        "Canvas": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "CropDialog": {
          "motionOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫：開啟"])},
          "motionOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫：關閉"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])}
        },
        "DraftList": {
          "addScene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加分鏡"])},
          "trimVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪影片"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])},
          "totalVideoDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片全長"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存中"])},
          "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存成功！"])}
        },
        "FontPanel": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時長"])},
          "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字型"])},
          "animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比例"])},
          "letterSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字距"])},
          "lineSpacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行距"])},
          "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字顏色"])},
          "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背景顏色"])}
        },
        "Materials": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字"])},
          "sticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預設圖形"])},
          "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒體庫"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "MediaComponent": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "MediaList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])},
          "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳圖片與影片"])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])}
        }
      },
      "styles": {
        "FavoriteList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        },
        "StyleList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        }
      },
      "utils": {
        "MenuList": {
          "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替換"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貼上"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往下一層"])},
          "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最下層"])},
          "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往上一層"])},
          "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最上層"])},
          "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加在上面"])}
        }
      }
    },
    "views": {
      "BrandingLite": {
        "brandColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌顏色"])},
        "brandFonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌字型"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "primaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要字型"])},
        "secondaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輔助字型"])}
      },
      "Create": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
        "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
        "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入Firework共同編輯代碼來開始製作影片"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入FW代碼"])}
      },
      "Login": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
        "loginFromFwTv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login from fw.tv"])},
        "loginFromBizPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從Firework商務控制台登入"])}
      },
      "Music": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音樂庫"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])}
      },
      "Scenes": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Media"])}
      },
      "Styles": {
        "allTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部範本"])},
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending Styles"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])}
      }
    }
  },
  "zh": {
    "components": {
      "scenes": {
        "FieldInputSeconds": {
          "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])}
        }
      },
      "TitleBar": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈"])}
      }
    }
  }
}