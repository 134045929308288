<!--
System Alerts global display
CT-2144

This component displays system alerts from a Google Sheet. The Google Sheet is fetched on page load and the alerts 
are displayed in the header of the app. Alerts can be dismissed by clicking the "x" button on the alert. Dismissed 
alerts are stored in local storage and will not be displayed again.

The Google Sheet is fetched from the following URL:
https://docs.google.com/spreadsheets/d/1q_4mad61SF_jPgfh5fbx9TT-ypypOABufbLugeK9kbc/edit#gid=0

-->

<template>
  <a-alert
    v-for="alert in systemAlerts"
    :key="alert.hash"
    :type="alert.type"
    :message="alert.title"
    :description="alert.message"
    show-icon
    closable
    @close="() => handleAlertDismissal(alert.hash)"
  />
</template>
<script>
import { getLocale } from '@/utils/Environment'

export default {
  name: 'SystemAlerts',
  data() {
    // Get list of dismissed alerts from local storage (array of message hashes)
    const dismissedAlerts = JSON.parse(localStorage.getItem('dismissedSystemAlerts') || '[]')

    const locale = getLocale()?.substring(0, 2) || 'en'
    const englishTitleColumnIdx = 3
    const englishMessageColumnIdx = 4
    let defaultTitleColumnIdx = englishTitleColumnIdx
    let defaultMessageColumnIdx = englishMessageColumnIdx
    if (locale === 'ja') {
      defaultTitleColumnIdx = 5
      defaultMessageColumnIdx = 6
    }

    return {
      englishTitleColumnIdx,
      englishMessageColumnIdx,
      defaultTitleColumnIdx,
      defaultMessageColumnIdx,
      rawData: undefined,

      dismissedAlerts,
    }
  },
  computed: {
    // Using a specific Google Sheet a SST for system alert data
    // https://stackoverflow.com/questions/68796548/404-while-trying-to-fetch-sheet-data-as-json/68845761#68845761
    googleSheetSource() {
      return `https://docs.google.com/spreadsheets/d/1q_4mad61SF_jPgfh5fbx9TT-ypypOABufbLugeK9kbc/gviz/tq?tqx=out:json&tq&gid=${
        this.$isProd ? '0' : '929590841' // Sheet ID, 0 is prod, 929590841 is staging
      }`
    },
    systemAlerts() {
      const alertData = this.rawData?.rows || []
      const alerts = []
      alertData.forEach((row) => {
        // Validate data - must have a title or message
        // Hardcode column numbers for title and message based on locale for now, since we only care about English and Japanese
        const title = row.c[this.defaultTitleColumnIdx]?.v || row.c[this.englishTitleColumnIdx]?.v
        const message = row.c[this.defaultMessageColumnIdx]?.v || row.c[this.englishMessageColumnIdx]?.v
        if (!title && !message) {
          return
        }

        // Validate data - must have a start and end date
        const now = new Date()
        let startDate, endDate
        try {
          startDate = new Date(row.c[1].f)
          endDate = new Date(row.c[2].f)
        } catch (e) {
          return
        }

        // Validate data - Only display alerts if today is on or after the start date and on or before the end date
        if (now < startDate || now > endDate) {
          return
        }

        // Validate data - Only display alert if the alert has not been dismissed previously
        const hash = _stringToHash(title + message)
        if (this.dismissedAlerts.includes(hash)) {
          return
        }

        // Validate data - default to info type if type is not valid
        const type = ['success', 'info', 'warning', 'error'].includes(row.c[0].v) ? row.c[0].v : 'info'

        // Hash the title and message to create a unique ID
        // Used to track dismissed alerts
        alerts.push({
          type,
          startDate,
          endDate,
          title,
          message,
          hash,
        })
      })

      return alerts
    },
  },
  created() {
    fetch(this.googleSheetSource)
      .then((r) => r.text())
      .then((r) => {
        const jsonString = r.match(/(?<="table":).*(?=}\);)/g)[0]
        return JSON.parse(jsonString)
      })
      .then((r) => {
        this.rawData = r
      })
      .catch((e) => {
        console.error(e)
      })
  },
  methods: {
    handleAlertDismissal(h) {
      this.dismissedAlerts.push(h)

      // Update local storage immediately when user dismisses an alert
      localStorage.setItem('dismissedSystemAlerts', JSON.stringify(this.dismissedAlerts))
    },
  },
}
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
function _stringToHash(str) {
  let hash = 0,
    i,
    chr
  if (str.length === 0) {
    return hash
  }
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}
</script>

<style scoped lang="scss">
</style>