/*
  Text-to-Speech Service

  This service is the standard (CRUD) interface to BE for all transactions for TTS
*/

import apiHost from '@/api/apiHost'
import { _axios } from '@/plugins/axios'

/**
 * Load voiceover models
 * CT-2212
 *
 * @param {Object} options - Options object
 * @returns {Promise}
 */
export function getVoiceoverModels(options = {}) {
  const basePath = `${apiHost.fw}/studio/voiceovers`
  const { page_size = 100 } = options

  return _axios.get(basePath, {
    params: { page_size },
  })
}

/**
 * Invoke text-to-speech generation
 * CT-2212
 *
 * @param {Object} options - Options object. Requires text and voiceover_id
 * @returns {Promise}
 */
export function generateTTS(options = {}) {
  const basePath = `${apiHost.fw}/studio/external_assets/import/tts`
  const { text = undefined, voiceover_id = undefined, playback_speed_multiplier = 1 } = options

  if (!text || !voiceover_id) {
    return Promise.reject(`Missing required parameters: text=${text}, voiceover_id=${voiceover_id}`)
  }

  return _axios.post(basePath, {
    text,
    voiceover_id,
    playback_speed_multiplier,
  })
}

const ttsService = { getVoiceoverModels, generateTTS }
export default ttsService
