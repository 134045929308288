import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)

      // FIXME - the messages obj seems to be incorrectly generated, and contains invalid locale keys.
      // Either the locales should come from the data only or they should come from the filenames only and root keys removed...
      // see https://kazupon.github.io/vue-i18n/started.html#html
      //
      // Can all <i18n> local code be cleaned up,
      // such that the files in the /locales folder are the single-source-of-truth for all localization?
      //
      // The following line uses keys from the data, not filenames.
      // messages = { ...messages, ...locales(key) }
    }
  })
  return messages
}

const i18n = createI18n({
  locale: navigator.language || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
})

export default i18n
