export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスとチャンネルを選択"])},
      "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to create a business account and channel before using Firework Studio"])},
      "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネス"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネル"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
      "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Firework Business Portal"])}
    }
  })
}
