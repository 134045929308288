import ttsService from '@/services/ttsService'

export default {
  namespaced: true,
  state: () => ({
    voiceoverModels: [],
  }),
  mutations: {
    setVoiceoverModels(state, payload) {
      state.voiceoverModels = payload
    },
  },
  actions: {
    loadVoiceoverModels({ commit, state }) {
      // Infrequently changed data - only fetch voiceover models once per session
      if (state.voiceoverModels.length) {
        return
      }

      ttsService
        .getVoiceoverModels()
        .then((response) => {
          commit('setVoiceoverModels', response.voiceovers)
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
