export default {
  "ja": {
    "App": {
      "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シーン"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタイル"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュージック"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランディング"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスに対するフィードバックをお送りください。"])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser not supported"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use Google Chrome to access the Firework Studio."])}
    },
    "components": {
      "branding": {
        "BrandingPreview": {
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイズ"])}
        }
      },
      "create": {
        "BusinessChannelSelector": {
          "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスとチャンネルを選択"])},
          "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to create a business account and channel before using Firework Studio"])},
          "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネス"])},
          "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネル"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
          "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Firework Business Portal"])}
        },
        "CreateSideNav": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインを作成"])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコード"])},
          "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制作済コンテンツ"])},
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])}
        },
        "MediaItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])}
        },
        "Medias": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ライブラリ"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your uploads will appear here"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオ"])},
          "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Failed"])},
          "loadMediaFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Medias Failed"])}
        },
        "PreviewModal": {
          "videoOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインの詳細"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "sceneOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["シーン ", _interpolate(_named("sceneOrder"))])},
          "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CSV"])},
          "batchEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括編集"])},
          "batchProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["処理中"])},
          "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
          "batchEditingButtonHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVファイルをドラッグアンドドロップ"])},
          "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを選択"])},
          "totalVideoNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["計 ", _interpolate(_named("videoNumber")), " ビデオ"])},
          "sampleCsvFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サンプルCSVファイル"])},
          "totalFinishedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["完成 ", _interpolate(_named("finishedVideo")), " / ", _interpolate(_named("videoNumber"))])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
          "confirmCancelBatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel the batch editing job. Continue?"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "cancelProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel process"])},
          "cancelBatchSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel batch process success"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
          "failedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("failedVideoCount")), " failed."])}
        },
        "Project": {
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規動画作成"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドラッグ&ドロップでファイルをアップロードする"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを選択する"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のコンテンツ"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "fwCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW共同編集コード"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "ProjectItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW共同編集コード"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオスタイル"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit DOM"])},
          "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Template"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])}
        },
        "Template": {
          "templateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全てのテンプレート"])}
        },
        "TemplateItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW共同編集コード"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオスタイル"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit DOM"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])}
        },
        "UploadItem": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルをアップロードする"])}
        }
      },
      "music": {
        "MusicList": {
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリミング"])},
          "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用する"])},
          "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
          "noMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No More Content"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
          "applyAudioFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Audio Failed"])}
        }
      },
      "Preview": {
        "swapMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メディアを交換する"])},
        "loadModulesFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込みに失敗しました。再度お試しください。"])},
        "loadProjectFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込みに失敗しました。再度お試しください。"])},
        "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])}
      },
      "scenes": {
        "Canvas": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のコンテンツ"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])}
        },
        "CropDialog": {
          "motionOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動モーションオン"])},
          "motionOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動モーションオフ"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])}
        },
        "DraftList": {
          "addScene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
          "trimVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオをトリミングする"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])},
          "totalVideoDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計動画尺"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
          "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Successfully!"])}
        },
        "FontPanel": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動画尺"])},
          "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォント"])},
          "animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アニメーション"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字サイズ"])},
          "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストカラー"])},
          "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バックグラウンドカラー"])}
        },
        "Materials": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキスト"])},
          "sticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グラフィック"])},
          "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メディア"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のコンテンツ"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])}
        },
        "MediaComponent": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "MediaList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])},
          "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像と動画をアップロードする"])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中"])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])}
        }
      },
      "styles": {
        "FavoriteList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        },
        "StyleList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        }
      },
      "template": {
        "Templates": {
          "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気のスタイル"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択解除"])}
        }
      },
      "TitleBar": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューを表示"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制作完了"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
        "sceneEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シーンを編集"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャプション"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグ"])},
        "posters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サムネイル設定"])},
        "postTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開設定"])},
        "ctaButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTAボタン"])},
        "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非公開"])},
        "hashtagHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(#マークは不要。ハッシュタグひとつ入力する度にEnter押下してください。）"])},
        "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
        "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compose Finish!"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compose Be In Progress..."])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Compose Failed"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
        "posterMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいサムネイルを追加してください"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])},
        "insertLinkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクを挿入"])},
        "cta_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Now"])},
        "cta_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "cta_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
        "cta_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Now"])},
        "cta_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
        "cta_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
        "cta_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
        "cta_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "cta_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSVP"])},
        "cta_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
        "cta_get_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Offer"])},
        "cta_get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Quote"])},
        "cta_view_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Menu"])},
        "cta_get_showtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Showtimes"])},
        "cta_read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])}
      },
      "utils": {
        "MenuList": {
          "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["置換"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切り抜き"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
          "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貼り付け"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
          "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背面へ"])},
          "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最背面へ"])},
          "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前面へ"])},
          "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最前面"])},
          "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最前面に追加"])}
        }
      }
    },
    "views": {
      "BrandingLite": {
        "brandColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドカラー"])},
        "brandFonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドフォント"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロゴ"])},
        "primaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メイン"])},
        "secondaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブ"])}
      },
      "Create": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加する"])},
        "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコード"])},
        "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firework共同編集コードを入力して制作を開始する"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FWコードを入力"])}
      },
      "Login": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
        "loginFromFwTv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login from fw.tv"])},
        "loginFromBizPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスポータルからログイン"])}
      },
      "Music": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュージックライブラリ"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択解除"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュージックを検索する"])}
      },
      "Scenes": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Media"])}
      },
      "Styles": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気のスタイル"])},
        "allTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全てのテンプレート"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択解除"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りスタイル"])}
      }
    }
  }
}