<template>
  <div id="title-bar" class="flex">
    <router-link to="/Create" class="flex logo-wrapper">
      <svg-icon class="logo" :class="{ withRightBorder: isEditPages }" icon-class="logo-new" />
      <a-button v-if="isEditPages" type="link">
        <svg-icon icon-class="chevron-left-light" />
        <span class="padding-left-8">{{ $t('Home') }}</span>
      </a-button>
    </router-link>

    <div v-if="isEditPages" class="button-wrapper">
      <a-button class="flex" size="medium" @click="openPreview">
        <PlayCircleOutlined style="line-height: 0" />{{ $t('preview') }}
      </a-button>
      <a-button class="flex" size="medium" type="primary" @click="openPublishDialog">
        <UploadOutlined style="line-height: 0" /> {{ $t('publish') }}
      </a-button>
    </div>

    <!-- Profile Display -->
    <div v-else class="profile-wrapper">
      <SImage
        v-if="profile"
        ref="image"
        class="profile-thumbnail"
        :src="profile.avatar_url"
        fallback-src="https://cdn4.fireworktv.com/assets/default_avatar.png"
        fit="cover"
        @click="$emit('open-business-channel-selector')"
      ></SImage>

      <div class="profile-infos" @click="$emit('open-business-channel-selector')">
        <div v-if="business" class="profile-infos-business">
          {{ business.name }}
        </div>
        <div v-if="channel" class="profile-infos-channel">
          {{ channel.name }}
        </div>
      </div>
    </div>

    <transition name="el-fade-in">
      <div v-if="showPreview" class="full-preview">
        <FullPreview :auto-play="showPreview" class="preview-box"></FullPreview>
        <svg-icon class="preview-close" icon-class="close" @click="closePreview"></svg-icon>
      </div>
    </transition>

    <transition name="el-fade-in">
      <PublishModal
        :dialog-visible="dialogVisible"
        :info-form="infoForm"
        :duration="duration"
        :duration-limit="{ min: minVideoDuration, max: maxVideoDuration }"
        @close-publish-modal="cancelPublish"
        @publish-project-and-close-modal="publishProject"
        @change="handleInfoFormChange"
      />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { PlayCircleOutlined, UploadOutlined } from '@ant-design/icons-vue'

import cookie from '@/utils/Cookie'
import { getActionLink } from '@/utils/CTA'
import { MAX_VIDEO_DURATION_LONG, MAX_VIDEO_DURATION_REGULAR } from '@/utils/Global'
import { TaskItem } from '@/utils/Task'

import FullPreview from './FullPreview'
import PublishModal from './PublishModal'
import SImage from './utils/SImage.vue'

export default {
  name: 'TitleBar',
  components: {
    FullPreview,
    PlayCircleOutlined,
    PublishModal,
    SImage,
    UploadOutlined,
  },
  props: {
    isEditPages: Boolean,
    profile: Object,
    business: Object,
    channel: Object,
  },
  data() {
    return {
      minVideoDuration: 3, // Seconds
      showPreview: false,
      dialogVisible: false,
      infoForm: {
        caption: `Untitled ${`${new Date()}`.substring(4, 24)}`,
        hashtags: '',
        access: 'public',
        posterSrc: null,
        posterPayload: null,
        actionType: null,
        actionUrl: null,
      },
    }
  },
  computed: {
    ...mapState({
      projectTitle: (state) => state.projectTitle,
    }),
    duration() {
      const duration = this.videos.reduce((res, item) => {
        res += item.splitList.reduce((sum, clip) => {
          return sum + (clip.captureOut - clip.captureIn)
        }, 0)
        return res
      }, 0)
      return parseInt(duration / 1000000)
    },
    maxVideoDuration() {
      const canCreateLongVideo = this.business?.metadata?.can_create_long_video
      if (canCreateLongVideo) {
        return MAX_VIDEO_DURATION_LONG
      }
      return MAX_VIDEO_DURATION_REGULAR
    },
  },
  watch: {
    projectTitle(title) {
      this.infoForm.caption = title
    },
  },
  created() {
    this.$eventBusOn(this.$keys.keydown_esc, this.closePreview)
  },
  methods: {
    // Open Preview modal
    openPreview() {
      this.$_tracking_dispatch({
        event: this.$_tracking_events.FWSTUDIO_CLICK_PREVIEW_HEADER,
      })
      this.showPreview = true
    },
    // Close Preview modal
    closePreview() {
      this.showPreview = false
    },
    cancelPublish() {
      this.dialogVisible = false
    },
    //FIXME - this method seems very similar to VideoOrStudio.vue:publishVideo() or PreviewModal.vue:instantPublish() - can they be unified?
    //TODO - change to use projectService
    async publishProject() {
      if (this.duration < this.minVideoDuration || this.duration > this.maxVideoDuration) {
        return false
      }

      const channelId = cookie.get('channelId')
      try {
        const { action_url } = getActionLink(this.infoForm)
        const params = {
          access: this.infoForm.access,
          caption: this.infoForm.caption,
          hashtags: this.infoForm.hashtags.trim().split(','),
          video_posters: [],
          channel_id: channelId,
          // action_type: action_type,
          action_url: action_url,
        }
        if (this.infoForm.posterPayload) {
          params.video_posters = [this.infoForm.posterPayload]
        }
        const res = await this.axios.post(this.$api.videoProjectActionById('publish', this.$route.query.id), params)
        const progress_url = res.progress_url
        this.$message.info(this.$t('videoCompileInProgress'))
        const options = {
          jobURL: progress_url,
          onSuccess: async (r) => {
            this.taskFinish(r)
          },
          onError: (e) => {
            console.error('Video Compile Failed', e)
            this.$message.error(this.$t('videoCompileFailed'))
            this.$emit('clear-loading')
          },
          onProgress: (task) => {
            this.$emit('publish-loading', task.progress)
          },
        }
        new TaskItem(options)
      } catch (error) {
        console.error('Generation failed', error)
        this.$message.error(this.$t('videoCompileFailed'))
      } finally {
        // Handle loading
        this.dialogVisible = false
      }
    },
    // 测试 - 任务完成后的处理
    taskFinish(task) {
      task.result = '5mGkXz'
      const businessId = cookie.get('businessId')
      const channelId = cookie.get('channelId')
      // Navigate back to bizPortal
      window.location = this.$api.videoCreatedChannel(businessId, channelId)
    },

    openPublishDialog() {
      this.$_tracking_dispatch({
        event: this.$_tracking_events.FWSTUDIO_CLICK_PUBLISH_HEADER,
      })

      this.$eventBusEmit(this.$keys.updateCover)
      // Templates / Branding
      this.$eventBusEmit(this.$keys.updateProject, () => {
        this.dialogVisible = true
      })
    },
    handleInfoFormChange(changedFields) {
      // Handle one field at a time
      if (Object.keys(changedFields).length === 0) {
        return
      }
      // console.log(changedFields[`${Object.keys(changedFields)[0]}`])
      let changeField = {}
      if (changedFields[`${Object.keys(changedFields)[0]}`].value !== undefined) {
        changeField[`${Object.keys(changedFields)[0]}`] = `${changedFields[`${Object.keys(changedFields)[0]}`].value}`
      } else {
        changeField = changedFields
      }
      // console.log(changeField)
      this.infoForm = { ...this.infoForm, ...changeField }
    },
  },
}
</script>

<style lang="scss">
#title-bar {
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .logo-wrapper {
    height: 100%;

    .logo {
      height: 100%;
      width: 176px;
      padding: 0.5em 1.7em 0.5em 1em;

      &.withRightBorder {
        border-right: 1px solid $border-color;
        margin-right: 0.2em;
      }
    }

    .ant-btn.ant-btn-link {
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }

      .svg-icon {
        margin-right: 0.3em;
      }
    }
  }

  .full-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
    background-color: rgba($color: #000000, $alpha: 0.7);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
    box-sizing: border-box;
    .preview-close {
      position: absolute;
      right: 22px;
      cursor: pointer;
    }
  }
  .button-wrapper {
    display: flex;
    gap: 8px;
    margin-right: 10px;
  }
}

.profile-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  max-width: 300px;
  overflow: auto;
  padding: 0 20px;
  .profile-thumbnail {
    width: 40px;
    cursor: pointer;
    margin: 10px 0;
    aspect-ratio: 1;
    img {
      border-radius: 50%;
    }
  }
  .profile-infos {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 10px;
    color: white;
    font-size: 0.8em;
    justify-content: center;
    max-width: 240px;
    .profile-infos-business,
    .profile-infos-channel {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.padding-left-8 {
  padding-left: 8px;
}
</style>

<i18n>
{
  "en": {
    "Home": "Home",
    "videoCompileInProgress": "Video Compile In Progress...",
    "videoCompileFailed": "Video Compile Failed",
    "preview": "Preview",
    "publish": "Publish"
  },
  "ja": {
    "Home": "ホーム",
    "preview": "プレビューを表示",
    "publish": "制作完了",
    "create": "Create",
    "template": "Template",
    "sceneEditing": "シーンを編集",
    "post": "Post",
    "next": "次へ",
    "caption": "キャプション",
    "hashtags": "ハッシュタグ",
    "posters": "サムネイル設定",
    "postTo": "公開設定",
    "ctaButton": "CTAボタン",
    "global": "公開",
    "private": "非公開",
    "hashtagHint": "(#マークは不要。ハッシュタグひとつ入力する度にEnter押下してください。）",
    "see": "See",
    "finish": "Video Compose Finish!",
    "running": "Video Compose Be In Progress...",
    "failed": "Video Compose Failed",
    "cancel": "キャンセル",
    "posterMsg": "新しいサムネイルを追加してください",
    "upload": "アップロード",
    "insertLinkPlaceholder": "リンクを挿入",
    "cta_buy": "Shop Now",
    "cta_download": "Download",
    "cta_view": "See More",
    "cta_book": "Book Now",
    "cta_live": "Watch",
    "cta_donate": "Donate",
    "cta_apply": "Apply now",
    "cta_contact": "Contact Us",
    "cta_sign_up": "RSVP",
    "cta_order": "Order Now",
    "cta_get_offer": "Get Offer",
    "cta_get_quote": "Get Quote",
    "cta_view_menu": "See Menu",
    "cta_get_showtimes": "Get Showtimes",
    "cta_read_more": "Read More"
  },
  "zh": {
    "Home": "首頁",
    "preview": "預覽",
    "publish": "發佈"
  }
}
</i18n>
