import host from './apiHost'
// ^/ms : Meishe testing poert
// ^/fw : Firework serve
// ^/fwServe : Firework meishe server (https://meishe.firework-staging.com)

function isDev(path) {
  return host[path]
}
export default {
  toPath: (path) => (path.startsWith(isDev('fw')) ? path : `${isDev('fw')}${path}`),
  auth: host['auth'], // SDK auth address
  defaultFonts: [
    `https://cdn1-staging.fireworktv.com/studio/assets/font/NotoSansCJK-Regular.ttc`,
    `https://cdn1.fireworktv.com/medias/2021/11/1/1635787484-amydzsrj/original/NotoSansTelugu-Regular.ttf`,
  ],
  resources: `${isDev('ms')}/resource/list`,
  materials: `${isDev('ms')}/material/list`,
  msSts: `${isDev('ms')}/upload/sts/info`,
  msUpload: `${isDev('ms')}/upload/files`,

  videoCreate: `${isDev('fwServe')}/bs/video/create`, // initialize compile job
  jobInfo: `${isDev('fwServe')}/bs/job/info`, // lookup job

  me: `${isDev('fw')}/api/me`,
  bus: `${isDev('fw')}/api/bus`,
  listBusiness: `${isDev('fw')}/api/bus`,
  getBusiness: (business_id) => `${isDev('fw')}/api/bus/${business_id}`,
  listChannel: (business_id) => `${isDev('fw')}/api/bus/${business_id}/channels`,
  getChannel: (business_id, channel_id) => `${isDev('fw')}/api/bus/${business_id}/channels/${channel_id}`,
  soundTracks: `${isDev('fw')}/api/soundtracks`,
  soundTrackTags: (search_term) => `${isDev('fw')}/api/soundtrack_tags/${search_term}/soundtracks`,
  fwUpload: `${isDev('fw')}/api/upload_signatures`,
  videoProjects: `${isDev('fw')}/studio/video_projects`,
  // Template
  templateList: `${isDev('fw')}/studio/video_project_template_lists`,
  singleTemplateList: (tempalte_list_id) => `${isDev('fw')}/studio/video_project_template_lists/${tempalte_list_id}`,
  prompts: `${isDev('fw')}/studio/webpage_to_video_configs`,
  videoTemplates: `${isDev('fw')}/studio/video_project_templates`, // Todo: remove this when StyleList.vue, Create.vue are updated to use templateService.js
  createFromTemplate: (template_id) => `${isDev('fw')}/studio/video_projects/create_from_template/${template_id}`,
  stickers: `${isDev('fw')}/studio/stickers`, // sticker list
  captions: `${isDev('fw')}/studio/caption_packages`, // caption list
  fonts: `${isDev('fw')}/studio/fonts`, // font list
  userFonts: (business_id) => `${isDev('fw')}/studio/businesses/${business_id}/fonts`, // user's font list

  uploadFonts: (biz) => `${isDev('fw')}/studio/businesses/${biz}/fonts`, // upload font
  queryCaption: (captionUuid) => `${isDev('fw')}/studio/caption_packages/uuid/${captionUuid}`, //CT-2030 Legacy caption support - TODO remove this and clean up BE endpoint

  favorites: `${isDev('fw')}/studio/styles/favorites`, // favorite style list
  addFavorites: (id) => `${isDev('fw')}/studio/styles/${id}/favorite`, // add favorite style
  removeFavorites: (id) => `${isDev('fw')}/studio/styles/${id}/unfavorite`, // remove favorite style

  brandingPresets: (business_id, channel_id, id) =>
    `${isDev('fw')}/studio/businesses/${business_id}/channels/${channel_id}/branding_presets${id ? '/' + id : ''}`,

  videoProjectById: (id) => `${isDev('fw')}/studio/video_projects/${id}`,
  videoProjectActionById: (action, id) => `${isDev('fw')}/studio/video_projects/${id}/${action}`,

  fwAPIPath: (apiPath) => `${isDev('fw')}${apiPath}`,

  videoCreatedChannel: (business_id, channel_id) =>
    `${host['bizPortal']}/business/${business_id}/channel/${channel_id}/videos`,

  createBatch: `${isDev('fw')}/studio/video_compilation_batch`,
  feedBatch: (id) => `${isDev('fw')}/studio/video_compilation_batch/${id}/feed`,
  startBatch: (id) => `${isDev('fw')}/studio/video_compilation_batch/${id}/start`,
  cancelBatch: (id) => `${isDev('fw')}/studio/video_compilation_batch/${id}/cancel`,

  // External assets (ie Getty)
  searchImagesOn: (provider) =>
    `${isDev('fw')}/studio/external_assets/search/images?provider=${provider}&file_types=jpg`,
  searchVideosOn: (provider) => `${isDev('fw')}/studio/external_assets/search/videos?provider=${provider}`,
  importImageOn: (provider) => `${isDev('fw')}/studio/external_assets/import/${provider}/image`,
  importVideoOn: (provider) => `${isDev('fw')}/studio/external_assets/import/${provider}/video`,

  // Media Assets
  mediaAssets: `${isDev('fw')}/studio/media_assets`,
  mediaAssetsById: (id) => `${isDev('fw')}/studio/media_assets/${id}`,
  mediaAssetsUploadComplete: (id) => `${isDev('fw')}/studio/media_assets/${id}/upload_completed`,

  // Universal Animations
  getFx: (effectType) => `${isDev('fw')}/studio/studio_effects${effectType ? `?effect_type=${effectType}` : ''}`,

  // Webpage 2 Video
  w2vCrawl: `${isDev('fw')}/studio/ttv/crawl`,
  w2vSummarize: `${isDev('fw')}/studio/ttv/summarize`,

  // TTS
}
