// This file domain should aligned with vue.config.js
const isNodeProd = process.env.NODE_ENV === 'production'
const isVercelProd = process.env.VUE_APP_VERCEL_ENV === 'production'
const prodApiHost = {
  auth: `https://meishe.fireworktv.com/app/auth`,
  fw: 'https://api.firework.tv',
  fwServe: 'https://meishe.fireworktv.com',
  ms: 'https://testeapi.meishesdk.com:8443',
  bizPortal: 'https://business.firework.tv',
  embedCdn: '//asset.fwcdn2.com/js/storyblock.js',
  AMPLITUDE_API_KEY: '346f62aca5a1078bb5e2d2106dcdecc4',
  PIXEL_URL: 'https://p2.fwpixel.com',
}
const stagingApiHost = {
  auth: `https://meishe.firework-staging.com/app/auth`,
  // fw: "https://sandbox-media-asset-clips-metadata.sandbox.fireworktv.com", // Jan 2022 TGIF, for autoflip
  // fw: "https://sandbox-getty-multi-import.sandbox.fireworktv.com",
  fw: sessionStorage.getItem('fwApiUrl') || 'https://staging.fireworktv.com',
  fwServe: 'https://meishe.firework-staging.com',
  ms: 'https://testeapi.meishesdk.com:8443',
  bizPortal: 'https://business.firework-staging.com',
  embedCdn: '//asset-staging.fwcdn1.com/js/storyblock.js',
  AMPLITUDE_API_KEY: 'db3e50de980da95e2f09cbef6736aaa8',
  PIXEL_URL: 'https://p2-staging.fwpixel.com',
}

const apiHost = isNodeProd || isVercelProd ? prodApiHost : stagingApiHost
export default apiHost
