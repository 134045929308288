export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["置換"])},
      "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切り抜き"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
      "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貼り付け"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
      "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背面へ"])},
      "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最背面へ"])},
      "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前面へ"])},
      "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最前面"])},
      "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最前面に追加"])}
    }
  })
}
