'use strict'

import amplitude from 'amplitude-js'
import isEmpty from 'lodash/isEmpty'

import apiHost from '@/api/apiHost'
import { getBrowser, getLocale, getOS } from '@/utils/Environment'

// PIXEL SETUP
const nowAndNowLocal = () => {
  const now = new Date()
  const nowLocal = new Date(+now - now.getTimezoneOffset() * 60 * 1000)

  return [now, nowLocal]
}

const nowAndNowLocalToString = () => {
  const [now, nowLocal] = nowAndNowLocal()

  return [now.toISOString().replace('Z', ''), nowLocal.toISOString().replace('Z', '')]
}

const getPixelClientMeta = () => {
  const [now] = nowAndNowLocalToString()
  return {
    browser: getBrowser(),
    locale: getLocale(),
    os_name: getOS(),
    platform: 'web',
    product: 'studio.genosis',
    product_version: '1.0',
    track_version: '2.0',
    client_event_time: now,
    client_upload_time: now,
  }
}

const sendBeaconPixel = ({ host, path, data }) => {
  if (navigator.sendBeacon) {
    try {
      // multipart/form-data
      const formData = new FormData()
      formData.append('_json', encodeURI(JSON.stringify(data)))

      if (isEmpty(formData.get('_json'))) {
        throw Error('Empty payload')
      }

      const url = `${host}${path}`
      const success = navigator.sendBeacon(url, formData)
      return Promise.resolve(success)
    } catch (err) {
      return Promise.reject()
    }
  }
  return Promise.reject()
}

const sendGetPixel = ({ host, path, data }) => {
  const url = `${host}${path}`
  const fetchPromise = fetch(url + JSON.stringify({ ...data }, { addQueryPrefix: true }))
  Promise.resolve(fetchPromise)
}

function usePixelTracking() {
  const sendPixelTracking = ({ url: path, data = {} }) => {
    const pixelData = {
      referer: document.referrer,
      ...getPixelClientMeta(),
      ...data,
    }

    try {
      return sendBeaconPixel({ host: apiHost.PIXEL_URL, path, data: pixelData })
    } catch (err) {
      return sendGetPixel({ host: apiHost.PIXEL_URL, path, data: pixelData })
    }
  }

  const sendVisitorEventPixelTracking = ({ event, data = {} }) =>
    sendPixelTracking({
      data,
      url: `/trk/${event}`,
    })

  return {
    sendVisitorEventPixelTracking,
  }
}

const { sendVisitorEventPixelTracking } = usePixelTracking()

/*
  This is a Vue plugin that is essentially a wrapper for all tracking
  - See CT-1350
  - Abstracts and isolates concern of tracking, specifically whatever mechanism we
    choose to use (right now only Amplitude) can be easily swapped out
  - This should be installed at the global level (main.js) so it is available everywhere

  This plugin exposes the $_tracking_dispatch method and $_tracking_events enum list

  Usage:
  (Vue Instance).$_tracking_dispatch({ $_tracking_events.[event], props })
  */
export default {
  install: async (app) => {
    app.config.globalProperties.$_tracking_events = TRACKING_EVENTS

    app.config.globalProperties.$_tracking_dispatch = function ({ event, props = {} }) {
      const eventProperties = {
        event_properties: {
          browser: getBrowser(),
          // Approach: send these data points whenever they are available, regardless of event type
          business_id: this.$store?.state?.business?.id || null,
          channel_id: this.$store?.state?.channel?.id || null,
          project_id: this.$store?.state?.clip?.videoProject?.id || null,
          ...props,
        },
        language: getLocale(),
        os_name: getOS(),
        platform: 'web',
      }

      amplitude.getInstance().logEvent(event, eventProperties)

      // Send Pixel event
      if (this.$nff.profile?.encoded_id) {
        sendVisitorEventPixelTracking({
          data: {
            _user_id: this.$nff.profile?.encoded_id,
            internal_user: this.$nff.profile?.internal_user,
            event_properties: eventProperties.event_properties,
          },
          event,
        })
      }
    }

    // Finally, init Amplitude singleton
    await amplitude.getInstance().init(apiHost.AMPLITUDE_API_KEY)
  },
}

// Put tracking events enum at the bottom of this file, use JS Hoisting to our advantage for neat and tidiness
const TRACKING_EVENTS = {
  FWSTUDIO_CLICK_ADDMEDIA_MAIN: `FWStudio:click_addmedia_main`,
  FWSTUDIO_CLICK_SELECT_VIDEOPROJECT_MAIN: `FWStudio:click_select_videoproject_main`, // uid
  FWSTUDIO_CLICK_VIDEOPROJECT_DUPLICATE_MAIN: `FWStudio:click_videoproject_duplicate_main`, // uid
  FWSTUDIO_CLICK_VIDEOPROJECT_FWCODE_MAIN: `FWStudio:click_videoproject_fwcode_main`, // uid
  FWSTUDIO_CLICK_VIDEOPROJECT_DELETE_MAIN: `FWStudio:click_videoproject_delete_main`, // uid
  FWSTUDIO_CLICK_ENTER_FWCODE_MAIN: `FWStudio:click_enter_fwcode_main`,
  FWSTUDIO_CLICK_UPLOADMEDIA_ADDMEDIA: `FWStudio:click_uploadmedia_addmedia`,
  FWSTUDIO_MEDIA_UPLOADING_ADDMEDIA: `FWStudio:media_uploading_addmedia`, // file size / file length
  FWSTUDIO_CLICK_REMOVEMEDIA_ADDMEDIA: `FWStudio:click_removemedia_addmedia`, // file yid
  FWSTUDIO_CLICK_CANCEL_ADDMEDIA: `FWStudio:click_cancel_addmedia`,
  FWSTUDIO_CLICK_NEXT_ADDMEDIA: `FWStudio:click_next_addmedia`, // number of clips / total length
  FWSTUDIO_CLICK_SCENES_SIDENAV: `FWStudio:click_scenes_sidenav`,
  FWSTUDIO_CLICK_SCENES_TEXT_TAB: `FWStudio:click_scenes_text_tab`,
  FWSTUDIO_CLICK_SCENES_TEXT_PACKAGE: `FWStudio:click_scenes_text_package`, // package id
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_ANIMATION: `FWStudio:click_scenes_text_detail_animation`, // animation
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_SCALE: `FWStudio:click_scenes_text_detail_scale`, // scale
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_LINE_SPACING: `FWStudio:click_scenes_text_detail_line_spacing`, // line spacing
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_LETTER_SPACING: `FWStudio:click_scenes_text_detail_letter_spacing`, // letter spacing
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_FONT: `FWStudio:click_scenes_text_detail_font`, // font
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_ALIGHMENT: `FWStudio:click_scenes_text_detail_alighment`, // alighment
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_FONTCOLOR: `FWStudio:click_scenes_text_detail_fontcolor`, // color
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_BGCOLOR: `FWStudio:click_scenes_text_detail_bgcolor`, // color
  FWSTUDIO_CLICK_SCENES_TEXT_DETAIL_DELETE: `FWStudio:click_scenes_text_detail_delete`,
  FWSTUDIO_CLICK_SCENES_STICKER_TAB: `FWStudio:click_scenes_sticker_tab`,
  FWSTUDIO_CLICK_SCENES_STICKER_PACKAGE: `FWStudio:click_scenes_sticker_package`, // sticker id
  FWSTUDIO_CLICK_SCENES_STICKER_DETAIL_ANIMATION: `FWStudio:click_scenes_sticker_detail_animation`, // animation
  FWSTUDIO_CLICK_SCENES_STICKER_DETAIL_SCALE: `FWStudio:click_scenes_sticker_detail_scale`, // scale
  FWSTUDIO_CLICK_SCENES_STICKER_DETAIL_DELETE: `FWStudio:click_scenes_sticker_detail_delete`,
  FWSTUDIO_CLICK_SCENES_MEDIA_TAB: `FWStudio:click_scenes_media_tab`,
  FWSTUDIO_CLICK_SCENES_MEDIA_PACKAGE: `FWStudio:click_scenes_media_package`,
  FWSTUDIO_CLICK_SCENES_MEDIA_DETAIL_DELETE: `FWStudio:click_scenes_sticker_detail_delete`,
  FWSTUDIO_CLICK_STYLES_SIDENAV: `FWStudio:click_styles_sidenav`,
  FWSTUDIO_CLICK_STYLES_SELECT_STYLE: `FWStudio:click_styles_select_style`, // style id
  FWSTUDIO_CLICK_STYLES_SELECT_NONE: `FWStudio:click_styles_select_none`,
  FWSTUDIO_CLICK_MUSIC_SIDENAV: `FWStudio:click_music_sidenav`,
  FWSTUDIO_SEARCH_MUSIC_TERMS: `FWStudio:search_music_terms`, // terms
  FWSTUDIO_CLICK_MUSIC_USE_TRACK: `FWStudio:click_music_use_track`, // track name
  FWSTUDIO_CLICK_PREVIEW_HEADER: `FWStudio:click_preview_header`,
  FWSTUDIO_CLICK_PUBLISH_HEADER: `FWStudio:click_publish_header`,
  FWSTUDIO_CLICK_SCENES_LIST_ADDMEDIA: `FWStudio:click_scenes_list_addmedia`,
  FWSTUDIO_CLICK_TEMPLATE_CATEGORY: `FWStudio:click_template_category`, // template_list.name
  FWSTUDIO_CLICK_TEMPLATE_DETAIL: `FWStudio:click_template_detail`, // template_id
  FWSTUDIO_CLICK_BRANDING_SIDENAV: `FWStudio:click_branding_sidenav`,
  FWSTUDIO_ENTER_STUDIO_HOME: `FWStudio:enter_studio_home`,
  FWSTUDIO_PUBLISH_VIDEO_FROM_PROJECT: `FWStudio:publish_video_from_project`,

  FWSTUDIO_HOME_CREATE_PROJECT_FROM_BLANK_TEMPLATE: `FWStudio:home:create_project_from_blank_template`,
  FWSTUDIO_HOME_CREATE_PROJECT_FROM_TEMPLATE: `FWStudio:home:create_project_from_template`,
  FWSTUDIO_HOME_CREATE_PROJECT_FROM_DUPLICATE_PROJECT: `FWStudio:home:create_project_from_duplicate_project`,
  FWSTUDIO_HOME_CREATE_PROJECT_FROM_UPLOAD: `FWStudio:home:create_project_from_upload`,
  FWSTUDIO_HOME_CREATE_PROJECT_FROM_FWCODE: `FWStudio:home:create_project_from_fwcode`,

  // Create (Home) Events
  FWSTUDIO_CLICK_CREATE_SIDENAV: `FWStudio:click_create_sidenav`,

  // W2V Events
  FWSTUDIO_W2V_STEP_Configuration: 'FWStudio:W2V:step_configuration',
  FWSTUDIO_W2V_STEP_CrawlAndSummarize: 'FWStudio:W2V:step_crawl_and_summarize',
  FWSTUDIO_W2V_STEP_Manual: 'FWStudio:W2V:step_manual',
  FWSTUDIO_W2V_STEP_GenerateScenes: 'FWStudio:W2V:step_generate_scenes',
  FWSTUDIO_W2V_STEP_ReviewScenes: 'FWStudio:W2V:step_review_scenes',
  FWSTUDIO_W2V_STEP_BuildProject: 'FWStudio:W2V:step_build_project',
  FWSTUDIO_W2V_STEP_Previewer: 'FWStudio:W2V:step_previewer',

  FWSTUDIO_W2V_CONFIGURATION_INPUT: `FWStudio:W2V:configuration_input`,
  FWSTUDIO_W2V_CRAWLER_RESULTS: `FWStudio:W2V:crawler_results`,
  FWSTUDIO_W2V_SUMMARIZER_RESULTS: `FWStudio:W2V:summarizer_results`,
  FWSTUDIO_W2V_SCENE_GENERATION_RESULTS: `FWStudio:W2V:scene_generation_results`,
  FWSTUDIO_W2V_SCENE_REVIEW_IMAGE_SELECTED: `FWStudio:W2V:scene_review_user_selected_alternate_image`,
  FWSTUDIO_W2V_SCENE_REVIEW_IMAGE_UPLOAD: `FWStudio:W2V:scene_review_user_uploaded_image`,
  FWSTUDIO_W2V_SCENE_REVIEW_ADD: `FWStudio:W2V:scene_review_user_adds_scene`,
  FWSTUDIO_W2V_SCENE_REVIEW_DELETE: `FWStudio:W2V:scene_review_user_delete_scene`,
  FWSTUDIO_W2V_SCENE_REVIEW_INPUT: `FWStudio:W2V:scene_review_input`,
  FWSTUDIO_W2V_BUILD_PROJECT_DH_GENERATED: `FWStudio:W2V:build_project_generated_dh`,
  FWSTUDIO_W2V_BUILD_PROJECT_RESULTS: `FWStudio:W2V:build_project_results`,
  FWSTUDIO_W2V_PREVIEW_CLICK_REGENERATE: `FWStudio:W2V:click_regenerate_project`,
  FWSTUDIO_W2V_PREVIEW_CLICK_PLAY: `FWStudio:W2V:click_play_project`,

  FWSTUDIO_PREVIEW_MODAL_BATCH_CREATE_PROJECT: `FWStudio:preview_modal:batch_create_project`,
  FWSTUDIO_PREVIEW_MODAL_BATCH_CREATE_TEMPLATE: `FWStudio:preview_modal:batch_create_template`,
}
