import avatarService from '@/services/avatarService'

export default {
  namespaced: true,
  state: () => ({
    avatars: [],
  }),
  mutations: {
    setAvatars(state, payload) {
      state.avatars = payload
    },
  },
  actions: {
    loadAvatars({ commit, state }) {
      // Infrequently changed data - only fetch avatars once per session
      if (state.avatars.length) {
        return
      }

      avatarService
        .getAvatars()
        .then((response) => {
          commit('setAvatars', response.avatars)
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  getters: {
    // Get only published avatars
    getPublishedAvatars(state) {
      return state.avatars.filter((avatar) => avatar.state === 'published')
    },
    // Get all avatars
    getAllAvatars(state) {
      return state.avatars
    },
  },
}
