export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "zh-HK",
    "resource": {
      "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇您的商家和頻道"])},
      "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在使用FW Studio之前，您需要先建立商家和頻道"])},
      "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頻道"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進入"])},
      "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往Firework商家控制台"])}
    }
  })
}
