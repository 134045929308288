export default {
  "zh-HK": {
    "App": {
      "scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分鏡"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音樂"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈中..."])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享你的回饋讓我們更好"])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未支援的瀏覽器"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請使用Google Chrome來使用Firework Studio"])}
    },
    "components": {
      "branding": {
        "BrandingPreview": {
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比例"])}
        }
      },
      "create": {
        "BusinessChannelSelector": {
          "businessChannelModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇您的商家和頻道"])},
          "businessChannelModalTitleNoBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在使用FW Studio之前，您需要先建立商家和頻道"])},
          "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家"])},
          "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頻道"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進入"])},
          "enterBusinessPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往Firework商家控制台"])}
        },
        "CreateSideNav": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳媒體"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往頭開始編輯"])},
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專案"])},
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])}
        },
        "MediaItem": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["處理中..."])}
        },
        "Medias": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your uploads will appear here"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter keyword search"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖片"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片"])},
          "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Failed"])},
          "loadMediaFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Medias Failed"])}
        },
        "PreviewModal": {
          "videoOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分鏡概覽"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
          "sceneOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["分鏡 ", _interpolate(_named("sceneOrder"))])},
          "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳CSV檔"])},
          "batchEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次編輯"])},
          "batchProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批次處理"])},
          "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
          "batchEditingButtonHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將CSV檔案拖曳到這裡"])},
          "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取檔案"])},
          "totalVideoNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["共", _interpolate(_named("videoNumber")), "影片"])},
          "sampleCsvFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下載CSV樣本"])},
          "totalFinishedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已完成 ", _interpolate(_named("finishedVideo")), " / ", _interpolate(_named("videoNumber")), " 影片"])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
          "confirmCancelBatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel the batch editing job. Continue?"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "cancelProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel process"])},
          "cancelBatchSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel batch process success"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步"])},
          "failedVideo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("failedVideoCount")), " failed."])}
        },
        "Project": {
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往頭開始編輯"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖放檔案在這裡開始上傳"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或選取檔案"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "fwCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標題"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "ProjectItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯DOM"])},
          "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯範本"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "projectState": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
            "compiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiling"])},
            "compiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compiled"])},
            "publishPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Pending"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
            "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
          }
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])}
        },
        "Template": {
          "templateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部範本"])}
        },
        "TemplateItem": {
          "fwcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
          "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMP Story"])},
          "editDom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯DOM"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])}
        },
        "UploadItem": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳媒體"])}
        }
      },
      "music": {
        "MusicList": {
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪"])},
          "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
          "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "noMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No More Content"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "applyAudioFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Audio Failed"])}
        }
      },
      "Preview": {
        "swapMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替換媒體"])},
        "loadModulesFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入失敗"])},
        "loadProjectFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法載入，請稍候再試"])},
        "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])}
      },
      "scenes": {
        "Canvas": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "CropDialog": {
          "motionOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫：開啟"])},
          "motionOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫：關閉"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])}
        },
        "DraftList": {
          "addScene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加分鏡"])},
          "trimVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪影片"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分割"])},
          "totalVideoDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片全長"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存中"])},
          "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存成功！"])}
        },
        "FontPanel": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時長"])},
          "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字型"])},
          "animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動畫"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比例"])},
          "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字顏色"])},
          "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背景顏色"])}
        },
        "Materials": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字"])},
          "sticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預設圖形"])},
          "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒體庫"])},
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add & arrange video clips and images to build your video"])},
          "addMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
          "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的影片專案"])},
          "Draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
        },
        "MediaComponent": {
          "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
        },
        "MediaList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])},
          "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳圖片與影片"])}
        },
        "StickerList": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
          "nomore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomore"])}
        }
      },
      "styles": {
        "FavoriteList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        },
        "StyleList": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Module Failed"])}
        }
      },
      "template": {
        "Templates": {
          "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending Styles"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])}
        }
      },
      "TitleBar": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])},
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
        "sceneEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scene Editing"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標題"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤"])},
        "posters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短片封面"])},
        "postTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開設定"])},
        "ctaButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短片連結"])},
        "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人"])},
        "hashtagHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以逗號分隔標籤（例如: Travel, Trip, Nomad）"])},
        "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
        "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片合成完成！"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在合成影片..."])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影片合成失敗"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "posterMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增封面"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳"])},
        "insertLinkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增超連結（https://...）"])},
        "cta_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Now"])},
        "cta_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "cta_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
        "cta_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Now"])},
        "cta_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch"])},
        "cta_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
        "cta_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
        "cta_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "cta_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSVP"])},
        "cta_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
        "cta_get_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Offer"])},
        "cta_get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Quote"])},
        "cta_view_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Menu"])},
        "cta_get_showtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Showtimes"])},
        "cta_read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])}
      },
      "utils": {
        "MenuList": {
          "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替換"])},
          "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
          "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貼上"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
          "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往下一層"])},
          "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最下層"])},
          "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往上一層"])},
          "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最上層"])},
          "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加在上面"])}
        }
      }
    },
    "views": {
      "BrandingLite": {
        "brandColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌顏色"])},
        "brandFonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌字型"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "primaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要字型"])},
        "secondaryFont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輔助字型"])}
      },
      "Create": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增媒體"])},
        "fwCodeModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FW代碼"])},
        "fwCodeModalDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入Firework共同編輯代碼來開始製作影片"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "fwCodeInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入FW代碼"])}
      },
      "Login": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
        "loginFromFwTv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login from fw.tv"])},
        "loginFromBizPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從Firework商務控制台登入"])}
      },
      "Music": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音樂庫"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])}
      },
      "Scenes": {
        "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Media"])}
      },
      "Styles": {
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending Styles"])},
        "allTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部範本"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])}
      }
    }
  }
}