export default {
  openMediaAndUpload: 'openMediaAndUpload',
  deleteBackgroundClip: 'deleteBackgroundClip', // delete background media and use background color
  deleteClips: 'deleteClips', // delete all currently selected clips in Workflow from project
  detachFromBackground: 'detachFromBackground', // convert background media to overlay and add background color
  setOverlayAsBackground: 'setOverlayAsBackground', // convert overlay to background media
  editClip: 'editClip',
  changeMonitor: 'changeMonitor',
  afreshVideoClip: 'afreshVideoClip',
  seek: 'seek',
  drawBox: 'drawBox',
  modifySceneDuration: 'modifySceneDuration',
  updateCover: 'updateCover',
  updateProject: 'updateProject',
  clearWorkflowSelections: 'Clear all selected clips in WorkFlow',
  swapMedia: 'swapMedia',
  openCropDialog: 'openCropDialog',
  copy: 'copy',
  paste: 'paste',
  openMediaDialog: 'openMediaDialog',
  openAssignChannelSelector: 'openAssignChannelSelector',
  swapUserAddedClip: 'swapUserAddedClip',
  preSetPlay: 'preSetPlay',
  setupCursorOffset: 'setupCursorOffset',
  copyScene: 'copyScene',
  pasteScene: 'pasteScene',
  selectScene: 'selectScene',
  resetCopiedScene: 'resetCopiedScene',
  noteEdited: 'noteEdited',
  resetInpoint: 'resetInpoint',
  deleteAsset: 'deleteAsset',
  refreshMediaAsset: 'refreshMediaAsset',
  // stream context events
  onPlaybackStopped: 'onPlaybackStopped',
  onPlaybackTimelinePosition: 'onPlaybackTimelinePosition',
  onImageGrabbedArrived: 'onImageGrabbedArrived',
  onWebRequestWaitStatusChange: 'onWebRequestWaitStatusChange',
  onFinishAssetPackageInstallation: 'onFinishAssetPackageInstallation',
  refreshVideoProjects: 'refreshVideoProjects',
  keydown_esc: 'Escape key pressed',
  keydown_ctrl_s: 'Command/Ctrl-S pressed',
  // CT-1409 This event is to imperatively add to the undo/redo snapshot history as some
  // creatives changes directly mutate in-memory objects and are not detected by Vuex mutation
  // event subscriptions...
  change_clipAsset: 'A creative asset has changed',
  // CT-1909 Timeline lifecyle events
  getTimeline: 'getTimeline',
  rebuildTimeline: 'rebuildTimeline',
  updated_timeline: 'Timeline has completed rebuilding',
  // CT-1940 Template events
  change_template: 'Project template has changed',
  // CT-2070 Media Cropping events
  mediaCroppingChange: 'A clip has changed in the Media Cropping modal',
}
