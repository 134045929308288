<template>
  <a-layout id="app" v-loading="isLoading || isNffLoading">
    <a-layout-header class="app-header flex">
      <TitleBar
        :profile="profile"
        :business="business"
        :channel="channel"
        :is-edit-pages="isEditPages"
        @publish-loading="publishLoading"
        @clear-loading="clearPublishLoading"
        @open-business-channel-selector="openChannelSelectorFromAvatar"
      ></TitleBar>
    </a-layout-header>
    <a-modal
      :visible="isBrowserNotSupported"
      :mask-closable="false"
      :closable="false"
      :title="$t('browserNotSupportedTitle')"
      :footer="null"
    >
      {{ $t('browserNotSupportedText') }}
    </a-modal>

    <ChannelVsUserDialog />

    <div v-if="isPublishLoading" class="publish-loading-overlay flex flex-column">
      <h4>{{ $t('publishing') }}</h4>
      <div class="publish-progress">
        <a-progress :show-info="false" :percent="progress" />
      </div>
    </div>

    <SystemAlerts />

    <a-layout-content v-if="!isNffLoading">
      <router-view @publish-loading="publishLoading" @hide-progress="clearPublishLoading"></router-view>
    </a-layout-content>

    <div v-show="dragDivVisible" class="drag-item" :style="dragDivStyle"></div>

    <BusinessChannelSelector
      class="business-channel-selector-modal-wrapper"
      :class="{ show: showBusinessChannelModal }"
      :project="project"
      :business-id="businessId"
      :channel-id="channelId"
      :force-open="!businessId || !channelId"
      @close-business-channel-modal="showBusinessChannelModal = false"
      @change-business-channel="changeBusinessAndChannel"
      @assign-project-channel="assignProjectToChannel"
    />

    <Fireworks v-if="isEasterEggFireworks" />
  </a-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { connectSocket } from '@/channels'
import SystemAlerts from '@/components/SystemAlerts'
import cookies from '@/utils/Cookie'
import { getTokenFromCookie } from '@/utils/Token'

import ChannelVsUserDialog from './components/ChannelVsUserDialog'
import BusinessChannelSelector from './components/create/BusinessChannelSelector'
import Fireworks from './components/Fireworks'
import TitleBar from './components/TitleBar'

const SECRECTMODE_CODE = 'FwStudi0!'
const EASTEREGG_FIREWORKS_CODE = 'firework rocks'

export default {
  name: 'App',
  components: {
    BusinessChannelSelector,
    ChannelVsUserDialog,
    Fireworks,
    SystemAlerts,
    TitleBar,
  },
  data() {
    return {
      isPublishLoading: false,
      progress: 0,
      publishLoadingTick: null,
      isBrowserNotSupported: false,
      showBusinessChannelModal: false,
      businessId: cookies.get('businessId'),
      channelId: cookies.get('channelId'),
      project: null, // For assign channel

      isConsoleOpen: false,
    }
  },
  computed: {
    ...mapState({
      isFinishNvs: (state) => state.isFinishNvs,
      isFinishProject: (state) => state.isFinishProject,
      isSecretMode: (state) => state.isSecretMode,
      isEasterEggFireworks: (state) => state.isEasterEggFireworks,
      profile: (state) => state.profile,
      business: (state) => state.business,
      channel: (state) => state.channel,
      isNffLoading: (state) => state.isNffLoading,
    }),
    defaultActive() {
      return this.$route.name || ''
    },
    dragDivStyle() {
      return this.$store.state.draggable.style
    },
    dragDivVisible() {
      return this.$store.state.draggable.isDragging
    },
    isLoading() {
      if (['Edit', 'Create'].includes(this.$route.name)) {
        return false
      }
      return !(this.isFinishNvs && this.isFinishProject) && this.isEditPages
    },
    isEditPages() {
      return this.$route.name === 'Edit'
    },
    currentBrowser() {
      return (function (agent) {
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'MS Edge'
          case agent.indexOf('edg/') > -1:
            return 'Edge (Chrome base)'
          case agent.indexOf('opr') > -1 && !!window.opr:
            return 'Opera'
          case agent.indexOf('chrome') > -1 && !!window.chrome:
            return 'Chrome'
          case agent.indexOf('trident') > -1:
            return 'MS IE'
          case agent.indexOf('firefox') > -1:
            return 'Mozilla Firefox'
          case agent.indexOf('safari') > -1:
            return 'Safari'
          default:
            return 'Others'
        }
      })(window.navigator.userAgent.toLowerCase())
    },
  },
  created() {
    // Check if Naboo flags are fully loaded
    this.$nff.isReady().finally(() => {
      this.setIsNffLoading(false)
      this.checkLogin()
    })
  },
  mounted() {
    this.checkSupportedBrowser()
    // eslint-disable-next-line
    const isEnabledSharedArrayBuffer =
      typeof SharedArrayBuffer === 'function' && typeof window !== 'undefined' && window.crossOriginIsolated === true
    // Event bus
    this.$eventBusOn(this.$keys.openAssignChannelSelector, this.openAssignChannelSelector)

    this.logCurrentBreakpoint()
    window.addEventListener('resize', this.logCurrentBreakpoint)

    window.addEventListener('keydown', this.handleGlobalKeydown)
  },
  methods: {
    ...mapActions({
      setProfile: 'setProfile',
      setBusiness: 'setBusiness',
      setChannel: 'setChannel',
      setCurrentBreakpoint: 'setCurrentBreakpoint',
      setPhoenixChannelSocket: 'setPhoenixChannelSocket',
      setSecretMode: 'setSecretMode',
      setEasterEggFireworks: 'setEasterEggFireworks',
      setIsNffLoading: 'setIsNffLoading',
    }),
    // CT-2301 Global activator for console (to activate secret mode)
    handleGlobalKeydown(e) {
      // Activate on CTRL-SHIFT-Backtick or CMD-K
      if ((e.ctrlKey && e.shiftKey && e.keyCode === 192) || (e.metaKey && e.keyCode === 75)) {
        if (this.isConsoleOpen) {
          return
        }

        this.isConsoleOpen = true
        this.$prompt({
          okText: this.$t('apply'),
          cancelText: this.$t('cancel'),
          onOk: (val) => {
            this.isConsoleOpen = false
            if (!val) {
              return
            }

            if (val === SECRECTMODE_CODE) {
              this.$message.success('Secret Mode Toggled')
              return this.setSecretMode(!this.isSecretMode)
            }
            if (val === EASTEREGG_FIREWORKS_CODE) {
              this.$message.success('See Our Sparks!')
              return this.setEasterEggFireworks(true)
            }
          },
          onCancel: () => {
            this.isConsoleOpen = false
          },
        })
      }
    },
    logCurrentBreakpoint() {
      const breakpoint = window.getComputedStyle(document.body, ':before').content.replace(/"/g, '')
      this.setCurrentBreakpoint(breakpoint)
    },
    checkSupportedBrowser() {
      const supportedBrowsers = ['Edge (Chrome base)', 'Chrome', 'Mozilla Firefox']
      this.isBrowserNotSupported = supportedBrowsers.indexOf(this.currentBrowser) < 0
    },
    selectRouter(event) {
      const name = event.key

      this.$_tracking_dispatch({
        event: this.$_tracking_events[`FWSTUDIO_CLICK_${name.toUpperCase()}_SIDENAV`],
      })

      const { id } = this.$route.query
      const r = {
        name,
        query: { id },
      }
      this.$router.push(r)
    },
    publishLoading(progress) {
      clearTimeout(this.publishLoadingTick)
      this.isPublishLoading = true
      this.progress = progress > this.progress ? progress : this.progress
    },
    clearPublishLoading() {
      this.publishLoadingTick = setTimeout(() => {
        this.isPublishLoading = false
        this.progress = 0
      }, 1000)
    },
    async checkLogin() {
      // Avoid calling fw/me api again and instead get profile data from $nff
      const profile = this.$nff.profile

      if (profile && !profile.message) {
        this.setProfile(this.$nff.profile)
      } else {
        console.error('Token not valid:', profile)
        return this.$router.push({ name: 'Login' })
      }

      if (!cookies.get('businessId') || !cookies.get('channelId')) {
        return this.forceDisplayBusinessAndChannelSelector()
      }
      this.getBusinessInfo()
      this.getChannelInfo()

      // Set phoenix channel socket
      const tokenFromCookie = getTokenFromCookie()
      const socket = connectSocket(tokenFromCookie)
      this.setPhoenixChannelSocket(socket)
    },
    forceDisplayBusinessAndChannelSelector() {
      this.businessId = ''
      this.channelId = ''
      this.showBusinessChannelModal = true
      this.project = null
    },
    setBusinessId(id) {
      this.businessId = id
      cookies.set('businessId', id)
    },
    setChannelId(id) {
      this.channelId = id
      cookies.set('channelId', id)
    },
    changeBusinessAndChannel(businessId, channelId) {
      this.setIsNffLoading(true)
      this.$nff.fetchNabooFlags(businessId)
      this.$nff.isReady().then(() => {
        this.setIsNffLoading(false)
      })
      this.setBusinessId(businessId)
      this.setChannelId(channelId)
      this.getBusinessInfo()
      this.getChannelInfo()
      this.$eventBusEmit(this.$keys.refreshVideoProjects)
    },
    async getBusinessInfo() {
      const businessId = cookies.get('businessId')
      try {
        if (!businessId) {
          throw 'No Business Id'
        }
        const businessResponse = await axios.get(this.$api.getBusiness(businessId))
        this.setBusiness(businessResponse)
        return Promise.resolve()
      } catch (e) {
        console.error(e)
        return this.forceDisplayBusinessAndChannelSelector()
      }
    },
    async getChannelInfo() {
      const businessId = cookies.get('businessId')
      const channelId = cookies.get('channelId')
      try {
        if (!channelId) {
          throw 'No Channel Id'
        }
        const channelResponse = await axios.get(this.$api.getChannel(businessId, channelId))
        this.setChannel(channelResponse)
      } catch (e) {
        console.error(e)
        return this.forceDisplayBusinessAndChannelSelector()
      }
    },
    openChannelSelectorFromAvatar() {
      this.project = null
      this.showBusinessChannelModal = true
    },
    openAssignChannelSelector(project) {
      this.project = project
      this.showBusinessChannelModal = true
    },
    async assignProjectToChannel(project, channelId) {
      await this.axios.put(this.$api.videoProjects + `/${project.id}`, {
        channel_id: channelId,
      })
      this.$message.success(this.$t('projectChannelUpdated'))
    },
  },
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir Next', Avenir, sans-serif !important;
}
#app {
  // background-color: $main-bgc;
  background: linear-gradient(0, rgb(15, 15, 15), rgb(16, 16, 16) 50%, rgb(50, 50, 50));
  height: 100vh;
  display: flex;
  // > * {
  //   padding: 0 48px;
  // }
  .menu-vertical {
    overflow-y: scroll;
    padding-top: 10px;
    flex-shrink: 0;
    width: 100px;
    height: calc(100% - 10px);
    background-color: transparent;
    border-right: 1px solid $border-color;
    &::-webkit-scrollbar {
      display: none !important;
    }
    .ant-menu-item {
      height: auto;
      // padding-top: 11px;
      margin-bottom: 12px;
      padding-right: 9px !important;
      padding-left: 9px !important;
      line-height: 1em;
      background-color: transparent;
      &:focus,
      &:hover {
        background-color: transparent;
      }
      .menu-item {
        position: relative;
        padding-top: 100%;
        .menu-icon {
          width: 70px;
          height: 60px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
        }

        span {
          position: absolute;
          /* bottom: -20%; */
          top: 70%;
          left: 0;
          right: 0;
          text-align: center;
          color: rgba(255, 255, 255, 0.4);
        }

        .menu-item-title:lang(ja) {
          font-size: 80%;
        }
      }
    }
    .ant-menu-item-selected .menu-item,
    .is-active .menu-item {
      width: 100%;
      border-radius: 6px;
      background-color: #404040;
    }
  }
  .app {
    &-header {
      height: $header__height;
      border-bottom: 1px solid $border-color;
      line-height: normal;
      background: none;
      padding: 0;
    }
    &-main {
      display: flex;
      flex: 1;
      padding: 0;
    }
  }

  .drag-item {
    position: absolute;
    background-size: contain;
    background-position: center;
    transform: translate(-50%, -50%);
  }

  .publish-loading-overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1002;
    color: white;
  }
  .publish-progress {
    width: 300px;
  }
}
.business-channel-selector-modal-wrapper {
  position: absolute;
  top: 0;
  display: none;
  &.show {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  width: 100%;
  height: 100%;
  z-index: 10;
}

/**
 * @section Breakpoints
 * These values will not show up in content, but can be queried by JavaScript to know which breakpoint is active.
 * https://1x.antdv.com/components/layout/
 */

body::before {
  content: 'md';
  display: none;
  visibility: hidden;
}

@media (min-width: 71em) {
  body::before {
    content: 'lg';
  }
}

@media (min-width: 86em) {
  body::before {
    content: 'xl';
  }
}
</style>
<i18n>
{
  "en": {
    "templates": "Templates",
    "styles": "Styles",
    "publishing": "Publishing ...",
    "browserNotSupportedTitle": "Browser not supported",
    "browserNotSupportedText": "Please use Google Chrome or Firefox on desktop to access the Firework Studio.",
    "projectChannelUpdated": "Video project channel has been updated"
  }
}
</i18n>

<i18n locale="ja">
{
  "templates": "テンプレート",
  "styles": "スタイル",
  "publishing": "読み込み中...",
  "browserNotSupportedTitle": "Browser not supported",
  "browserNotSupportedText": "Please use Google Chrome to access the Firework Studio.",
  "feedback": "サービスに対するフィードバックをお送りください。"
}
</i18n>

<i18n locale="zh-TW">
{
  "templates": "範本",
  "styles": "Styles",
  "publishing": "發佈中...",
  "browserNotSupportedTitle": "未支援的瀏覽器",
  "browserNotSupportedText": "請在電腦上使用 Google Chrome 或 Firework 來使用 Firework Studio"
}
</i18n>

<i18n locale="zh-HK">
{
  "templates": "範本",
  "styles": "Styles",
  "publishing": "發佈中...",
  "browserNotSupportedTitle": "未支援的瀏覽器",
  "browserNotSupportedText": "請在電腦上使用 Google Chrome 或 Firework 來使用 Firework Studio"
}
</i18n>
