import { createStore } from 'vuex'

import undoRedoVuexPlugin from '@/plugins/undoRedoPlugin'

import assets from './modules/assets'
import avatar from './modules/avatar'
import branding from './modules/branding'
import clip from './modules/clip'
import create from './modules/create'
import draggable from './modules/draggable'
import edit from './modules/edit'
import fx from './modules/fx'
import mediaCropping from './modules/mediaCropping'
import tts from './modules/tts'

const cloneDeep = require('clone-deep')

export const store = createStore({
  state() {
    return {
      isSecretMode: false,
      isNffLoading: true,
      isEasterEggFireworks: false,
      isFinishNvs: false,
      isFinishProject: false,
      editBoxStatus: false, // 字幕编辑窗是否打开中
      isScenePristine: false, // Ephemoral - this tracks if currently selected Scene was added by user in current session (not if scene is empty)
      isEditCaption: false, // 是否正在编辑字幕（输入框打开中）
      projectTitle: '',
      copyClip: null, // 复制的clip,
      // Profile picture & login business / channel status
      profile: null,
      business: null,
      channel: null,
      sceneTimeSeries: [],
      isFullPreviewVisible: false,
      currentBreakpoint: '',
      phoenixChannelSocket: null,
    }
  },
  mutations: {
    setCurrentBreakpoint(state, breakpoint) {
      state.currentBreakpoint = breakpoint
    },
    setIsFullPreviewVisible(state, payload) {
      state.isFullPreviewVisible = payload
    },
    setSceneTimeSeries(state, sceneTimeSeries) {
      state.sceneTimeSeries = sceneTimeSeries
    },
    setIsScenePristine(state, bool) {
      state.isScenePristine = bool
    },
    setSecretMode(state, secretMode) {
      state.isSecretMode = secretMode
    },
    setIsNffLoading(state, isLoading) {
      state.isNffLoading = isLoading
    },
    setEasterEggFireworks(state, isEasterEggFireworks) {
      state.isEasterEggFireworks = isEasterEggFireworks
    },
    setNvsStatus(state, isFinish) {
      state.isFinishNvs = isFinish
    },
    setEditBoxStatus(state, status) {
      state.editBoxStatus = status
    },
    setIsEdit(state, status) {
      state.isEditCaption = status
    },
    setProjectStatus(state, isFinish) {
      state.isFinishProject = isFinish
    },
    setCopyClip(state, clip) {
      state.copyClip = cloneDeep(clip)
    },
    setProjectTitle(state, title) {
      state.projectTitle = title
    },
    setProfile(state, profile) {
      state.profile = profile
    },
    setBusiness(state, business) {
      state.business = business
    },
    setChannel(state, channel) {
      state.channel = channel
    },
    setPhoenixChannelSocket(state, phoenixChannelSocket) {
      state.phoenixChannelSocket = phoenixChannelSocket
    },
  },
  actions: {
    setCurrentBreakpoint({ commit }, breakpoint) {
      commit('setCurrentBreakpoint', breakpoint)
    },
    setIsFullPreviewVisible({ commit }, payload) {
      commit('setIsFullPreviewVisible', payload)
    },
    setSceneTimeSeries({ commit }, sceneTimeSeries) {
      commit('setSceneTimeSeries', sceneTimeSeries)
    },
    setSecretMode({ commit }, secretMode) {
      commit('setSecretMode', secretMode)
    },
    setIsNffLoading({ commit }, isLoading) {
      commit('setIsNffLoading', isLoading)
    },
    setEasterEggFireworks({ commit }, easterEggFireworks) {
      commit('setEasterEggFireworks', easterEggFireworks)
    },
    setNvsStatus({ commit }, isFinish) {
      commit('setNvsStatus', isFinish)
    },
    setEditBoxStatus({ commit }, status) {
      commit('setEditBoxStatus', status)
    },
    setProjectStatus({ commit }, isFinish) {
      commit('setProjectStatus', isFinish)
    },
    setProjectTitle({ commit }, title) {
      commit('setProjectTitle', title)
    },
    setProfile({ commit }, profile) {
      commit('setProfile', profile)
    },
    setBusiness({ commit }, business) {
      commit('setBusiness', business)
    },
    setChannel({ commit }, channel) {
      commit('setChannel', channel)
    },
    setPhoenixChannelSocket({ commit }, phoenixChannelSocket) {
      commit('setPhoenixChannelSocket', phoenixChannelSocket)
    },
  },
  modules: {
    assets,
    avatar,
    branding,
    clip,
    create,
    draggable,
    edit,
    fx,
    mediaCropping,
    tts,
  },
  plugins: [undoRedoVuexPlugin],
})

export default {
  store,
  install: (app) => {
    app.use(store)
  },
}
