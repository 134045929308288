<template>
  <a-modal
    :visible="dialogVisible"
    :dialog-style="{
      top: 'calc(7vh)',
      minWidth: '1233px',
    }"
    width="60%"
    :body-style="{
      height: 'calc(84vh)',
      position: 'relative',
      display: 'flex',
    }"
    :title="text.modalTitle"
    :footer="null"
    @cancel="hideModal"
  >
    <div class="publish-modal-preview-wrapper">
      <!-- Do not remove v-if, component needs to unmount between modal activations -->
      <FullPreview v-if="dialogVisible" :light-mode="true" />
    </div>
    <div class="publish-modal-content-wrapper">
      <a-form
        :model="formState"
        class="publish-modal-content-form"
        :layout="'vertical'"
        :hide-required-mark="true"
        @finish="onFinish"
      >
        <a-form-item>
          <a-alert
            v-if="duration < durationLimit.min"
            class="publish-warning"
            :closable="false"
            type="warning"
            :show-icon="true"
            :message="
              $t('videoTooShort', {
                durationOfVideo: duration,
                minVideoDuration: durationLimit.min,
              })
            "
          ></a-alert>
          <a-alert
            v-if="duration > durationLimit.max"
            class="publish-warning"
            :closable="false"
            type="warning"
            :show-icon="true"
            :message="
              $t('videoTooLong', {
                durationOfVideo: duration,
                maxVideoDuration: durationLimit.max,
              })
            "
          ></a-alert>
        </a-form-item>

        <a-form-item label="Caption" name="caption" :rules="[{ required: true, message: 'Required' }]">
          <a-input v-model:value="formState.caption" />
        </a-form-item>

        <a-form-item :label="$t('hashtags')" name="hashtags" :help="$t('hashtagHint')">
          <!-- TODO : upgrade to new hashtags -->
          <a-input v-model:value="formState.hashtags" />
        </a-form-item>

        <a-form-item class="mb0">
          <a-collapse class="publish-modal-collapse" :bordered="false" :expand-icon-position="'right'">
            <template #expandIcon="props">
              <RightOutlined :rotate="props.isActive ? 90 : 0" />
            </template>
            <a-collapse-panel key="1" :header="$t('poster')">
              <a-upload-dragger
                name="poster"
                accept="image/png, image/jpeg"
                class="poster-uploader"
                :show-upload-list="false"
                :before-upload="beforeUploadPoster"
              >
                <img v-if="infoForm.posterSrc" ref="image" class="media-cover" :src="infoForm.posterSrc" fit="cover" />
                <div v-else>
                  <div class="poster-text">{{ $t('posterMsg') }}</div>
                  <PlusCircleOutlined />
                </div>
              </a-upload-dragger>
            </a-collapse-panel>
          </a-collapse>
        </a-form-item>
        <a-form-item name="action_url">
          <a-collapse class="publish-modal-collapse" :bordered="false" expand-icon-position="right">
            <template #expandIcon="props">
              <RightOutlined :rotate="props.isActive ? 90 : 0" />
            </template>
            <a-collapse-panel key="1" :header="$t('ctaButton')">
              <a-input :placeholder="$t('insertLinkPlaceholder')" />
            </a-collapse-panel>
          </a-collapse>
        </a-form-item>
        <a-form-item label="Visibility">
          <a-radio-group v-model:value="formState.access">
            <a-radio value="private">
              <div class="radio-option">
                <div class="option-1">{{ $t('private') }}</div>
                <div class="option-2">
                  <svg-icon icon-class="lock"></svg-icon>
                  <span>
                    {{ $t('private_description') }}
                  </span>
                </div>
              </div>
            </a-radio>
            <a-radio value="public">
              <div class="radio-option">
                <div class="option-1">{{ $t('public') }}</div>
                <div class="option-2">{{ $t('public_description') }}</div>
              </div>
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item :style="{ textAlign: 'right' }">
          <div class="submit-action">
            <div>
              <a-button class="fw-ant-button" size="large" type="primary" html-type="submit">
                {{ $t('create') }}
              </a-button>
            </div>
            <i18n path="agreeFireworkText" tag="div" class="submit-action-agree">
              <template #communityGuideline>
                <a href="https://fw.tv/community_guidelines" target="_blank">{{ $t('communityGuideline') }}</a>
              </template>
              <template #terms>
                <a href="https://fw.tv/terms" target="_blank">{{ $t('terms') }}</a>
              </template>
            </i18n>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { getCurrentInstance, reactive } from 'vue'

import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons-vue'

import { dataURLtoFile, downscaleImage, getFileExtension, getPosterPayload } from '@/utils/common'
import { uploadFileToS3 } from '@/utils/Uploader'

import FullPreview from './FullPreview'

export default {
  name: 'PublishModal',
  components: { FullPreview, PlusCircleOutlined, RightOutlined },
  props: {
    infoForm: {
      type: Object,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    durationLimit: {
      type: Object,
      required: true,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const app = getCurrentInstance()
    const formState = reactive({
      caption: props.infoForm.caption,
      hashtags: props.infoForm.hashtags,
      action_url: props.infoForm.action_url,
      access: props.infoForm.access,
    })
    const onFinish = (values) => {
      context.emit('change', values)

      // FIXME - getCurrentInstance is not meant for this, need idiomatic way to access globals from setup() hook
      app.appContext.config.globalProperties.$_tracking_dispatch({
        event: app.appContext.config.globalProperties.$_tracking_events.FWSTUDIO_PUBLISH_VIDEO_FROM_PROJECT,
      })

      context.emit('publish-project-and-close-modal')
    }
    return {
      formState,
      onFinish,
    }
  },
  data() {
    return {
      text: {
        publish: this.$t('publish'),
        cancel: this.$t('cancel'),
      },
    }
  },
  methods: {
    beforeUploadPoster(file) {
      const URL = window.URL || window.webkitURL
      const filetype = getFileExtension(file.name)
      const newPosterImg = new Image()
      newPosterImg.src = URL.createObjectURL(file)
      this.infoForm.posterSrc = newPosterImg.src
      this.posterUploadProgress = 0
      const onProgress = (p) => (this.posterUploadProgress = p)
      if (filetype === 'jpg') {
        uploadFileToS3(file, onProgress).then(({ key }) => {
          this.$emit('change', {
            posterPayload: getPosterPayload({
              key,
              file,
              newPosterImg,
            }),
          })
        })
      } else if (filetype === 'png') {
        downscaleImage(URL.createObjectURL(file), 600).then(async (newRes) => {
          const file = dataURLtoFile(newRes, 'poster.jpg')
          newPosterImg.src = URL.createObjectURL(file)
          const { key } = await uploadFileToS3(file)
          this.$emit('change', {
            posterPayload: getPosterPayload({
              key,
              file,
              newPosterImg,
            }),
          })
        })
      }
      return false
    },
    hideModal() {
      this.$emit('close-publish-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.publish-modal-preview-wrapper {
  height: 100%;
}
.publish-modal-content-wrapper {
  display: flex;
  // min-width: 800px;
  max-height: 100%;
  height: auto;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  .ant-form {
    padding: 16px;
    width: 100%;
  }
  .poster-uploader {
    display: flex;
    max-width: 99px;
    min-height: 164px;
    max-height: 164px;
    height: 164px;
    .poster-text {
      font-size: 12px;
      padding: 12px;
    }
    i {
      font-size: 24px;
      color: rgb(15, 107, 255); // primary
    }
    .media-cover {
      max-width: 28em;
      max-height: 28em;
    }
  }
}
.publish-modal-content-form {
  .ant-form-item-label > label {
    color: rgb(64, 64, 64);
  }
  .ant-form-item-required::after {
    display: inline-block !important;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
  }
  .ant-radio-wrapper {
    display: flex;
    height: 30px;
    line-height: 30px;
    align-items: center;
    .radio-option {
      display: flex;
      box-sizing: border-box;
      margin-top: 0.5rem;

      .option-1 {
        display: block;
        box-sizing: border-box;
        min-width: 0px;
        width: 4rem;
      }
      .option-2 {
        display: block;
        box-sizing: border-box;
        min-width: 0px;
        margin-left: 12px;
        margin-right: 12px;
        color: rgb(155, 155, 155);
      }
    }
  }
  .publish-modal-collapse {
    &.ant-collapse-borderless {
      background: transparent;
    }
    .ant-collapse-header {
      padding-left: 0;
    }
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
}
.mb0 {
  margin-bottom: 0;
}
.submit-action {
  display: flex;
  flex-direction: column;
}
.fw-ant-button {
  font-weight: normal;
  margin-top: 20px;
  padding: 7px 20px;
  border-color: #0f6bff;
  background: #0f6bff;
}
.submit-action-agree {
  box-sizing: border-box;
  min-width: 0px;
  margin: 12px 0;
  color: rgb(189, 189, 189);
  a {
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #388bff;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "videoTooShort": "Your {durationOfVideo}-second video is too short. The minimum duration is {minVideoDuration} seconds.",
    "videoTooLong": "Your {durationOfVideo}-second video is too long. The maximum duration is {maxVideoDuration} seconds.",
    "publishVideo": "Publish Video",
    "caption": "caption",
    "hashtags": "Hashtags",
    "hashtagHint": "Separate hashtags with commas (i.e. Travel, Trip, Nomad)",
    "poster": "Poster",
    "posterMsg": "Add a new Poster",
    "ctaButton": "CTA Button",
    "insertLinkPlaceholder": "Insert link...",
    "private": "Only you",
    "private_description": "Only you and people on your account can see.",
    "public": "Public",
    "public_description": "Anyone on the internet can see.",
    "create": "Create",
    "cancel": "Cancel",
    "agreeFireworkText": "By posting, I agree to Firework {communityGuideline} & {terms}",
    "communityGuideline": "community guidelines",
    "terms": "terms"
  }
}
</i18n>
