import debounce from 'lodash/debounce'

import { generateUUID } from '@/utils/common'
import { DEFAULT_VIDEO_HEIGHT, DEFAULT_VIDEO_WIDTH } from '@/utils/Constants'
import { CLIP_TYPES } from '@/utils/Global'
import { CaptionClip, StickerClip, VideoClip } from '@/utils/ProjectData'

const cloneDeep = require('clone-deep')

export default {
  namespaced: true,
  state: {
    videos: [],
    audios: [],
    captions: [],
    stickers: [],
    images: [], // 模板里 放图片的轨道内容
    videoModule: null, // 使用的module
    currentModuleId: null, // 正在使用的module id
    currentVideoUuid: null,
    videoWidth: DEFAULT_VIDEO_WIDTH,
    videoHeight: DEFAULT_VIDEO_HEIGHT,
    alias: '',
    version: '1',
    projectType: null,
    useTTS: false,
    useDigitalHuman: false,
    loaded: false,
    videoProject: {},
    predefineColors: [],
    watermarks: [],
    predefineFontFamily: [],
    fonts: [],
    isSelectForReplaceUserAddedClip: false,
  },
  getters: {
    // Ensure fonts are in order: brandingPrimaryFont, brandingSecondaryFont, defaultFont, ...the rest base fonts
    // SEE https://fwn.atlassian.net/wiki/spaces/CE/pages/364937290/Caption+Packages
    fonts: (state) => {
      const prioritizedFontIds = [...state.predefineFontFamily, 'defaultFontFamily']

      const allFonts = [...state.fonts].sort((a, b) => {
        // Find all fonts that should appear first in font list
        if (prioritizedFontIds.includes(a.id)) {
          return -1
        } else {
          return a.label > b.label ? 1 : -1
        }
      })

      return allFonts
    },
    predefineColors: (state) => state.predefineColors,
    predefineFontFamily: (state) => state.predefineFontFamily,
    /**
     * Given videos (scenes), calculate and return the total duration
     * CT-1544
     * @param {VideoClip} videos - usually pass in timeline.videos
     * @returns total video duration in micro second
     */
    totalDuration: (state) => {
      if (!Array.isArray(state.videos)) {
        return 0
      }
      let time = 0
      state.videos.forEach((video) => {
        video.splitList.forEach((item) => {
          time += item.captureOut - item.captureIn
        })
      })
      return time
    },
  },
  mutations: {
    addFont: (state, font) => {
      state.fonts.push(font)
    },
    setIsSelectForReplaceUserAddedClip(state, bool) {
      state.isSelectForReplaceUserAddedClip = bool
    },
    setPredefineColorsAndFontFamily(state, payload) {
      state.predefineColors = payload.color
      state.predefineFontFamily = payload.fontFamily
    },
    setCurrentModuleId(state, id) {
      state.currentModuleId = id
    },
    changeLoaded(state, payload) {
      state.loaded = payload
    },
    init(state, data = {}) {
      state.videos = cloneDeep(data.videos || [])
      state.audios = cloneDeep(data.audios || [])
      state.captions = cloneDeep(data.captions || [])
      state.images = cloneDeep(data.images || [])
      state.watermarks = cloneDeep(data.watermarks || [])
      state.stickers = cloneDeep(data.stickers || [])
      state.videoModule = cloneDeep(data.videoModule || data.module || {})
      state.videoHeight = data.videoHeight || DEFAULT_VIDEO_HEIGHT
      state.videoWidth = data.videoWidth || DEFAULT_VIDEO_WIDTH
      state.alias = data.alias
      state.version = data.version || 1
      state.projectType = data.projectType
      state.useTTS = data.useTTS
      state.useDigitalHuman = data.useDigitalHuman
      state.currentModuleId = data.currentModuleId
      state.loaded = true
    },
    addUserAddedClipsToVuex(state, { videoIndex, videoUuid, userAddedClip, willBeReplacedUserAddedClip }) {
      let video = null
      if (videoUuid != null) {
        video = state.videos.find((video) => video.uuid === videoUuid)
      } else {
        video = state.videos[videoIndex]
      }
      if (willBeReplacedUserAddedClip) {
        const idx = video.userAddedClips.findIndex((item) => item.uuid === willBeReplacedUserAddedClip.uuid)
        return video.userAddedClips.splice(idx, 1, userAddedClip)
      }
      video.userAddedClips.push(userAddedClip)
    },
    resetLoaded(state) {
      state.loaded = false
    },
    setWatermarks(state, payload) {
      state.watermarks = payload
    },
    resetClips(state, { type, clips, audioType }) {
      switch (type) {
        case CLIP_TYPES.VIDEO:
          state.videos = clips
          state.currentVideoUuid = state.videos[0] && state.videos[0].uuid + `_0`
          break
        case CLIP_TYPES.AUDIO:
          if (audioType) {
            // with the presence of audioType, only replace the clips of the same audioType
            const clipsWithoutAudioType = state.audios.filter((clip) => clip.audioType !== audioType)
            state.audios = [...clipsWithoutAudioType, ...clips]
          } else {
            state.audios = clips
          }
          break
        case CLIP_TYPES.CAPTION:
          state.captions = clips
          break
        case CLIP_TYPES.STICKER:
          state.stickers = clips
          break
        default:
          break
      }
    },
    addClipToVuex(state, clips) {
      if (!clips) {
        return
      }
      if (!Array.isArray(clips)) {
        clips = [clips]
      }
      const type = clips[0].type
      switch (type) {
        case CLIP_TYPES.VIDEO:
          state.videos.push(...clips)
          state.currentVideoUuid = state.videos[0].uuid + '_0'
          break
        case CLIP_TYPES.IMAGE:
          state.videos.push(...clips)
          break
        case CLIP_TYPES.AUDIO:
          state.audios.push(...clips)
          break
        case CLIP_TYPES.CAPTION:
          state.captions.push(...clips)
          break
        case CLIP_TYPES.STICKER:
          state.stickers.push(...clips)
          break
        default:
          break
      }
    },
    addMultipleClipToVuex(state, object) {
      if (object.needClear) {
        state.videoModule = null
        state.captions = state.captions.filter((c) => !c.isModule)
        state.stickers = state.stickers.filter((s) => !s.isModule)
        state.images = []
        state.videos.map((video) => {
          for (let i = 0; i < video.splitList.length; i++) {
            const element = video.splitList[i]
            element.videoFxs = element.videoFxs.filter((fx) => fx.from === 'user-added')
          }
        })
      }
      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          const value = cloneDeep(object[key])
          if (key === 'needClear') {
            continue
          }
          if (Array.isArray(value)) {
            state[key].push(...value)
          } else {
            state[key] = value
          }
        }
      }
    },
    // CT-1909 - Clear all userAddedClips of all videos (scenes)
    // This state-mutating logic was moved here from in StyleList.vue
    clearUserAddedVideoClips(state) {
      for (const video of state.videos) {
        // remove previous module items
        const { userAddedClips } = video
        let i = userAddedClips.length
        while (i--) {
          if (userAddedClips[i]?.isFromModule || userAddedClips[i]?.isDefaultAsset) {
            userAddedClips.splice(i, 1)
          }
        }
      }
    },
    setVideoModule(state, module) {
      if (module) {
        state.videoModule = cloneDeep(module)
      }
    },
    updateClipToVuex(state, value) {
      let index = -1
      const type = value.type
      switch (type) {
        case CLIP_TYPES.VIDEO:
          index = state.videos.findIndex((item) => item.uuid === value.uuid)
          state.videos[index] = value
          computedInPoint(state, value)

          if (value.isUserAddedClip) {
            // 此状态只在crop dialog里有
            state.videos.forEach((video, idx) => {
              index = video.userAddedClips.findIndex((clip) => clip.uuid === value.uuid)
              if (index != -1) {
                delete state.videos[idx].userAddedClips[index].isUserAddedClip
                state.videos[idx].userAddedClips.splice(index, 1, value)
              }
            })
          }
          break
        case CLIP_TYPES.AUDIO:
          index = state.audios.findIndex((item) => item?.uuid === value?.uuid)
          state.audios[index] = value
          break
        case CLIP_TYPES.CAPTION:
          index = state.captions.findIndex((item) => item?.uuid === value?.uuid)
          state.captions[index] = value
          break
        case CLIP_TYPES.STICKER:
          index = state.stickers.findIndex((item) => item?.uuid === value?.uuid)
          state.stickers[index] = value
          break
        default:
          break
      }
    },
    deleteUserAddedClipsToVuex(state, userAddedClipUuid) {
      for (const video of state.videos) {
        let deleteIdx = -1
        for (const [idx, userAddedClip] of Object.entries(video.userAddedClips)) {
          if (userAddedClip.uuid === userAddedClipUuid) {
            deleteIdx = idx
          }
        }
        if (deleteIdx !== -1) {
          delete video.userAddedClips[deleteIdx]
        }
      }
    },
    deleteClipToVuex(state, { type, index }) {
      const { VIDEO, AUDIO, CAPTION, STICKER } = CLIP_TYPES
      if (type === VIDEO) {
        beforeDel(state, index)
      } else if (type === AUDIO) {
        state.audios.splice(index,1)
      } else if (type === CAPTION) {
        const target = state.captions[index]
        if (target.isModule) {
          target.deleted = true
        } else {
          state.captions.splice(index, 1)
        }
      } else if (type === STICKER) {
        const target = state.stickers[index]
        if (target.isModule) {
          target.deleted = true
        } else {
          state.stickers.splice(index,1)
        }
      }
    },
    setCurrentVideoUuid(state, uuid) {
      state.currentVideoUuid = uuid
    },
    clearModuleData(state) {
      state.videoModule = null
      state.captions = state.captions.filter((c) => !c.isModule)
      state.stickers = state.stickers.filter((s) => !s.isModule)
      state.images = []
      state.currentModuleId = null
      state.videos.map((video) => {
        for (let i = 0; i < video.splitList.length; i++) {
          const element = video.splitList[i]
          element.videoFxs = element.videoFxs.filter((fx) => fx.from === 'user-added')
        }
      })
      console.log('卸载模板')
    },
    clearIsModuleData(state) {
      state.captions = state.captions.filter((c) => !c.isModule)
      state.stickers = state.stickers.filter((s) => !s.isModule)
    },
    setVideoProject(state, project) {
      state.videoProject = project
    },

    setCaptions(state, captions) {
      state.captions = captions
    },
    setStickers(state, stickers) {
      state.stickers = stickers
    },
    setVideos(state, videos) {
      state.videos = videos
    },
  },
  actions: {
    addFont({ commit }, font) {
      commit('addFont', font)
    },
    setVideoModule({ commit }, module) {
      commit('clearModuleData')
      if (module) {
        commit('setVideoModule', module)
        console.warn('应用模板')
      }
    },
    setVideoProject({ commit }, project) {
      if (project) {
        commit('setVideoProject', project)
      }
    },
  },
}
// Recalculate after video split and trim modification
function computedInPoint(state, v) {
  const captions = state.captions.filter((caption) => caption.inPoint === v.inPoint)
  let captionIndex = -1,
    captionLength = 0

  if (captions.length) {
    captions.map((caption) => {
      const c = []
      v.splitList.reduce((point, split) => {
        const { captureIn: tin, captureOut: tout } = split
        const ca = new CaptionClip({
          ...caption,
          inPoint: point,
          duration: tout - tin,
        })
        c.push(ca)
        return point + tout - tin
      }, caption.inPoint)
      const index = state.captions.indexOf(caption)
      if (index > -1) {
        state.captions.splice(index, 1, ...c)

        captionIndex = index
        captionLength = captions.length
      }
    })
  }

  const stickers = state.stickers.filter((sticker) => sticker.inPoint === v.inPoint)
  let stickerIndex = -1,
    stickerLength = 0
  if (stickers.length) {
    stickers.map((sticker) => {
      const s = []
      v.splitList.reduce((point, split) => {
        const { captureIn: tin, captureOut: tout } = split
        const sti = new StickerClip({
          ...sticker,
          inPoint: point,
          duration: tout - tin,
        })
        s.push(sti)
        return point + tout - tin
      }, sticker.inPoint)
      const index = state.stickers.indexOf(sticker)
      if (index > -1) {
        state.stickers.splice(index, 1, ...s)

        stickerIndex = index
        stickerLength = s.length
      }
    })
  }

  let point = 0
  for (let vi = 0; vi < state.videos.length; vi++) {
    const video = state.videos[vi]
    for (let j = captionIndex + captionLength; j < state.captions.length; j++) {
      const caption = state.captions[j]
      if (caption && caption.inPoint === video.inPoint) {
        caption.inPoint = point
        console.warn('Modify the point of the subtitle', point, caption)
      }
    }
    for (let j = stickerIndex + stickerLength; j < state.stickers.length; j++) {
      const sticker = state.stickers[j]
      if (sticker && sticker.inPoint === video.inPoint) {
        sticker.inPoint = point
      }
    }
    video.inPoint = point
    const videos = []

    if (video.uuid === v.uuid) {
      for (const [idx, split] of Object.entries(v.splitList)) {
        const prev = videos[idx - 1]
        let inPoint = 0
        if (prev) {
          inPoint = prev.inPoint + (prev.splitList[0].captureOut - prev.splitList[0].captureIn)
        } else {
          inPoint = video.inPoint
        }
        const userAddedClips = video.userAddedClips.map((clip) => {
          return new VideoClip({
            ...clip,
            ...JSON.parse(JSON.stringify(clip.splitList[0])),
          })
        })
        const videoClip = new VideoClip({
          ...video,
          ...split,
          userAddedClips,
          uuid: null,
          inPoint,
          duration: split.captureOut - split.captureIn,
        })
        const curDuration = split.captureOut - split.captureIn // current clip length

        for (let i = 0; i < videoClip.userAddedClips.length; i++) {
          if (!userAddedClips[i]) {
            continue
          }
          userAddedClips[i].uuid = generateUUID()
          userAddedClips[i].inPoint = inPoint

          if (userAddedClips[i].videoType === CLIP_TYPES.VIDEO) {
            // The first part of the current split, and trimIn is 0
            if (!video.splitList[0].captureOut && !prev) {
              userAddedClips[i].duration = Math.min(videoClip.duration, userAddedClips[i].orgDuration)
              changeUserAddedClipSplitData(
                userAddedClips[i],
                0,
                Math.min(videoClip.duration, userAddedClips[i].orgDuration),
              )
            } else if (prev) {
              const prevUserAddClip = prev.userAddedClips[i]
              // If the previous one is to be deleted, the current one should also be deleted
              if (!prevUserAddClip || prevUserAddClip.shouldRemove) {
                userAddedClips[i].shouldRemove = true
              } else if (
                // If the last useraddedclip is already orgDuration, it means that the useraddedclip of the current video has ended and can be deleted
                prevUserAddClip.splitList[0].captureOut == prevUserAddClip.orgDuration
              ) {
                userAddedClips[i].shouldRemove = true
              } else {
                // The length of the last useraddedclip is less than orgDuration, indicating that the current useraddedclip will continue
                const restDuration = prevUserAddClip.orgDuration - prevUserAddClip.splitList[0].captureOut

                userAddedClips[i].duration = restDuration > curDuration ? curDuration : restDuration

                changeUserAddedClipSplitData(
                  userAddedClips[i],
                  prevUserAddClip.splitList[0].captureOut,
                  prevUserAddClip.splitList[0].captureOut + userAddedClips[i].duration,
                )
              }
            } else {
              // splitList[0] after each split, the beginning position, and trimIn is not 0
              const captureIn = userAddedClips[i].splitList[0].captureIn // The old captureIn is valid

              const restDuration = userAddedClips[i].orgDuration - captureIn

              userAddedClips[i].duration = restDuration > curDuration ? curDuration : restDuration

              changeUserAddedClipSplitData(
                userAddedClips[i],
                userAddedClips[i].splitList[0].captureIn,
                userAddedClips[i].splitList[0].captureIn + userAddedClips[i].duration,
              )
            }
          }
        }
        videoClip.userAddedClips = videoClip.userAddedClips.filter((c) => !c.shouldRemove)
        videos.push(videoClip)
      }
      state.videos.splice(vi, 1, ...videos)
    }
    const { captureIn, captureOut } = state.videos[vi].splitList[0]
    const duration = captureOut - captureIn
    point += duration
  }
}
// After the video is deleted, recalculate
function beforeDel(state, index) {
  const { inPoint, splitList } = state.videos[index]
  const { videos, captions, stickers } = state
  const vDuration = splitList[0].captureOut - splitList[0].captureIn
  // Delete the corresponding subtitle, and the subsequent subtitles will move forward
  for (let i = 0; i < captions.length; i++) {
    const c = captions[i]
    if (c?.inPoint === undefined) {
      continue
    }
    if (c.inPoint === inPoint) {
      state.captions.splice(i, 1)
      i--
    } else if (c.inPoint > inPoint) {
      state.captions[i].inPoint -= vDuration
    }
  }
  // Delete the corresponding sticker, and the subsequent stickers will move forward
  for (let i = 0; i < stickers.length; i++) {
    const s = stickers[i]
    if (s?.inPoint === undefined) {
      continue
    }
    if (s.inPoint === inPoint) {
      state.stickers.splice(i, 1)
      i--
    } else if (s.inPoint > inPoint) {
      state.stickers[i].inPoint -= vDuration
    }
  }
  // Delete the video, and put the following video forward
  state.videos.splice(index, 1)
  for (let i = index; i < videos.length; i++) {
    let inPoint = 0
    if (videos[i - 1]) {
      const { inPoint: preIn, splitList: preSplit } = videos[i - 1]
      inPoint = preIn + preSplit[0].captureOut - preSplit[0].captureIn
    }
    state.videos[i].inPoint = inPoint
  }
}

function changeUserAddedClipSplitData(clip, inPoint, outPoint) {
  if (inPoint != null) {
    clip.splitList[0].captureIn = inPoint
    // clip.splitList[0].trimIn = inPoint;
  }
  if (outPoint != null) {
    clip.splitList[0].captureOut = outPoint
    // clip.splitList[0].trimOut = outPoint;
  }
}
