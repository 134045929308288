export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "zh-HK",
    "resource": {
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替換"])},
      "trim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["裁剪"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
      "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貼上"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
      "sendBackward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往下一層"])},
      "sendToBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最下層"])},
      "bringForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往上一層"])},
      "bringToFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移到最上層"])},
      "addOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加在上面"])}
    }
  })
}
