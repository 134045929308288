// Resource categories in IndexDB, Also the directories inside FS
export const objectStores = [
  'm3u8',
  'font',
  'audio',
  'captionstyle',
  'captioninanimation',
  'captionoutanimation',
  'captionanimation',
  'captioncontext',
  'animatedsticker',
  'compoundcaption',
  'resource',
  'lic',
  'videofx',
]
export const RESOURCE = 'resource'
// Resourse package type
export const assetTypes = {
  videofx: 0,
  captionstyle: 2,
  animatedsticker: 3,
  compoundcaption: 7,
  captioncontext: 8,
  captionrenderer: 9,
  captionanimation: 10,
  captioninanimation: 11,
  captionoutanimation: 12,
}
// Assets that needs to be installed
export const needInstall = Object.keys(assetTypes)
// clip type
export const CLIP_TYPES = {
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE: 'image',
  CAPTION: 'caption',
  STICKER: 'sticker',
}
export const NEW_MEDIA = 'new media' // Drag and drop purpose
// fx desc
export const FX_DESC = {
  TRANSFORM2D: 'Transform 2D',
  MOSAIC: 'Mosaic',
  REMOVEBACK: 'Master Keyer',
}
// background removal FX parameter key
export const BACKGROUND_REMOVAL_KEYS = {
  SPILL_REMOVAL: 'Spill Removal',
  SOFTENESS_AMENDMENT: 'Softeness Amendment',
  SPILL_REMOVAL_INTENSITY: 'Spill Removal Intensity',
  SHRINK_INTENSITY: 'Shrink Intensity',
  KEY_COLOR: 'Key Color',
}
// transform 2D FX parameter's key
export const TRANSFORM2D_KEYS = {
  TRANS_X: 'Trans X', // Transform
  TRANS_Y: 'Trans Y',
  SCALE_X: 'Scale X', // Zoom / Scale
  SCALE_Y: 'Scale Y',
  ROTATION: 'Rotation',
  ANCHOR_X: 'Anchor X',
  ANCHOR_Y: 'Anchor Y',
}
// key for setting up in/out animation and its in/out point
export const ANIMATE_PROPERTY_KEYS = {
  IN_ANIMATE_PACKAGE_ID: 'Package Id',
  IN_ANIMATE_IN_POINT: 'Package Effect In',
  IN_ANIMATE_OUT_POINT: 'Package Effect Out',
  OUT_ANIMATE_PACKAGE_ID: 'Package2 Id',
  OUT_ANIMATE_IN_POINT: 'Package2 Effect In',
  OUT_ANIMATE_OUT_POINT: 'Package2 Effect Out',
}
export const CROP_KEYS = {
  REGION: 'Region',
  UNIT_SIZE: 'Unit Size',
}
// CT-1796 Caption fx types
// Key names match Meishe Creator Community categories while values match the
// the actual fx file extensions used by Meishe binary
export const CAPTION_FX_TYPES = {
  IN_ANIMATION: 'captionInAnimation',
  OUT_ANIMATION: 'captionOutAnimation',
  LOOP_ANIMATION: 'captionAnimation',
  BUBBLE_ANIMATION: 'captionContext',
}
export const CAPTION_FX_TIMING_TYPES = {
  IN_ANIMATION: 'captionInAnimationDuration',
  OUT_ANIMATION: 'captionOutAnimationDuration',
  LOOP_ANIMATION: 'captionAnimationPeriod',
}

// video fx types
export const VIDEO_FX_TYPES = {
  IN_ANIMATION: 'mediaInAnimation',
  OUT_ANIMATION: 'mediaOutAnimation',
  IN_OUT_ANIMATION: 'mediaInOutAnimation',
  FILTER: 'media-filter',
  CONTEXT: 'mediaContext',
}
// video fx types
export const FX_TYPES = {
  BUILTIN: 'builtin',
  PACKAGE: 'package',
  PROPERTY: 'property',
}
// fx parameter types
export const PARAMS_TYPES = {
  STRING: 'string',
  FLOAT: 'float',
  BOOL: 'bool',
  INT: 'int',
  COLOR: 'color',
}
// track type
export const TRACK_TYPES = {
  VIDEO: 'videoTrack',
  AUDIO: 'audioTrack',
  CAPTION: 'captionTrack',
  STICKER: 'stickerTrack',
}
export const DEFAULT_CAPTION = '4447104F-7377-478C-92A3-89FC98FDABF6' // Default caption pacakage
export const DEFAULT_FONT = 'Noto Sans'
export const USER_ADDED = 'user-added'
// text alignment
export const TEXT_ALIGN = {
  left: 0,
  center: 1,
  right: 2,
}

// media type
export const MEDIA_TYPES = {
  1: 'video',
  2: 'audio',
  3: 'image',
}
export const RATIO = 0.5625
export const DEFAULT_ANIMATION_DURATION = 1000000

// the two constants below need to be identical
export const MIN_DURATION_SEC = 0.1 // minimal time unit = 0.1 second
export const MIN_DURATION_US = 100 * 1000 // 0.1 second = 100,000 micro-second

export const DEFAULT_IN_ANIMATION_DURATION = 1000000
export const DEFAULT_OUT_ANIMATION_DURATION = 1000000

export const ENGINE_STATE = { STREAMING_ENGINE_STATE_STOPPED: 0 }

export const MENU_OPTIONS = {
  REPLACE: 'replace',
  TRIM: 'trim',
  COPY: 'copy',
  PASTE: 'paste',
  DELETE: 'delete',
  SEND_BACKWARD: 'sendBackward',
  SEND_TO_BACK: 'sendToBack',
  BRING_FORWARD: 'bringForward',
  BRING_TO_FRONT: 'bringToFront',
  ADD_ON_TOP: 'addOnTop',
}

export const APPLY_CAPTION_STYLE_MODE = {
  // when applying a .captionstyle, inherit its properties onto the caption
  USE_ASSET_DEFAULT_PARAM: 0,
  // do *NOT* inherit its properties onto the caption
  NOT_USE_ASSET_DEFAULT_PARAM: 1,
}

export const MAX_VIDEO_DURATION_REGULAR = 60
export const MAX_VIDEO_DURATION_LONG = 180

export const W2V = {
  TALKING_HEAD_MODE: {
    NONE: 'none',
    TALKINGHEAD: 'talkinghead',
  },
  VOICEOVER_MODE: {
    NONE: 'none',
    VOICEOVER: 'voiceover',
  },
}

export const AUDIO_TYPE = {
  SOUNDTRACK: 'soundtrack',
  MEDIA: 'media',
  TTS: 'tts',
}
