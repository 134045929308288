import { createApp } from 'vue'
import VueTimeago from 'vue-timeago3'

import { createGtm } from '@gtm-support/vue-gtm'
import * as Sentry from '@sentry/vue'

import api from '@/api/apiPath'
import App from '@/App.vue'
import utils from '@/components/utils'
import Focus from '@/directives/focus.js'
import LongClick from '@/directives/longClick.js'
import i18n from '@/i18n'
import icons from '@/icons'
import clipMixin from '@/mixins/clipMixin'
import ant from '@/plugins/ant.js'
import axios from '@/plugins/axios'
import element from '@/plugins/element.js'
import eventBus from '@/plugins/eventBus'
import nff from '@/plugins/NabooFeatureFlag'
import prompt from '@/plugins/prompt'
import tracking from '@/plugins/tracking'
import router from '@/router'
import store from '@/store'
import EventBusKeys from '@/utils/EventBusKeys'

import '@/assets/style/index.scss'

const isNodeProd = process.env.NODE_ENV === 'production'
const isVercelProd = process.env.VUE_APP_VERCEL_ENV === 'production'
const isProd = isNodeProd || isVercelProd

const app = createApp(App)

app.config.globalProperties.$api = api
app.config.globalProperties.$keys = EventBusKeys
app.config.globalProperties.$isProd = isProd

app.use(ant)
app.use(axios)
app.use(nff)
app.use(element)
app.use(eventBus) // CT-1516 - new Event Bus, future proofing to Vue3
app.use(i18n)
app.use(icons)
app.use(router)
app.use(prompt) // CT-1594 - Replicate Element $prompt with Ant
app.use(store)
app.use(VueTimeago)
app.use(tracking) // CT-1350 - init global tracking
app.use(utils)

// See https://www.npmjs.com/package/vue-gtm
app.use(
  createGtm({
    id: 'GTM-TS5N5HZ',
    queryParams: isProd
      ? {
          gtm_auth: 'wY-apal0sBdbrMtI5ANTtg',
          gtm_preview: 'env-7',
        }
      : null,
    defer: false,
    debug: !isProd,
    vueRouter: router,
    trackOnNextTick: false,
  }),
)

app.mixin(clipMixin)

// Directives
app.directive('focus', Focus)
app.directive('long-click', LongClick) // CT-1531 - add long-click behavior

app.mount('#app')

// See https://docs.sentry.io/platforms/javascript/guides/vue/
Sentry.init({
  app,
  dsn: 'https://586b3783c5f74322b2493a6a77aae61d@o141156.ingest.sentry.io/4504209820418048',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: isProd ? 0.5 : 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'api.firework.tv', /^\//],
})

// Init Firework Prod StatusPage Status Embed
const statusPageScript = document.createElement('script')
statusPageScript.src = 'http://fireworkstatus.com/embed/script.js'
document.body.appendChild(statusPageScript)
