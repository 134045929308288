<template>
  <canvas ref="fireworksCanvas" class="fireworks-canvas" @click="handleCancelFireworks"></canvas>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Fireworks',
  data() {
    return {}
  },
  mounted() {
    this.initializeCanvas()

    // adding a delay here before creating the event listener because
    // the previous form allows submit by pressing enter key
    // and it seems to trigger this event listener if there is no delay
    setTimeout(() => {
      window.addEventListener('keydown', this.handleCancelFireworks, { once: true })
    }, 1000)
  },
  methods: {
    ...mapActions({
      setEasterEggFireworks: 'setEasterEggFireworks',
    }),
    handleCancelFireworks() {
      window.removeEventListener('keydown', this.handleCancelFireworks)
      this.setEasterEggFireworks(false)
    },
    initializeCanvas() {
      class Layer {
        constructor(ctx) {
          this.ctx = ctx
          this.commands = []
          this.alpha = 1
        }
        update(delta) {
          this.alpha *= 1 - 0.1 * delta
          return this.alpha < 0.1
        }
        draw() {
          this.commands.forEach(([color, alpha, x, y, width, height]) => {
            this.ctx.fillStyle = color
            this.ctx.globalAlpha = this.alpha * alpha
            this.ctx.fillRect(x, y, width, height)
          })
        }
      }

      class Particle {
        constructor(x, y, col) {
          this.x = x
          this.y = y
          this.col = col
          this.vel = randomVec(2)
          this.lifetime = 0
        }

        update(delta) {
          delta = 1
          this.x += this.vel.x
          this.y += this.vel.y
          this.vel.y += 0.02 * delta
          this.vel.x *= 1 - delta * 0.01
          this.vel.y *= 1 - delta * 0.01
          this.lifetime += delta
          return this.lifetime > 80
        }

        draw() {
          const color = this.col
          const alpha = Math.max(1 - this.lifetime / 80, 0)
          const x = this.x
          const y = this.y
          const rad = 4
          currentLayer.commands.push([color, alpha, x, y, rad, rad])
        }
      }

      class Firework {
        constructor(x) {
          this.x = x
          this.y = height
          this.isBlown = false
          this.col = randomCol()
        }

        update(delta) {
          this.y -= 3 * delta
          if (this.y < 350 - Math.sqrt(Math.random() * 500) * 40) {
            this.isBlown = true
            for (let i = 0; i < 60; i++) {
              particles.push(new Particle(this.x, this.y, this.col))
            }
          }
          return this.isBlown
        }

        draw() {
          const color = this.col
          const alpha = 1
          const x = this.x
          const y = this.y
          const rad = 4
          currentLayer.commands.push([color, alpha, x, y, rad, rad])
        }
      }

      const canvas = this.$refs.fireworksCanvas
      var currentLayer
      var width = innerWidth
      var height = innerHeight

      const layers = []
      const fireworks = []
      const particles = []
      setSize(canvas)
      const ctx = canvas.getContext('2d')
      fireworks.push(new Firework(Math.random() * (width - 200) + 100))

      let lastTime = document.timeline?.currentTime || performance.now()
      requestAnimationFrame(loop)

      function loop(time) {
        // values were defined with a 60FPS base
        // we now use rAF and thus need a delta time
        // to honor the same expected speed on all devices
        const delta = (time - lastTime) / (1000 / 60)
        lastTime = time
        ctx.globalAlpha = 1
        ctx.clearRect(0, 0, width, height)
        currentLayer = new Layer(ctx)
        layers.push(currentLayer)

        const ended = []

        fireworks.forEach((firework, index) => {
          const done = firework.update(delta)
          if (done) {
            ended.push(index)
          }
          firework.draw()
        })
        // remove all ended, for last to first
        ended.reverse().forEach((index) => {
          fireworks.splice(index, 1)
        })
        ended.length = 0

        particles.forEach((particle, index) => {
          const done = particle.update(delta)
          particle.draw()
          if (done) {
            ended.push(index)
          }
        })

        ended.reverse().forEach((index) => {
          particles.splice(index, 1)
        })
        ended.length = 0

        layers.forEach((layer, index) => {
          const done = layer.update(delta)
          if (done) {
            ended.push(index)
          }
          layer.draw()
        })

        ended.reverse().forEach((index) => {
          layers.splice(index, 1)
        })

        if (Math.random() < 1 / 25) {
          fireworks.push(new Firework(Math.random() * (width - 200) + 100))
        }
        requestAnimationFrame(loop)
      }

      function randomCol() {
        const letter = '0123456789ABCDEF'
        const nums = []

        for (let i = 0; i < 3; i++) {
          nums[i] = Math.floor(Math.random() * 256)
        }

        let brightest = 0
        for (let i = 0; i < 3; i++) {
          if (brightest < nums[i]) {
            brightest = nums[i]
          }
        }

        brightest /= 255
        for (let i = 0; i < 3; i++) {
          nums[i] /= brightest
        }

        let color = '#'
        for (let i = 0; i < 3; i++) {
          color += letter[Math.floor(nums[i] / 16)]
          color += letter[Math.floor(nums[i] % 16)]
        }
        return color
      }

      function randomVec(max) {
        const dir = Math.random() * Math.PI * 2
        const spd = Math.random() * max
        return {
          x: Math.cos(dir) * spd,
          y: Math.sin(dir) * spd,
        }
      }

      function setSize(canv) {
        canv.style.width = window.innerWidth + 'px'
        canv.style.height = window.innerHeight + 'px'
        width = window.innerWidth
        height = window.innerHeight

        canv.width = innerWidth * window.devicePixelRatio
        canv.height = innerHeight * window.devicePixelRatio
        canvas.getContext('2d').scale(window.devicePixelRatio, window.devicePixelRatio)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fireworks-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>
