<template>
  <div class="preview-box">
    <div v-loading="waiting" class="preview-window" element-loading-background="rgba(0, 0, 0, 0.5)">
      <canvas
        id="preview-window"
        class="preview-window"
        :width="PREVIEW_VIDEO_WIDTH"
        :height="PREVIEW_VIDEO_HEIGHT"
      ></canvas>
    </div>
    <div class="footer flex">
      <svg-icon
        :class="{ 'replay-button': isVideoStopAndEnd, lightmode: lightMode }"
        :icon-class="previewButtonIconClass"
        @click="play"
      ></svg-icon>
      <div ref="track" class="play-track" @click="mousemove">
        <span
          class="play-dot"
          :class="[lightMode ? 'lightmode' : '']"
          :style="seekBarStyle"
          @mousedown.stop="mousedown"
        ></span>
        <div
          v-for="(item, index) in clipList"
          :key="item.uuid"
          :class="['play-item', index === activeIndex ? 'active' : '', lightMode ? 'lightmode' : '']"
          :style="getStyle(item)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { PREVIEW_VIDEO_HEIGHT, PREVIEW_VIDEO_WIDTH } from '@/utils/Constants'

export default {
  props: {
    autoPlay: Boolean,
    lightMode: Boolean,
  },
  data() {
    return {
      PREVIEW_VIDEO_HEIGHT,
      PREVIEW_VIDEO_WIDTH,

      seekVal: 0,
      waiting: false,
      isPlaying: false,
    }
  },
  computed: {
    ...mapState({
      timelineClass: (state) => state.edit.timeline,
    }),

    isVideoStopAndEnd() {
      // the playback stops before the very end, hence using 98% as the
      return !this.isPlaying && this.seekVal / this.duration >= 0.98
    },
    previewButtonIconClass() {
      if (this.isVideoStopAndEnd) {
        return 'undo'
      } // undo is the replay-icon
      return this.isPlaying ? 'pause' : 'play'
    },
    duration() {
      return this.videos.reduce((res, item) => {
        res += item.splitList.reduce((sum, clip) => {
          sum += clip.captureOut - clip.captureIn
          return sum
        }, 0)
        return res
      }, 0)
    },
    seekBarStyle() {
      return {
        left: (this.seekVal / this.duration) * 100 + '%',
      }
    },
    activeIndex() {
      return this.clipList.findIndex(
        ({ inPoint, duration }) => inPoint <= this.seekVal && this.seekVal < inPoint + duration,
      )
    },
    clipList() {
      return this.videos.reduce((res, item) => {
        let inPoint = item.inPoint
        const clips = item.splitList.map((clip, index) => {
          const c = {
            duration: clip.captureOut - clip.captureIn,
            inPoint: inPoint,
            uuid: item.uuid + `_${index}`,
          }
          inPoint += clip.captureOut - clip.captureIn
          return c
        })
        res.push(...clips)
        return res
      }, [])
    },
  },
  mounted() {
    this.setIsFullPreviewVisible(true)

    // Set the existing Timeline class object to use the preview window to reflect timeline
    this.$eventBusEmit(this.$keys.changeMonitor, 'preview-window')

    this.bindContextEvent()
    if (this.autoPlay) {
      this.$nextTick(() => {
        this.play()
      })
    }
  },
  beforeUnmount() {
    this.setIsFullPreviewVisible(false)

    // Set the existing Timeline class object to revert back to default to reflect timeline
    this.$eventBusEmit(this.$keys.changeMonitor)
  },
  methods: {
    ...mapActions(['setIsFullPreviewVisible']),
    getStyle(item) {
      const width = (item.duration / this.duration) * 100
      return {
        width: width + '%',
      }
    },
    mousedown() {
      document.body.addEventListener('mousemove', this.mousemove)
      document.body.addEventListener('mouseup', this.mouseup)
    },
    mousemove(e) {
      const trackDom = this.$refs.track
      const { left, width } = trackDom.getBoundingClientRect()
      const val = Math.round(((e.clientX - left) / width) * this.duration)
      this.seekVal = Math.min(Math.max(0, val), this.duration - 1)
      this.timelineClass.seekTimeline(this.seekVal)
    },
    mouseup() {
      document.body.removeEventListener('mousemove', this.mousemove)
      document.body.removeEventListener('mouseup', this.mouseup)
    },
    play() {
      // CT-1730 : When play, deselect all clip
      // Need similar behavior as in the timeline preview
      this.$eventBusEmit(this.$keys.clearWorkflowSelections)
      if (this.isPlaying) {
        this.timelineClass.stop()
      } else {
        if (this.isVideoStopAndEnd) {
          this.seekVal = 0 // threshold for determining whether the playback is "ended"
          this.timelineClass.seekTimeline(this.seekVal)
        }
        this.timelineClass.play()
      }
      this.isPlaying = !this.isPlaying
    },
    stopEvent(timeline) {
      if (timeline === this.timelineClass.timeline) {
        this.isPlaying = false
      }
    },
    playingEvent(timeline, pos) {
      if (timeline && pos) {
        this.isPlaying = true
        this.seekVal = pos
      }
    },
    bindContextEvent() {
      this.$eventBusOn(this.$keys.onWebRequestWaitStatusChange, this.statusChangeEvent)
      this.$eventBusOn(this.$keys.onPlaybackTimelinePosition, this.playingEvent)
      this.$eventBusOn(this.$keys.onPlaybackStopped, this.stopEvent)
    },
    statusChangeEvent(isVideo, waiting) {
      this.waiting = waiting
    },
  },
}
</script>

<style lang="scss">
.preview-box {
  height: 100%;
  svg.lightmode {
    filter: brightness(0);
  }
  .replay-button {
    transform: scale(2);
  }
  .preview-window {
    height: calc(100% - 40px);
    canvas {
      height: 100%;
      width: 100%;
      aspect-ratio: 9/16;
      background-color: rosybrown;
      border-radius: 6px;
    }
  }
  .footer {
    margin-top: 16px;
    padding-right: 8px;
    .play-track {
      flex: 1;
      position: relative;
      margin-left: 12px;
      display: flex;
      .play-item {
        border-radius: 2px;
        background-color: #fff;
        opacity: 0.4;
        height: 4px;
        transition: opacity 0.3s;
        cursor: pointer;
        &.active {
          opacity: 1;
        }
        &.lightmode {
          background: #333;
        }
      }
      .play-item + .play-item {
        margin-left: 2px;
      }
    }
    .play-dot {
      border-radius: 50%;
      background-color: #fff;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: translate(-50%, -50%);
      &.lightmode {
        background: #333;
      }
    }
  }
}
</style>
