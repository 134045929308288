/*
  Isolate all Element imports to this file to keep main.js clean - CT-1567

  We only load what we need (what we haven't migrated to Ant yet)
*/

import { ElColorPicker, ElInfiniteScroll, ElLoading } from 'element-plus'

import 'element-plus/dist/index.css'

export default {
  install: (app) => {
    // Components
    app.use(ElColorPicker)

    // Directives
    app.use(ElInfiniteScroll)
    app.use(ElLoading)

    // Globals
    app.config.globalProperties.$loading = ElLoading.service
  },
}
