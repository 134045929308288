<!-- FIXME - DEPRECATE THIS COMPONENT (AND ELEMENT COLOR PICKER) AND REPLACE WITH ANT COLOR PICKER -->
<template>
  <el-color-picker
    v-bind="$attrs"
    v-model="color"
    class="fw-color-picker"
    :predefine="predefineColors"
    show-alpha
    @input="handleInput"
  />
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FwColorPicker',
  props: ['modelValue', 'value'],
  data() {
    return {
      color: this.modelValue || this.value,
    }
  },
  computed: {
    ...mapGetters({ predefineColors: 'clip/predefineColors' }),
  },
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e)
      this.$emit('active-change', e)
    },
  },
}
</script>
