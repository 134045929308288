export default {
  namespaced: true,
  state: () => ({
    currentlyPlayingAssetId: '',
    currentlyPlayingAssetType: '',
  }),
  mutations: {
    setCurrentlyPlayingAssetId(state, payload) {
      state.currentlyPlayingAssetId = payload
    },
    setCurrentlyPlayingAssetType(state, payload) {
      state.currentlyPlayingAssetType = payload
    },
  },
  actions: {
    setCurrentlyPlayingAssetId({ commit }, payload) {
      commit('setCurrentlyPlayingAssetId', payload)
    },
    setCurrentlyPlayingAssetType({ commit }, payload) {
      commit('setCurrentlyPlayingAssetType', payload)
    },
  },
}
