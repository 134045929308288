export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ja",
    "resource": {
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])},
      "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタイル"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
      "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser not supported"])},
      "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use Google Chrome to access the Firework Studio."])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスに対するフィードバックをお送りください。"])}
    }
  })
}
