export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
        "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles"])},
        "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing ..."])},
        "browserNotSupportedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser not supported"])},
        "browserNotSupportedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use Google Chrome or Firefox on desktop to access the Firework Studio."])},
        "projectChannelUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video project channel has been updated"])}
      }
    }
  })
}
